import {
    DateSelectArg,
    DatesSetArg,
    EventClickArg,
    EventContentArg,
    EventInput,
    PluginDef,
    ToolbarInput,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import "bootstrap-icons/font/bootstrap-icons.css"; // Webpack uses file-loader to handle font files
import { ReactElement, useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import LocalStorageHelper from "../../../core/utilities/local-storage-helper";
import { isQueryLoading } from "../../../core/utilities/responseStateHelper";
import FilterActionItemEventsDto from "../../../domain/dtos/action-items/filter-action-item-events-dto";
import { ActionInstanceStatus } from "../../../domain/enums/action-items/ActionInstanceStatus";
import {
    getEventColour,
    transformToEventInput,
    useFilterActionItemEvents,
} from "../../../domain/viewmodels/action-items/my-action-calendar-viewmodel";
import ActionItemEventPopoverBody from "../../organisms/action-items/action-item-event-popover";
import InlineActionItemEvent from "../../organisms/action-items/inline-action-item-event";
import Legend from "../../organisms/legend";

const StyledFullCalendarWrapper = styled.div`
    --fc-button-bg-color: ${(props) => props.theme.palette.primary};
    --fc-button-border-color: ${(props) => props.theme.palette.primary};
    --fc-button-hover-bg-color: ${(props) => props.theme.palette.primaryVariant};
    --fc-button-hover-border-color: ${(props) => props.theme.palette.primaryVariant};
    --fc-button-active-bg-color: ${(props) => props.theme.palette.primaryVariant};
    --fc-button-active-border-color: ${(props) => props.theme.palette.primaryVariant};

    .fc-daygrid-day-number,
    .fc-col-header-cell-cushion {
        color: ${(props) => props.theme.palette.secondary};
        text-decoration: none;
    }

    .fc-toolbar-title {
        color: ${(props) => props.theme.palette.secondary};
    }

    .fc-button {
        text-transform: capitalize;
    }
`;

const MyActionItemsCalendarContainer: React.FC = () => {
    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const themeContext = useContext(ThemeContext);
    const auth = useAuth();

    const [filterActionItemEventsDto, setFilterActionItemEventsDto] =
        useState<FilterActionItemEventsDto>();

    const filterActionItemEvents = useFilterActionItemEvents(filterActionItemEventsDto);

    useLoader(isQueryLoading(filterActionItemEvents), MyActionItemsCalendarContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.MyActionCalendar);
        menu.changeActiveDrawerItem(DrawerTitles.MyActionCalendar);
    }, []);

    const buildToolbar = (): ToolbarInput => ({
        left: "today prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
    });

    const buildPlugins = (): PluginDef[] => [dayGridPlugin, timeGridPlugin, interactionPlugin];

    const onDateSetChanged = (datesSet: DatesSetArg): void => {
        if (filterActionItemEventsDto === undefined) {
            setFilterActionItemEventsDto(
                new FilterActionItemEventsDto(datesSet.start, datesSet.end)
            );

            return;
        }

        if (
            datesSet.end > filterActionItemEventsDto.dateOnlyRangeEnd ||
            datesSet.start < filterActionItemEventsDto.dateOnlyRangeStart
        ) {
            setFilterActionItemEventsDto(
                new FilterActionItemEventsDto(datesSet.start, datesSet.end)
            );
        }
    };

    const onDateSelected = (dateSelect: DateSelectArg): void => {
        const params = [
            createNavigateSearchParameter("startDate", dateSelect.startStr),
            createNavigateSearchParameter("dueDate", dateSelect.endStr),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItems)}/create`, params);
    };

    const toEventInput = (eventInput: EventInput): EventInput =>
        transformToEventInput(eventInput, themeContext!);

    const buildEventContent =
        () =>
        (eventContent: EventContentArg): ReactElement<HTMLDivElement> => (
            <OverlayTrigger
                placement={"top"}
                overlay={
                    <Popover>
                        <ActionItemEventPopoverBody
                            status={eventContent.event.extendedProps.status}
                            labelValueMap={
                                new Map([
                                    [t("Name", { keyPrefix: Common }), eventContent.event.title],
                                    [
                                        t("Start", { keyPrefix: Common }),
                                        eventContent.event.extendedProps.startDateLocalString,
                                    ],
                                    [
                                        t("Due", { keyPrefix: Common }),
                                        eventContent.event.extendedProps.endDateLocalString,
                                    ],
                                    [
                                        t("Type", { keyPrefix: Common }),
                                        eventContent.event.extendedProps.type,
                                    ],
                                    [
                                        t("Status", { keyPrefix: Common }),
                                        eventContent.event.extendedProps.statusString,
                                    ],
                                    [t("Urgency"), eventContent.event.extendedProps.urgency],
                                    [
                                        t("Participation"),
                                        eventContent.event.extendedProps.raciParticipation,
                                    ],
                                ])
                            }
                        />
                    </Popover>
                }
            >
                <div>
                    <InlineActionItemEvent
                        startDateLocal={eventContent.event.start!}
                        title={eventContent.event.title}
                        raciParticipation={eventContent.event.extendedProps.raciParticipationShort}
                    />
                </div>
            </OverlayTrigger>
        );

    const onEventClicked = (eventClick: EventClickArg): void => {
        if (eventClick.event.extendedProps.isActionItemInstanceEvent) {
            const params = [
                createNavigateSearchParameter("actionItemInstanceId", eventClick.event.id),
            ];

            navigateSearch(getPath(DrawerTitles.MyActionItems), params);
        } else {
            navigate(getPath(DrawerTitles.TriggerAdhocs));
        }
    };

    const buildLegend = (): ReactElement<HTMLDivElement> => (
        <Legend
            labelColourMap={
                new Map([
                    [t("Overdue"), getEventColour(ActionInstanceStatus.Overdue, themeContext!)],
                    [t("OnHold"), getEventColour(ActionInstanceStatus.OnHold, themeContext!)],
                    [t("Pending"), getEventColour(ActionInstanceStatus.Pending, themeContext!)],
                    [t("Due"), getEventColour(ActionInstanceStatus.Due, themeContext!)],
                ])
            }
        />
    );

    return (
        <>
            <PageHeading>{t("MyActionItemsCalendar")}</PageHeading>
            <SectionVerticalSpace />

            <ContentContainer>
                <StyledFullCalendarWrapper>
                    <FullCalendar
                        headerToolbar={buildToolbar()}
                        plugins={buildPlugins()}
                        initialView={"dayGridMonth"}
                        eventDisplay={"block"}
                        aspectRatio={2.5}
                        locale={LocalStorageHelper.getLanguageCulture()}
                        timeZone={LocalStorageHelper.getIanaTimeZoneId()}
                        selectable={!auth.isAdmin || auth.isImpersonating}
                        datesSet={onDateSetChanged}
                        events={filterActionItemEvents.data}
                        eventDataTransform={toEventInput}
                        eventContent={buildEventContent}
                        eventClick={onEventClicked}
                        select={onDateSelected}
                    />
                </StyledFullCalendarWrapper>

                <LargeVerticalSpace />
                {buildLegend()}
            </ContentContainer>
        </>
    );
};

export default MyActionItemsCalendarContainer;
