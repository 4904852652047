import * as d3 from "d3";
import { HierarchyNode } from "d3";
import d3ContextMenu from "d3-context-menu";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import {
    ActionItems,
    HierarchyTypeTreeView,
    Nodes,
} from "../../core/constants/translation-namespace";
import { hasFlag, HierarchyMenuType } from "../../core/hierarchyMenuType";
import useLoader from "../../core/hooks/loaderManager";
import { ContentContainer } from "../../core/theme/global-styles";
import { globalTheme } from "../../core/theme/global-theme";
import { AccordionTitles } from "../../core/utilities/enums";
import { getPath } from "../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../core/utilities/responseStateHelper";
import { NodeHierarchyDescendantResponse } from "../../domain/responses/hierarchy/node-hierarchy-response";
import { useGetNodeDetails } from "../../domain/viewmodels/hierarchy/node-hierarchy-viewmodel";
import SbModal, { ModalSize } from "../molecules/SbModal";
import SbTabs, { SbTabItemProps } from "../molecules/SbTabs";
import AssignedActionItemsToNodeContainer from "../templates/hierarchy/AssignedActionItemsToNodeContainer";
import AssignedChecklistsToNodeContainer from "../templates/hierarchy/AssignedChecklistsToNodeContainer";
import AssignedUsersToNodeContainer from "../templates/hierarchy/AssignedUsersToNodeContainer";
import "./HierarchyTree.css";

const ElementTagProps = {
    group: "g",
    text: "text",
    circle: "circle",
    node: "g.node",
    circleNode: "circle.node",
    ghostCircle: "ghostCircle",
    ghostCircleShow: "ghostCircle show",
    circleGhostCircle: "circle.ghostCircle",
    circleGhostCircleShow: "circle.ghostCircle.show",
    path: "path",
    link: "path.link",
    tooltip: "d3-tooltip",
};

const AttributeProps = {
    class: "class",
    width: "width",
    height: "height",
    viewBox: "viewBox",
    fill: "fill",
    opacity: "opacity",
    fillOpacity: "fill-opacity",
    transform: "transform",
    textAnchor: "text-anchor",
    radius: "r",
    drawPath: "d",
    yShift: "dy",
    x: "x",
};

const ClassNameProps = {
    node: "node",
    link: "link",
};

const TypenameProps = {
    zoom: "zoom",
    click: "click",
    contextMenu: "contextmenu",
    mousedown: "mousedown",
    mouseover: "mouseover",
    mouseout: "mouseout",
};

const dashedLine = "8, 4";
const line = "1, 0";

const StyledParentDiv = styled.div`
    position: relative;
    overflow: hidden;
`;

const StyledHeading = styled.h6`
    color: ${(props) => props.theme.palette.primary};
`;

export interface DataManagementProps {
    data: NodeHierarchyDescendantResponse | undefined;
    isDataLoading: boolean;
    isDataSuccessful: boolean;
}

export interface LeafDataManagementProps extends DataManagementProps {
    onClick: (nodeId: number) => void;
}

export const createDataManagementProps = (
    data: NodeHierarchyDescendantResponse | undefined,
    isDataLoading: boolean,
    isDataSuccessful: boolean
): DataManagementProps => ({
    data: data,
    isDataLoading: isDataLoading,
    isDataSuccessful: isDataSuccessful,
});

export const createLeafDataManagementProps = (
    data: NodeHierarchyDescendantResponse | undefined,
    isDataLoading: boolean,
    isDataSuccessful: boolean,
    onClick: (nodeId: number) => void
): LeafDataManagementProps => ({
    ...createDataManagementProps(data, isDataLoading, isDataSuccessful),
    onClick,
});

interface NodeToExpandProps {
    dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>;
    isLoadingAllChildren: boolean;
}

const createNodeToExpandProps = (
    dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>,
    isLoadingAllChildren: boolean = false
): { isLoadingAllChildren: boolean; dataNode: HierarchyNode<NodeHierarchyDescendantResponse> } => ({
    dataNode: dataNode,
    isLoadingAllChildren: isLoadingAllChildren,
});

interface MenuProps {
    title: string;
    action: (_: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => void;
    enabled?: boolean;
}

const createMenuProps = (
    title: string,
    action: (_: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => void,
    enabled?: boolean
): MenuProps => ({
    title: title,
    enabled: enabled,
    action: action,
});

const width = 2050;
const height = 700;
const marginStart = 120;
const transitionDuration = 750;
const nodeCircleRadius = 10;

// Create a curved path from parent to the child nodes
const diagonal = (s: any, d: any): string => {
    return `M ${s.y} ${s.x}
        C ${(s.y + d.y) / 2} ${s.x},
          ${(s.y + d.y) / 2} ${d.x},
          ${d.y} ${d.x}
    `;
};

// SVG transformation
const translate = (x: number, y: number): string => `translate(${y}, ${x})`;

const HierarchyTreeContainer: React.FC<{
    focusedPathEnabled: boolean;
    depth: number | null;
    dataManagement: DataManagementProps;
    dataDetailsBaseNavigationUrl: string;
    menuType: HierarchyMenuType;
    leafDataManagement: LeafDataManagementProps | null;
    selectNode?: (nodeId: number) => void;
}> = ({
    focusedPathEnabled,
    depth,
    dataManagement,
    dataDetailsBaseNavigationUrl,
    menuType,
    leafDataManagement,
    selectNode,
}) => {
    const selectedNodeToExpand = useRef<NodeToExpandProps | null>(null);
    const [selectedNodeIdToViewAssociations, setSelectedNodeIdToViewAssignedUsers] = useState<
        number | null
    >(null);
    const [selectedNodeInTreeFound, setSelectedNodeInTreeFound] = useState(false);
    const [focusedPath, setFocusedPath] = useState("");

    // TODO: Consider using ref here instead to optimise number of re-renders
    const [showManageAssociationsModal, setShowManageAssociationsModal] = useState(false);

    const treeSelector = useRef(null);
    const groupElement = useRef<d3.Selection<d3.BaseType, unknown, null, undefined>>();
    const root = useRef<d3.HierarchyNode<NodeHierarchyDescendantResponse>>();
    const tooltip = useRef<d3.Selection<HTMLDivElement, unknown, HTMLElement, any>>();
    const movingNodeIdRef = useRef<number | null>(null);

    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });
    const themeContext = useContext(ThemeContext);

    const { data, isDataLoading, isDataSuccessful } = dataManagement;

    const leafDataFetchEnabled = leafDataManagement !== null;

    let isLeafDataLoading = false;

    if (leafDataFetchEnabled) {
        // TODO: Investigate this use-case, seems it breaks something further down if not var
        // eslint-disable-next-line no-var
        var {
            data: leafData,
            isDataLoading: leafDataLoading,
            isDataSuccessful: isLeafDataSuccessful,
            onClick: onLeafClicked,
        } = leafDataManagement;

        isLeafDataLoading = leafDataLoading;
    }

    const nodeDetails = useGetNodeDetails(selectedNodeIdToViewAssociations);

    useLoader(
        isDataLoading || (leafDataFetchEnabled && isLeafDataLoading) || isQueryLoading(nodeDetails),
        HierarchyTreeContainer
    );

    const nodeActionsMenuEnabled = hasFlag(menuType, HierarchyMenuType.NodeActions);
    const detailsMenuEnabled = hasFlag(menuType, HierarchyMenuType.Details);
    const editMenuEnabled = hasFlag(menuType, HierarchyMenuType.Edit);
    const selectMenuEnabled = hasFlag(menuType, HierarchyMenuType.Select);
    const viewUsersMenuEnabled = hasFlag(menuType, HierarchyMenuType.ViewUsers);

    const homeMenu = [
        createMenuProps(
            t("EditNode"),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                navigate(`${dataDetailsBaseNavigationUrl}/${dataNode.data.nodeId}/edit`);
            },
            editMenuEnabled
        ),
        createMenuProps(
            t("SelectNode", { keyPrefix: HierarchyTypeTreeView }),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                selectNode && selectNode(dataNode.data.nodeId);
                d3.selectAll(ElementTagProps.circleGhostCircle).attr(
                    AttributeProps.class,
                    (d: any) =>
                        d.data.nodeId == dataNode.data.nodeId
                            ? ElementTagProps.ghostCircleShow
                            : ElementTagProps.ghostCircle
                );
            },
            selectMenuEnabled
        ),
        createMenuProps(
            //TODO: Update the Portuguese and French translations for ManageNodeDetails
            t("ManageNodeDetails", { keyPrefix: HierarchyTypeTreeView }),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                navigate(`${dataDetailsBaseNavigationUrl}/${dataNode.data.nodeId}`);
            },
            detailsMenuEnabled
        ),
        createMenuProps(
            t("ManageAssociations", { keyPrefix: HierarchyTypeTreeView }),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                setSelectedNodeIdToViewAssignedUsers(dataNode.data.nodeId);
                setShowManageAssociationsModal(true);
            },
            viewUsersMenuEnabled
        ),
        createMenuProps(
            t("NodeCreateTitle"),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                navigate(`${getPath(AccordionTitles.VisualTree)}/${dataNode.data.nodeId}/create`);
            },
            nodeActionsMenuEnabled
        ),
        createMenuProps(
            t("MoveNode"),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                movingNodeIdRef.current = dataNode.data.nodeId;
                d3.selectAll(ElementTagProps.circleGhostCircle).attr(
                    AttributeProps.class,
                    (d: any) =>
                        d.data.nodeId == movingNodeIdRef.current
                            ? ElementTagProps.ghostCircleShow
                            : ElementTagProps.ghostCircle
                );

                d3.selectAll(ElementTagProps.circle).on(
                    TypenameProps.contextMenu,
                    d3ContextMenu(moveNodeMenu)
                );
            },
            nodeActionsMenuEnabled
        ),
        createMenuProps(
            t("LoadAllChildrenForNode", { keyPrefix: HierarchyTypeTreeView }),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) =>
                getLeafData(dataNode, true),
            nodeActionsMenuEnabled
        ),
    ];

    const homeMenuOptions = homeMenu.filter((x) => x.enabled !== false);

    const moveNodeMenu = [
        createMenuProps(
            t("MoveSelectedNodeToHere", { keyPrefix: HierarchyTypeTreeView }),
            (dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                navigate(`${getPath(AccordionTitles.VisualTree)}/move`, {
                    state: {
                        candidateNodeId: movingNodeIdRef.current!,
                        destinationNodeId: dataNode.data.nodeId,
                    },
                });
            }
        ),
        createMenuProps(
            t("CancelNodeMove", { keyPrefix: HierarchyTypeTreeView }),
            (_: d3.HierarchyNode<NodeHierarchyDescendantResponse>) => {
                movingNodeIdRef.current = null;
                d3.selectAll(ElementTagProps.circleGhostCircleShow).attr(
                    AttributeProps.class,
                    (_: any) => ElementTagProps.ghostCircle
                );

                d3.selectAll(ElementTagProps.circle).on(
                    TypenameProps.contextMenu,
                    d3ContextMenu(homeMenuOptions)
                );
            }
        ),
    ];

    // Create SVG and bind its reference using useRef
    let svg = d3
        .select(treeSelector.current)
        .attr(AttributeProps.width, width)
        .attr(AttributeProps.height, height)
        .attr(AttributeProps.viewBox, [-marginStart, 0, width, height])
        .on(TypenameProps.mousedown, () => d3ContextMenu("close"));

    const zoomBehaviour = d3
        .zoom()
        .scaleExtent([0.1, 3])
        .clickDistance(3)
        .on(TypenameProps.zoom, (event) => {
            groupElement.current!.attr(AttributeProps.transform, event.transform);
        });

    useEffect(() => {
        if (!isDataSuccessful || !data) {
            return;
        }

        setFocusedPath("");

        // Clear svg when new base data is passed to this component
        d3.select(treeSelector.current).html("");

        // Create SVG and bind its reference using useRef
        svg = d3
            .select(treeSelector.current)
            .attr(AttributeProps.width, width)
            .attr(AttributeProps.height, height)
            .attr(AttributeProps.viewBox, [-marginStart, 0, width, height])
            .on(TypenameProps.mousedown, () => d3ContextMenu("close"));

        // Configure zoom behavior
        groupElement.current = svg.append(ElementTagProps.group);

        svg.call(zoomBehaviour as any);

        // Add tooltip div
        tooltip.current = d3
            .select("body")
            .append("div")
            .attr(AttributeProps.class, ElementTagProps.tooltip)
            .style(AttributeProps.opacity, 0);

        // Create root node
        root.current = d3.hierarchy(data!, (d: NodeHierarchyDescendantResponse) => d.children);
        // @ts-ignore
        root.current.x0 = 0;
        // @ts-ignore
        root.current.y0 = 0;

        draw(root.current, false);
    }, [data]);

    useEffect(() => {
        if (!isDataSuccessful || !isLeafDataSuccessful) {
            return;
        }

        const mergeTrees = (
            originalChildren: NodeHierarchyDescendantResponse[],
            newChildren: NodeHierarchyDescendantResponse[],
            depthCount: number = 0
        ): NodeHierarchyDescendantResponse[] => {
            if ((depth != null && depthCount > depth) || newChildren == null) {
                return originalChildren;
            }

            if (originalChildren == null) {
                originalChildren = newChildren;

                return originalChildren;
            }

            newChildren.forEach((newChild) => {
                const commonChild = originalChildren.find((x) => x.nodeId == newChild.nodeId);
                if (commonChild) {
                    commonChild.children = mergeTrees(
                        commonChild.children,
                        newChild.children,
                        depthCount + 1
                    );
                } else {
                    const newOriginalChildrenLength = originalChildren.push(newChild);
                    originalChildren[newOriginalChildrenLength - 1].children = mergeTrees(
                        originalChildren[newOriginalChildrenLength - 1].children,
                        newChild.children,
                        depthCount + 1
                    );
                }
            });

            return originalChildren;
        };

        const { dataNode, isLoadingAllChildren } = selectedNodeToExpand.current!;
        if (isLoadingAllChildren) {
            dataNode.data.children = mergeTrees(dataNode.data.children, leafData!.children);
        } else {
            dataNode.data.children = leafData!.children;
        }

        root.current = d3.hierarchy(data!, (d) => d.children);

        draw(dataNode, false);
    }, [leafData]);

    const getLeafData = (
        dataNode: d3.HierarchyNode<NodeHierarchyDescendantResponse>,
        isLoadingAllChildren: boolean = false
    ): void => {
        if (leafDataFetchEnabled) {
            selectedNodeToExpand.current = createNodeToExpandProps(dataNode, isLoadingAllChildren);

            onLeafClicked(dataNode.data.nodeId);
        }
    };

    const setCurrentTree = (
        currentChildren: NodeHierarchyDescendantResponse[],
        childNodeId: number,
        childrenToSet: NodeHierarchyDescendantResponse[]
    ): void => {
        if (selectedNodeInTreeFound || !currentChildren) {
            return;
        }

        if (currentChildren.find((x) => x.nodeId === childNodeId)) {
            if (currentChildren.find((x) => x.nodeId === childNodeId)!.children) {
                currentChildren.find((x) => x.nodeId === childNodeId)!.children = childrenToSet;
                setSelectedNodeInTreeFound(true);
            }
        } else {
            currentChildren.forEach((x) => setCurrentTree(x.children, childNodeId, childrenToSet));
        }
    };

    // Toggle/fetch children on click
    const onNodeClicked = (_: any, d: any): void => {
        setSelectedNodeIdToViewAssignedUsers(null);

        //reset all path color
        d3.selectAll("path").style("stroke", themeContext!.palette.hierarchyNode.defaultNodePath);

        let focusedNode = d;
        let selectedNodeWithParents = [];
        let linkedNodeName = "";

        if (focusedNode.data.linkedNodeTypeValue) {
            linkedNodeName = ` (${focusedNode.data.linkedNodeTypeValue})`;
        }

        selectedNodeWithParents.push(focusedNode.data.name + linkedNodeName);

        while (focusedNode.parent) {
            if (focusedPathEnabled && focusedNode.parent != "null") {
                d3.selectAll(
                    "#link" + focusedNode.parent?.data.nodeId + "-" + focusedNode.data.nodeId
                ).style("stroke", themeContext!.palette.primary);
            }

            focusedNode = focusedNode.parent;

            if (focusedNode.data.linkedNodeTypeValue) {
                linkedNodeName = ` (${focusedNode.data.linkedNodeTypeValue})`;
            }

            selectedNodeWithParents.push(focusedNode.data.name + linkedNodeName);
        }

        selectedNodeWithParents.reverse();

        let focusedPathString = "";

        if (selectedNodeWithParents.length > 1) {
            selectedNodeWithParents.map((node, index) =>
                index === 0 ? (focusedPathString += `${node}`) : (focusedPathString += ` | ${node}`)
            );
        } else {
            focusedPathString = selectedNodeWithParents[0];
        }

        setFocusedPath(focusedPathString);

        if (d.children) {
            d._children = d.children;
            d.children = null;

            setCurrentTree(root.current!.data.children, d.data.nodeId, []);
            setSelectedNodeInTreeFound(false);

            draw(d, true);
        } else if (d._children) {
            d.children = d._children;
            d._children = null;

            setCurrentTree(root.current!.data.children, d.data.nodeId, d._children);
            setSelectedNodeInTreeFound(false);

            draw(d, false);
        } else {
            getLeafData(d);
        }
    };

    const centreNode = (zoomElement: any, source: any, zoomBehaviour: any) => {
        zoomBehaviour.translateTo(
            zoomElement.transition().duration(transitionDuration),
            source.y,
            source.x
        );
    };

    const draw = (source: any, focusOnNode: boolean): void => {
        // Calculate height of tree dynamically
        root.current!.count();
        const newHeight = root.current!.value! * 35;

        // Declare tree layout
        const treeMap = d3
            .tree<NodeHierarchyDescendantResponse>()
            .size([Math.max(newHeight, height), width]);

        // Create node data for tree - assigns node positions
        const treeData = treeMap(root.current!);

        // Compute new tree layout
        const nodes = treeData.descendants();
        const links = nodes.slice(1);

        // Normalise for fixed-depth
        nodes.forEach((d: any) => {
            d.y = d.depth * 270;
        });

        /*
         * Nodes section
         */

        // Update the nodes
        const nodeSelection = groupElement
            .current!.selectAll(ElementTagProps.node)
            .data(nodes, (d: any) => d.data.nodeId);

        // Enter any new nodes at the parent's previous position
        const nodeEnter = nodeSelection
            .enter()
            .append(ElementTagProps.group)
            .attr(AttributeProps.class, ClassNameProps.node)
            .attr(
                AttributeProps.transform,
                (_: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) =>
                    translate(source.x0, source.y0)
            )
            .on(TypenameProps.click, onNodeClicked)
            .on(
                TypenameProps.contextMenu,
                d3ContextMenu(movingNodeIdRef.current == null ? homeMenuOptions : moveNodeMenu)
            )
            .on(
                TypenameProps.mouseover,
                (event, dataNode: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) => {
                    if (dataNode.data.description) {
                        tooltip
                            .current!.transition()
                            .duration(200)
                            .style(AttributeProps.opacity, 0.9)
                            .style("z-index", 10000);

                        tooltip
                            .current!.text(dataNode.data.description)
                            .style("left", `${event.pageX}px`)
                            .style("top", `${event.pageY}px`)
                            .style("z-index", 10000);
                    }
                }
            )
            .on(
                TypenameProps.mouseout,
                (_: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) => {
                    tooltip.current!.transition().duration(500).style(AttributeProps.opacity, 0);
                }
            );

        // Update node attributes and styles
        // Add companion Ghost Circle to Circle for ad-hock blinking styles
        nodeEnter
            .append(ElementTagProps.circle)
            .attr(AttributeProps.class, ElementTagProps.ghostCircle)
            .attr(AttributeProps.radius, 20);

        nodeEnter
            .append(ElementTagProps.circle)
            .attr(AttributeProps.class, ClassNameProps.node)
            .attr(AttributeProps.radius, 0)
            .style(AttributeProps.fill, (d: any) =>
                d._children
                    ? globalTheme.palette.hierarchyNode.collapsedNode
                    : globalTheme.palette.hierarchyNode.defaultNode
            );

        // Add labels for the nodes
        nodeEnter
            .append(ElementTagProps.text)
            .attr(AttributeProps.yShift, ".35em")
            .attr(
                AttributeProps.x,
                (_: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) => -13
            )
            .attr(
                AttributeProps.textAnchor,
                (_: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) => "end"
            )
            .text(
                (
                    dataNode: d3.HierarchyPointNode<NodeHierarchyDescendantResponse> // TODO: Spend some time on concrete types with D3
                ) => {
                    let linkedNodeName = "";
                    if (dataNode.data.linkedNodeTypeValue) {
                        linkedNodeName = ` (${dataNode.data.linkedNodeTypeValue})`;
                    }
                    return dataNode.data.name + linkedNodeName;
                }
            );

        // Add escalation level to the nodes
        nodeEnter
            .append(ElementTagProps.text)
            .style("font", "10px sans-serif")
            .attr(AttributeProps.yShift, "1.5em")
            .attr(
                AttributeProps.x,
                (_: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) => 13
            )
            .attr(
                AttributeProps.textAnchor,
                (_: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) => "start"
            )
            .text(
                (dataNode: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) =>
                    dataNode.data.escalationLevelText
            );

        // Update
        const nodeUpdate = nodeEnter.merge(nodeSelection);

        // Transition to the appropriate position for the node
        nodeUpdate
            .transition()
            .duration(transitionDuration)
            .attr(
                AttributeProps.transform,
                (dataNode: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) =>
                    translate(dataNode.x, dataNode.y)
            );

        // Update node attributes and styles
        nodeUpdate
            .select(ElementTagProps.circleNode)
            .attr(AttributeProps.radius, nodeCircleRadius)
            .style(AttributeProps.fill, (d: any) =>
                d._children
                    ? globalTheme.palette.hierarchyNode.collapsedNode
                    : d.data.isTraining
                      ? globalTheme.palette.hierarchyNode.trainingNode
                      : globalTheme.palette.hierarchyNode.defaultNode
            );

        // Remove any exiting nodes
        const nodeExit = nodeSelection
            .exit()
            .transition()
            .duration(transitionDuration)
            .attr(AttributeProps.transform, (_: any) => translate(source.x, source.y))
            .remove();

        // On exit, reduce the node circle radii
        nodeExit.select(ElementTagProps.circleNode).attr(AttributeProps.radius, 0);

        // On exit, reduce the opacity of the labels
        nodeExit.select(ElementTagProps.text).style(AttributeProps.fillOpacity, 0);

        /*
         * Links section
         */

        // Update the links
        const linkSelection = groupElement
            .current!.selectAll(ElementTagProps.link)
            .data(links, (d: any) => d.data.nodeId);

        // Enter any new links at the parent's previous position
        const linkEnter = linkSelection
            .enter()
            .insert(ElementTagProps.path, ElementTagProps.group)
            .attr("id", function (d) {
                return "link" + d.parent?.data.nodeId + "-" + d.data.nodeId;
            })
            .attr(AttributeProps.class, ClassNameProps.link)
            .style("stroke-dasharray", (d: any) => (d.data.isTraining ? dashedLine : line))
            .attr(AttributeProps.drawPath, (_: any) => {
                const o = { x: source.x0, y: source.y0 };
                return diagonal(o, o);
            });

        // Update
        const linkUpdate = linkEnter.merge(linkSelection as any);

        // Transition back to the parent element position
        linkUpdate
            .transition()
            .duration(transitionDuration)
            .attr(
                AttributeProps.drawPath,
                (dataNode: d3.HierarchyPointNode<NodeHierarchyDescendantResponse>) =>
                    diagonal(dataNode, dataNode.parent)
            );

        // Remove any exiting links
        linkSelection
            .exit()
            .transition()
            .duration(transitionDuration)
            .attr(AttributeProps.drawPath, (_: any) => {
                const o = { x: source.x, y: source.y };
                return diagonal(o, o);
            })
            .remove();

        // Store the old positions for transition
        nodes.forEach((d: any) => {
            d.x0 = d.x;
            d.y0 = d.y;
        });

        focusOnNode && centreNode(svg, source, zoomBehaviour);
    };

    const buildSbTabItemProps = (): SbTabItemProps[] => {
        return [
            new SbTabItemProps(
                t("ViewNodeUsers", { keyPrefix: HierarchyTypeTreeView }),
                (
                    <AssignedUsersToNodeContainer
                        selectedNodeIdToViewAssignedUsers={selectedNodeIdToViewAssociations}
                    />
                ),
                true
            ),
            new SbTabItemProps(
                t("AssociatedChecklists", { keyPrefix: HierarchyTypeTreeView }),
                (
                    <AssignedChecklistsToNodeContainer
                        nodeId={selectedNodeIdToViewAssociations}
                        isChecklistAssignable={nodeDetails.data!.node.isChecklistAssignable}
                    />
                )
            ),
            new SbTabItemProps(
                t("ActionItems", { keyPrefix: ActionItems }),
                <AssignedActionItemsToNodeContainer nodeId={selectedNodeIdToViewAssociations} />
            ),
        ];
    };

    return (
        <StyledParentDiv>
            {focusedPathEnabled && focusedPath && (
                <>
                    <StyledHeading>{t("FocusedPath")}:</StyledHeading>
                    <p>{focusedPath}</p>
                </>
            )}

            {isDataSuccessful && data && <svg ref={treeSelector} />}

            {isQuerySuccessful(nodeDetails) && (
                <SbModal
                    title={t("ManageAssociations", { keyPrefix: HierarchyTypeTreeView })}
                    subtitle={nodeDetails.data?.node.path ?? undefined}
                    body={
                        <ContentContainer>
                            <SbTabs values={buildSbTabItemProps()} />
                        </ContentContainer>
                    }
                    isVisible={showManageAssociationsModal}
                    updateIsVisible={setShowManageAssociationsModal}
                    size={ModalSize.Large}
                    showCloseButton
                    centered
                />
            )}
        </StyledParentDiv>
    );
};

export default HierarchyTreeContainer;
