import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTTPError } from "ky";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ActionItems, ActionPlans, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemDetailsDto } from "../../../domain/dtos/actions/action-item-details-dto";
import { ActionPlanDetailsDto } from "../../../domain/dtos/actions/action-plan-details-dto";
import { BasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { useGetActionItemDetailsByActionItemId } from "../../../domain/viewmodels/actions/action-item-details-viewmodel";
import {
    useGetActionPlanDetails,
    useGetActionPlanDetailsFromActionItem,
} from "../../../domain/viewmodels/actions/action-plan-details-viewmodel";
import { useTransferActionItem } from "../../../domain/viewmodels/actions/transfer-action-item-viewmodel";
import { CancelButton, ConfirmButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";

const StyledContainerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.theme.padding.lg};
`;

const StyledItemDiv = styled.div`
    flex: 1;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.palette.primary};
`;

interface TransferActionItemProps {
    destinationActionPlanId: number;
    actionItemId: number;
}

const TransferActionItemContainer: React.FC = () => {
    const menu = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });

    const [associatedActionItemsPaginationDto, setAssociatedActionItemsPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);

    const { destinationActionPlanId, actionItemId } = location.state as TransferActionItemProps;

    const getActionItemDetailsByActionItemId = useGetActionItemDetailsByActionItemId(actionItemId);

    const getDestinationActionPlanDetails = useGetActionPlanDetails(
        destinationActionPlanId,
        associatedActionItemsPaginationDto
    );

    const getCandidateActionPlanDetailsByActionItemId =
        useGetActionPlanDetailsFromActionItem(actionItemId);
        
    const candidateActionPlanDetailsResponseData = getCandidateActionPlanDetailsByActionItemId.data;

    const transferActionItem = useTransferActionItem();

    const destinationActionPlanDetailsResponseData = getDestinationActionPlanDetails[0].data;

    const actionItemDetailsResponseData = getActionItemDetailsByActionItemId.data;

    useLoader(
        areQueriesLoading([
            ...getDestinationActionPlanDetails,
            getCandidateActionPlanDetailsByActionItemId,
            getActionItemDetailsByActionItemId,
        ]) || isMutationLoading(transferActionItem),
        TransferActionItemContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
    }, []);

    const onTransferActionItemSuccess = async (): Promise<void> => {
        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "ActionItemTransferredSuccessfully"),
        ];

        navigateSearch(getPath(DrawerTitles.ActionPlans), params);
    };

    const onActionItemTransferConfirmed = (): void => {
        transferActionItem.mutate(
            {
                candidateActionPlanId: candidateActionPlanDetailsResponseData?.actionPlanId!,
                destinationActionPlanId: destinationActionPlanId,
                actionItemId: actionItemId,
            },
            {
                onSuccess: onTransferActionItemSuccess,
                onError: (error: HTTPError) => {
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const buildCandidateActionPlanContent = (): ReactElement<HTMLDivElement> => (
        <>
            {buildNodeDetailsContent(
                candidateActionPlanDetailsResponseData!,
                actionItemDetailsResponseData!
            )}
        </>
    );

    const buildDestinationActionPlanContent = (): ReactElement<HTMLDivElement> => (
        <>
            {buildNodeDetailsContent(
                destinationActionPlanDetailsResponseData!,
                actionItemDetailsResponseData!
            )}
        </>
    );

    const buildNodeDetailsContent = (
        actionPlanDetailsDto: ActionPlanDetailsDto,
        actionItemDetailDto: ActionItemDetailsDto
    ): ReactElement<HTMLDivElement> => (
        <>
            <DetailsLabel>{t("ActionPlanName", { keyPrefix: ActionPlans })}</DetailsLabel>
            <DetailsValue>{actionPlanDetailsDto.name}</DetailsValue>

            <DetailsLabel>{t("ActionItemName", { keyPrefix: ActionItems })}</DetailsLabel>
            <DetailsValue>{actionItemDetailDto.name}</DetailsValue>

            <DetailsLabel>{t("ActionPlanDescription", { keyPrefix: ActionPlans })}</DetailsLabel>
            <DetailsValue>{actionPlanDetailsDto.description}</DetailsValue>

            <DetailsLabel>{t("OwnerEmailAddress", { keyPrefix: Common })}</DetailsLabel>
            <DetailsValue>{actionPlanDetailsDto.ownerUserEmail}</DetailsValue>
        </>
    );

    return (
        <>
            <PageHeading>{t("TransferActionItem")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([
                ...getDestinationActionPlanDetails,
                getCandidateActionPlanDetailsByActionItemId,
                getActionItemDetailsByActionItemId,
            ]) && (
                <ContentContainer>
                    <StyledContainerDiv>
                        <StyledItemDiv>
                            <TextTitledPanel title={t("CandidateActionPlan")}>
                                {buildCandidateActionPlanContent()}
                            </TextTitledPanel>
                        </StyledItemDiv>

                        <StyledIcon icon={faArrowRight} size={"3x"} />

                        <StyledItemDiv>
                            <TextTitledPanel title={t("DestinationActionPlan")}>
                                {buildDestinationActionPlanContent()}
                            </TextTitledPanel>
                        </StyledItemDiv>
                    </StyledContainerDiv>
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <ConfirmButton type={"button"} onClick={onActionItemTransferConfirmed} />
                        <CancelButton onClick={() => navigate(-1)} />
                    </EndAlignedDiv>
                </ContentContainer>
            )}
        </>
    );
};

export default TransferActionItemContainer;
