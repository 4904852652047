import React, { useContext, useState } from "react";
import { DefaultTheme, ThemeContext } from "styled-components";
import { globalTheme } from "../theme/global-theme";

export interface UrlContextType {
    originalBaseUrl: string; // admin URL (if logged in as admin)
    baseUrl: string; // might be admin or tenant
    updateBaseUrlToCurrentLocation: () => void;
    updateBaseUrl: (url: string) => void;
}

const UrlContext = React.createContext<UrlContextType>({
    originalBaseUrl: "",
    baseUrl: "",
    updateBaseUrlToCurrentLocation: () => {},
    updateBaseUrl: () => {},
});

function selectUrl(windowUrl: string): string {
    let selectedUrl = "";

    if (windowUrl.includes("admin")) {
        selectedUrl = process.env.REACT_APP_API_URL_ADMIN!.toString();
    } else if (windowUrl.includes("controlfirst")) {
        selectedUrl = process.env.REACT_APP_API_URL_STANDARD_BANK!.toString();
    } else {
        if (process.env.REACT_APP_ENV !== "local") {
            selectedUrl = process.env.REACT_APP_API_AW_URL!.toString();
        } else {
            selectedUrl = process.env.REACT_APP_API_URL_ADMIN!.toString();
        }
    }

    return selectedUrl;
}

function updateTheme(themeContext: DefaultTheme, windowUrl: string): string {
    return themeContext!.palette.theme.standardBankPrimary;
}

export function UrlProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const themeContext = useContext(ThemeContext);
    const [baseUrl, setBaseUrl] = useState("");
    const [originalBaseUrl, setOriginalBaseUrl] = useState("");

    const updateBaseUrlToCurrentLocation = (): void => {
        const hostName = window.location.hostname;

        const selectedApiUrl = selectUrl(hostName);

        globalTheme.palette.primary = updateTheme(themeContext!, hostName);

        setOriginalBaseUrl(selectedApiUrl);
        setBaseUrl(selectedApiUrl);
    };

    const updateBaseUrl = (url: string): void => setBaseUrl(url);

    const value = {
        originalBaseUrl,
        baseUrl,
        updateBaseUrlToCurrentLocation,
        updateBaseUrl,
    };

    return <UrlContext.Provider value={value}>{children}</UrlContext.Provider>;
}

export function useUrl(): UrlContextType {
    return useContext(UrlContext);
}
