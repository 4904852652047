import { AiFillDashboard } from "react-icons/ai";
import {
    BsArchiveFill,
    BsCardChecklist,
    BsChevronDown,
    BsChevronLeft,
    BsChevronRight,
    BsChevronUp,
    BsEnvelopeFill,
    BsFillCalendarCheckFill,
    BsFillCheckSquareFill,
    BsFillGrid3X3GapFill,
    BsFillQuestionCircleFill,
    BsFillTelephoneFill,
    BsFlagFill,
    BsPieChartFill,
    BsSortDownAlt,
} from "react-icons/bs";
import {
    FaBuilding,
    FaCalendarAlt,
    FaCoffee,
    FaCog,
    FaCopy,
    FaDatabase,
    FaEdit,
    FaFlag,
    FaFolderOpen,
    FaHistory,
    FaHome,
    FaListOl,
    FaNewspaper,
    FaPlay,
    FaShare,
    FaTimesCircle,
    FaUniversity,
    FaUserAlt,
    FaUsers,
} from "react-icons/fa";
import { RiDashboard2Fill, RiShareForwardFill } from "react-icons/ri";
import { actionItemActions, DrawerTitles, NavbarTitles } from "./enums";

export const getIcon = (item: string, size?: string): JSX.Element | undefined => {
    switch (item) {
        // Navbar
        case NavbarTitles.Checklists:
            return <BsCardChecklist size={size} />;
        case NavbarTitles.Admin:
            return <FaCog size={size} />;
        case NavbarTitles.ActionTracker:
            return <BsFlagFill size={size} />;
        case NavbarTitles.MyActionCalendar:
            return <FaCalendarAlt size={size} />;
        case NavbarTitles.GoToRMP:
            return <FaHome size={size} />;
        case NavbarTitles.Help:
            return <BsFillQuestionCircleFill size={size} />;
        case NavbarTitles.Support:
            return <BsFillTelephoneFill size={size} />;
        // Drawer
        case DrawerTitles.TeamAnswerSummary:
            return <FaUsers size={size} />;
        case DrawerTitles.AnswerSummary:
            return <BsFillGrid3X3GapFill size={size} />;
        case DrawerTitles.AnswerCapture:
            return <BsSortDownAlt size={size} />;
        case DrawerTitles.CompletedAnswers:
            return <BsPieChartFill size={size} />;
        case DrawerTitles.TriggerAdhocs:
            return <FaPlay size={size} />;
        case DrawerTitles.PendingAdhocs:
            return <AiFillDashboard size={size} />;
        case DrawerTitles.Reviews:
            return <BsFillCheckSquareFill size={size} />;
        case DrawerTitles.ReviewsHistory:
            return <FaHistory size={size} />;
        case DrawerTitles.Library:
            return <FaFolderOpen size={size} />;
        case DrawerTitles.Hierarchy:
            return <FaBuilding size={size} />;
        case DrawerTitles.NonWorkingDay:
            return <BsFillCalendarCheckFill size={size} />;
        case DrawerTitles.UserManagement:
            return <FaUserAlt size={size} />;
        case DrawerTitles.EmailQueue:
            return <BsEnvelopeFill size={size} />;
        case DrawerTitles.Audits:
            return <FaDatabase size={size} />;
        case DrawerTitles.WizardCards:
            return <FaNewspaper size={size} />;
        case DrawerTitles.MyActionCalendar:
            return <FaCalendarAlt size={size} />;
        case DrawerTitles.ActionPlans:
            return <FaBuilding size={size} />;
        case DrawerTitles.ActionItems:
            return <FaFlag size={size} />;
        case DrawerTitles.MyActionSummary:
            return <BsFillGrid3X3GapFill size={size} />;
        case DrawerTitles.MyActionItems:
            return <BsCardChecklist size={size} />;
        case DrawerTitles.FinalizedActionItems:
            return <BsArchiveFill size={size} />;
        case DrawerTitles.ActionItemDynamicFields:
            return <FaListOl size={size} />;
        case DrawerTitles.NotificationTriggers:
            return <RiShareForwardFill size={size} />;
        case DrawerTitles.Dashboard:
            return <RiDashboard2Fill size={size} />;
        case "Drawer Open":
            return <BsChevronLeft size={size} />;
        case "Drawer Closed":
            return <BsChevronRight size={size} />;
        case "Accordion Open":
            return <BsChevronUp size={size} />;
        case "Accordion Closed":
            return <BsChevronDown size={size} />;
        // Action Items
        case actionItemActions.Extend:
            return <FaShare size={size} />;
        case actionItemActions.PlaceOnHold:
            return <FaCoffee size={size} />;
        case actionItemActions.CancelItem:
            return <FaTimesCircle size={size} />;
        case actionItemActions.Edit:
            return <FaEdit size={size} />;
        case actionItemActions.Clone:
            return <FaCopy size={size} />;
        default:
            return undefined;
    }
};
