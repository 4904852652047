import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { checklistsColumnNames } from "../../../core/utilities/dataTableColumns";
import { checklistColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterChecklistsDto,
    FilterChecklistsDto,
} from "../../../domain/dtos/checklists/filter-checklists-dto";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import DataStatus from "../../../domain/enums/common/DataStatus";
import { ChecklistRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { useFilterLibraryChecklists } from "../../../domain/viewmodels/checklists/view-checklists-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { SbRibbon } from "../../atoms/SbRibbon";
import { DataTable } from "../../organisms/DataTable";
import { ChecklistsFilter } from "../../organisms/filters/ChecklistsFilter";

interface SearchParams {
    name: string | null;
    modifiedByUserFullName: string | null;
    isTraining: DataStatus | null;
}

const createSearchParams = (
    name: string | null,
    modifiedByUserFullName: string | null,
    isTraining: DataStatus | null
): SearchParams => ({
    name: name,
    modifiedByUserFullName: modifiedByUserFullName,
    isTraining: isTraining,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null);

const checklistTypesArray = getEnumsForType(DataStatus).map((x) =>
    createReactSelectDto(x, DataStatus[x])
);

const ChecklistsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterChecklistsDto>(defaultFilterChecklistsDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation");
    const auth = useAuth();

    const filterChecklists = useFilterLibraryChecklists(filterDto);

    useLoader(isQueryLoading(filterChecklists), ChecklistsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: checklistColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const changeChecklistName = (checklistName: string): void => {
        setSearchParams({
            ...searchParams,
            name: checklistName !== "" ? checklistName : null,
        });
    };

    const changeModifiedByUser = (modifiedByUser: string): void => {
        setSearchParams({
            ...searchParams,
            modifiedByUserFullName: modifiedByUser !== "" ? modifiedByUser : null,
        });
    };

    const changeChecklistType = (option: SingleValue<ReactSelectDto<DataStatus>>): void => {
        setSearchParams({
            ...searchParams,
            isTraining: option?.value ?? null,
        });
    };

    const search = (): void => {
        setFilterDto({
            ...filterDto,
            pageNumber: 1,
            ...searchParams,
            isTraining:
                searchParams.isTraining === DataStatus.Training
                    ? true
                    : searchParams.isTraining === DataStatus.Live
                      ? false
                      : null,
        });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({
            ...filterDto,
            ...defaultBasePaginationDto,
            ...defaultSearchParams,
            isTraining: null,
        });
    };

    const navigateToViewChecklist = (checklistId: number): void =>
        navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}`);

    const navigateToEditChecklist = (checklistId: number): void =>
        navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}/edit`);

    const navigateToDeleteChecklist = (checklistId: number): void =>
        navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}/delete`);

    const buildRibbon = (): JSX.Element => (
        <SbRibbon size={"small"} label={t("Training", { keyPrefix: Common })} />
    );

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ChecklistIndexTitle", { keyPrefix: ChecklistQuestions })}</PageHeading>
            <PageSubHeading>
                {t("ChecklistHeaderHelperText", { keyPrefix: ChecklistQuestions })}
            </PageSubHeading>
            <SectionVerticalSpace />

            <ChecklistsFilter
                checklistName={searchParams.name}
                changeChecklistName={changeChecklistName}
                modifiedByUser={searchParams.modifiedByUserFullName}
                changeModifiedByUser={changeModifiedByUser}
                dataStatus={createReactSelectDto(
                    searchParams.isTraining!,
                    DataStatus[searchParams.isTraining!]
                )}
                dataStatuses={checklistTypesArray ?? []}
                changeDataStatus={changeChecklistType}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(AccordionTitles.Checklists)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />

            {isQuerySuccessful(filterChecklists) && (
                <DataTable
                    columns={checklistsColumnNames}
                    rows={filterChecklists.data!.rows}
                    keyPrefix={ChecklistQuestions}
                    viewItem={navigateToViewChecklist}
                    editItem={
                        hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.WriteRoles)
                            ? navigateToEditChecklist
                            : undefined
                    }
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.WriteRoles)
                            ? navigateToDeleteChecklist
                            : undefined
                    }
                    linkItem={navigateToViewChecklist}
                    sortBy={sortBy}
                    ribbon={buildRibbon()}
                    totalItems={filterChecklists.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default ChecklistsContainer;
