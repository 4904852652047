import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import CreateReferentialLinkTypeDto from "../../../domain/dtos/referential-links/create-referential-link-type-dto";
import { useCreateReferentialLinkType } from "../../../domain/viewmodels/referential-link-types/create-referential-link-type-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateReferentialLinkTypeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const createReferentialLinkType = useCreateReferentialLinkType();

    useLoader(isMutationLoading(createReferentialLinkType), CreateReferentialLinkTypeContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.ReferentialLinkTypes);
    }, []);

    const handleSubmit = (dto: CreateReferentialLinkTypeDto): void => {
        createReferentialLinkType.mutate(dto, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const createReferentialLinkTypeSchema = (): object => {
        return object({
            name: string()
                .max(50, t("MustBe50CharactersOrLess", { keyPrefix: Common }))
                .min(2, t("MustBe2ChractersOrMore", { keyPrefix: Common }))
                .required(t("NameIsRequired", { keyPrefix: Common })),
        });
    };

    return (
        <>
            <PageHeading>{t("ReferentialLinkTypeCreate")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("ReferentialLinkTypeCreate")}>
                <Formik
                    initialValues={new CreateReferentialLinkTypeDto("")}
                    onSubmit={handleSubmit}
                    validationSchema={createReferentialLinkTypeSchema}
                >
                    {({ handleChange, errors, touched }) => (
                        <Form>
                            <SbFormikTextFieldGroup
                                name="name"
                                label={t("Name")}
                                type="text"
                                required
                                onFormikChange={handleChange}
                                error={errors.name}
                                touched={touched.name}
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    )}
                </Formik>
            </TextTitledPanel>
        </>
    );
};

export default CreateReferentialLinkTypeContainer;
