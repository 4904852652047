import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { questionCategoryColumnNames } from "../../../core/utilities/dataTableColumns";
import { questionCategoryColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import {
    defaultFilterQuestionCategoriesDto,
    FilterQuestionCategoryDto,
} from "../../../domain/dtos/question-categories/filter-question-category-dto";
import { hasRoleTypeInGroup, QuestionCategoryRoleGroup } from "../../../domain/enums/Roles";
import { useGetQuestionCategories } from "../../../domain/viewmodels/question-categories/view-question-categories-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { QuestionCategoriesFilter } from "../../organisms/filters/QuestionCategoriesFilter";

interface SearchParams {
    questionCategoryName: string | null;
}

const createSearchParams = (questionCategoryName: string | null): SearchParams => ({
    questionCategoryName: questionCategoryName,
});

const defaultSearchParams: SearchParams = createSearchParams(null);

const QuestionCategoriesContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterQuestionCategoryDto>(
        defaultFilterQuestionCategoriesDto
    );
    const menu = useMenu();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const auth = useAuth();

    const getQuestionCategories = useGetQuestionCategories(filterDto);

    useLoader(isQueryLoading(getQuestionCategories), QuestionCategoriesContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionCategories);
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: questionCategoryColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const onQuestionCategoryNameChange = (questionCategoryName: string): void => {
        setSearchParams({ questionCategoryName });
    };

    const search = (): void => {
        setFilterDto({
            ...filterDto,
            pageNumber: 1,
            ...searchParams,
        });
    };

    const resetFilter = (): void => {
        setFilterDto({
            ...filterDto,
            ...defaultBasePaginationDto,
            ...searchParams,
        });
        setFilterDto(defaultFilterQuestionCategoriesDto);
        setSearchParams(defaultSearchParams);
    };

    const navigateToViewQuestionCategoryDetails = (questionCategoryId: number): void => {
        navigate(`${getPath(AccordionTitles.QuestionCategories)}/${questionCategoryId}`);
    };

    const navigateToEditQuestionCategory = (questionCategoryId: number): void => {
        navigate(`${getPath(AccordionTitles.QuestionCategories)}/${questionCategoryId}/edit`);
    };
    const navigateToDeleteQuestionCategory = (questionCategoryId: number): void => {
        navigate(`${getPath(AccordionTitles.QuestionCategories)}/${questionCategoryId}/delete`);
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("QuestionCategoryTitle")}</PageHeading>
            <PageSubHeading>{t("QuestionCategoryHeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            <QuestionCategoriesFilter
                questionCategoryName={searchParams.questionCategoryName}
                changeQuestionCategoryName={onQuestionCategoryNameChange}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            <EndAlignedDiv>
                <CreateLink
                    label={t("CreateNew", { keyPrefix: Common })}
                    navigateTo={`${getPath(AccordionTitles.QuestionCategories)}/create`}
                />
            </EndAlignedDiv>
            <LargeVerticalSpace />

            {isQuerySuccessful(getQuestionCategories) && (
                <DataTable
                    columns={questionCategoryColumnNames}
                    keyPrefix={ChecklistQuestions}
                    rows={getQuestionCategories.data!.rows}
                    viewItem={navigateToViewQuestionCategoryDetails}
                    linkItem={navigateToViewQuestionCategoryDetails}
                    editItem={
                        hasRoleTypeInGroup(auth.userRoles, QuestionCategoryRoleGroup.WriteRoles)
                            ? navigateToEditQuestionCategory
                            : undefined
                    }
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, QuestionCategoryRoleGroup.WriteRoles)
                            ? navigateToDeleteQuestionCategory
                            : undefined
                    }
                    sortBy={sortBy}
                    totalItems={getQuestionCategories.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default QuestionCategoriesContainer;
