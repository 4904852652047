import { useEffect } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { AuditChangelogResponse } from "../../../domain/responses/audits/audit-change-log-response";
import { useGetAudit } from "../../../domain/viewmodels/audits/audits-change-log-viewmodel";
import { SbButton } from "../../atoms/SbButton";

const StyledModal = styled(Modal)`
    --bs-modal-width: 750px;
`;

const StyledTable = styled(Table)`
    background-color: white;
    padding: ${(props) => props.theme.padding.lg};
`;

const ViewAuditsChangelogContainer: React.FC<{
    changelogId: number;
    show: boolean;
    handleClose: () => void;
}> = ({ changelogId, show, handleClose }) => {
    const menu = useMenu();

    const auditDetails = useGetAudit(changelogId);

    useLoader(isQueryLoading(auditDetails), ViewAuditsChangelogContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Audits, DrawerTitles.Audits);
    }, []);

    const getDetails = (result: AuditChangelogResponse, key: string): [] | string[] => {
        const objectOldValues: string = JSON.parse(result.oldValues);
        const objectNewValues: string = JSON.parse(result.newValues);
        switch (key) {
            case "keys":
                return objectNewValues ? Object.keys(objectNewValues) : [];
            case "oldValues":
                return objectOldValues ? Object.values(objectOldValues) : [];
            case "newValues":
                return objectNewValues ? Object.values(objectNewValues) : [];
        }
        return [];
    };

    return (
        <StyledModal show={show}>
            <ContentContainer>
                <PageHeading>Audit Log - Data Change Details</PageHeading>
                <SectionVerticalSpace />

                {isQuerySuccessful(auditDetails) && (
                    <>
                        <StyledTable borderless>
                            <thead>
                                <tr>
                                    <th>
                                        <DetailsLabel>Column</DetailsLabel>
                                    </th>
                                    <th>
                                        <DetailsLabel>Old Value</DetailsLabel>
                                    </th>
                                    <th>
                                        <DetailsLabel>New Value</DetailsLabel>
                                    </th>
                                </tr>
                                {auditDetails.status === "success" &&
                                    auditDetails.fetchStatus === "idle" && (
                                        <tr>
                                            <th>
                                                {getDetails(auditDetails.data!, "keys").map(
                                                    (updatedColumn) => (
                                                        <DetailsValue>{updatedColumn}</DetailsValue>
                                                    )
                                                )}
                                            </th>
                                            <th>
                                                {getDetails(auditDetails.data!, "oldValues").map(
                                                    (oldValue) => (
                                                        <DetailsValue>{oldValue}</DetailsValue>
                                                    )
                                                )}
                                            </th>
                                            <th>
                                                {getDetails(auditDetails.data!, "newValues").map(
                                                    (newValue) => (
                                                        <DetailsValue>{newValue}</DetailsValue>
                                                    )
                                                )}
                                            </th>
                                        </tr>
                                    )}
                            </thead>
                        </StyledTable>

                        <EndAlignedDiv>
                            <SbButton
                                variant="primary"
                                type="button"
                                label="Cancel"
                                onClick={handleClose}
                            />
                        </EndAlignedDiv>
                    </>
                )}
            </ContentContainer>
        </StyledModal>
    );
};

export default ViewAuditsChangelogContainer;
