import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { object, string } from "yup";
import { AllowedExtensionsForActionPlan } from "../../../core/constants/FileExtension";
import {
    ActionItems,
    ActionPlans,
    Common,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getBase64FileString } from "../../../core/utilities/file-helper";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { CreateActionPlanDto } from "../../../domain/dtos/actions/create-action-plan-dto";
import { EditActionPlanDto } from "../../../domain/dtos/actions/edit-action-plan-dto";
import { BaseUserDto, createBaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import {
    useDownloadActionPlanFile,
    useEditActionPlan,
    useGetActionPlanDetails,
    useRemoveActionPlanFile,
    useUploadActionPlanFile,
} from "../../../domain/viewmodels/actions/edit-action-plan-viewmodel";
import { useFilterUsers } from "../../../domain/viewmodels/hierarchy/node-details-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextAreaGroup from "../../molecules/input/SbFormikTextAreaGroup";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { SbAccordion } from "../../molecules/SbAccordion";
import SbModal from "../../molecules/SbModal";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { FileGallery } from "../../organisms/FileGallery";

const EditActionPlanContainer: React.FC = () => {
    const [actionPlanFileId, setActionPlanFileId] = useState<number | null>(null);
    const [removeActionPlanFileId, setRemoveActionPlanFileId] = useState<number | null>(null);
    const [
        showDeleteActionPlanFileConfirmationModal,
        setShowDeleteActionPlanFileConfirmationModal,
    ] = useState(false);
    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [assigningUser, setAssigningUser] = useState<BaseUserDto | null>(null);

    const menu = useMenu();
    const navigate = useNavigate();
    const toast = useToast();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });

    const actionPlanId = Number(useParams().actionPlanId);

    const getActionPlanDetails = useGetActionPlanDetails(actionPlanId);
    const downloadActionPlanFile = useDownloadActionPlanFile(actionPlanFileId);
    const editActionPlan = useEditActionPlan();
    const uploadActionPlanFile = useUploadActionPlanFile();
    const removeActionPlanFile = useRemoveActionPlanFile();

    const actionPlanDetailsResponse = getActionPlanDetails[0];
    const actionPlanFilesResponse = getActionPlanDetails[1];
    const actionPlanDetailsResponseData = actionPlanDetailsResponse.data;
    const actionPlanFilesResponseData = actionPlanFilesResponse.data;

    const filterUsers = useFilterUsers(1, 1000, userSearchText);

    useLoader(
        areQueriesLoading(getActionPlanDetails) ||
            areMutationsLoading([editActionPlan, uploadActionPlanFile, removeActionPlanFile]),
        EditActionPlanContainer
    );

    const userDetails = createBaseUserDto(
        Number(actionPlanDetailsResponseData?.ownerUserId!),
        "",
        "",
        actionPlanDetailsResponseData?.ownerUserEmail!,
        actionPlanDetailsResponseData?.ownerUserFullName!,
        ""
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);
    }, []);

    const handleSubmit = (dto: CreateActionPlanDto): void => {
        dto.userOwnerId = assigningUser ? assigningUser.userId : userDetails.userId;
        editActionPlan.mutate(new EditActionPlanDto(actionPlanId, dto), {
            onSuccess: async () => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "UpdateActionPlanSuccessMessage"),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const onDownloadFile = (fileId: number): void => setActionPlanFileId(fileId);

    const onDeleteFile = (fileId: number): void => {
        setShowDeleteActionPlanFileConfirmationModal(true);
        setRemoveActionPlanFileId(fileId);
    };

    const onRemoveFile = (): void => {
        removeActionPlanFile.mutate(removeActionPlanFileId!, {
            onSuccess: async () => {
                toast.addToast(createSuccessToastProps([t("EvidenceRemovedFromActionPlan")]));
                queryClient.refetchQueries(["filterActionPlanFiles"]);
            },
        });
    };

    const onChangeUserOwner = (userOption: SingleValue<BaseUserDto>) => {
        if (userOption) {
            setAssigningUser(userOption);
        } else {
            setAssigningUser(userDetails);
        }
    }

    const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        const file = selectedFiles?.[0];

        const fileBase64String = await getBase64FileString(file);

        if (fileBase64String === null) {
            toast.addToast(createErrorToastProps([t("FailedToReadFile")]));
        } else {
            uploadActionPlanFile.mutate(
                { actionPlanId, file, fileBase64String: fileBase64String! },
                {
                    onSuccess: async () => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("EvidenceSuccessfullyUploaded", { keyPrefix: ActionPlans }),
                            ])
                        );
                        queryClient.refetchQueries(["filterActionPlanFiles"]);
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }

        event.target.value = "";
    };

    const editActionPlanValidator = (): object => {
        return object({
            name: string().required(t("NameIsRequired", { keyPrefix: Common })).max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common })).required(),
            description: string().required(t("DescriptionRequired", { keyPrefix: Common })).max(1500, t("MustBe1500CharactersOrLess", { keyPrefix: Common })),
            userOwnerId: string().required(t("OwnerIsRequired", { keyPrefix: Common })),
        });
    };

    return (
        <>
            <PageHeading>{t("ActionPlanEdit")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextActionPlanEdit")}</PageSubHeading>
            <SectionVerticalSpace />

            <SbModal
                title={t("RemoveActionPlanFile")}
                body={t("RemoveActionPlanFileConfirmationText") as string}
                primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                onPrimaryButtonClicked={onRemoveFile}
                isVisible={showDeleteActionPlanFileConfirmationModal}
                updateIsVisible={setShowDeleteActionPlanFileConfirmationModal}
            />

            {isQuerySuccessful(actionPlanDetailsResponse) && (
                <TextTitledPanel title={t("EditActionPlan")}>
                    <Formik
                        initialValues={{
                            name: actionPlanDetailsResponseData?.name ?? "",
                            description: actionPlanDetailsResponseData?.description ?? "",
                            userOwnerId: actionPlanDetailsResponseData?.ownerUserId!,
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={editActionPlanValidator}
                    >
                        {({ handleChange, errors, touched }) => (
                            <Form>
                                <SbFormikTextFieldGroup
                                    name="name"
                                    label={t("Name", { keyPrefix: Common })}
                                    type="text"
                                    required
                                    defaultValue={actionPlanDetailsResponseData?.name}
                                    onFormikChange={handleChange}
                                    error={errors.name}
                                    touched={touched.name}
                                />

                                <SbFormikTextAreaGroup
                                    name="description"
                                    label={t("Description", { keyPrefix: Common })}
                                    maxLength={1500}
                                    rows={4}
                                    defaultValue={actionPlanDetailsResponseData?.description ?? ""}
                                    error={errors.description}
                                    onFormikChange={handleChange}
                                    touched={touched.description}
                                />
                                <Row className="mb-3">
                                    <SbFormSelectFieldGroup
                                        name={"userOwnerId"}
                                        label={t("Owner", { keyPrefix: Common })!}
                                        placeholderText={t("SearchForUser", { keyPrefix: Nodes })!}
                                        searchable
                                        clearable={false}
                                        required
                                        items={filterUsers?.data!}
                                        itemDisplayText={(option: BaseUserDto) =>
                                            `${option.fullName} - ${option.emailAddress}`
                                        }
                                        onChange={onChangeUserOwner}
                                        onSearchTextChanged={(text: string) =>
                                            setUserSearchText(text)
                                        }
                                        defaultSelectedItem={userDetails}
                                        error={errors.userOwnerId}
                                        touched={touched.userOwnerId}
                                    />
                                </Row>
                                <EndAlignedDiv>
                                    <SaveButton type="submit" />
                                    <CancelButton onClick={() => navigate(-1)} />
                                </EndAlignedDiv>
                            </Form>
                        )}
                    </Formik>

                    {isQuerySuccessful(actionPlanFilesResponse) && (
                        <>
                            <LargeVerticalSpace />
                            <SbAccordion title={t("AttachedEvidence", { keyPrefix: ActionItems })}>
                                <>
                                    <SectionVerticalSpace />

                                    <FileGallery
                                        files={actionPlanFilesResponseData!}
                                        filesLabel={`${t("ActionPlanFiles")}`}
                                        onDownloadFile={onDownloadFile}
                                        fileDownloadData={downloadActionPlanFile.data!}
                                        uploadLabel={`${t("AttachEvidence", {
                                            keyPrefix: ActionItems,
                                        })}`}
                                        onUploadFile={onUploadFile}
                                        onRemoveFile={onDeleteFile}
                                        supportedFileExtensions={AllowedExtensionsForActionPlan}
                                    />
                                </>
                            </SbAccordion>
                        </>
                    )}
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditActionPlanContainer;
