import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { FaBan, FaBell, FaCheck, FaExclamationTriangle } from "react-icons/fa";
import styled, { ThemeContext } from "styled-components";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import InstanceStatus from "../../../domain/enums/questions/instance-status";

interface StyledInstanceStatusDivProps {
    $colour: string;
}

const StyledInstanceStatusDiv = styled.div<StyledInstanceStatusDivProps>`
    color: ${(props) => props.$colour};
    font-size: ${(props) => props.theme.font.xl};
    font-weight: bold;
`;

const QuestionCaptureStatus: React.FC<{ status: InstanceStatus | undefined }> = ({ status }) => {
    const themeContext = useContext(ThemeContext);
    const { t } = useTranslation("translation");
    const baseInstanceStatusColour = themeContext!.palette.secondary;
    const completedInstanceStatusColour = themeContext!.palette.success;
    const overdueInstanceStatusColour = themeContext!.palette.danger;

    const buildQuestionTitleLabel = (
        label: string,
        icon: IconType,
        colour: string
    ): JSX.Element => (
        <StyledInstanceStatusDiv $colour={colour}>
            {React.createElement(icon)} {label}
        </StyledInstanceStatusDiv>
    );

    const buildInstanceStatus = (): JSX.Element => {
        switch (status) {
            case InstanceStatus.Pending:
                return buildQuestionTitleLabel("Not Due", FaBan, baseInstanceStatusColour);
            case InstanceStatus.Due:
                return buildQuestionTitleLabel("Due", FaBell, baseInstanceStatusColour);
            case InstanceStatus.Completed:
                return buildQuestionTitleLabel("Completed", FaCheck, completedInstanceStatusColour);
            case InstanceStatus.Overdue:
                return buildQuestionTitleLabel(
                    t("Overdue", { keyPrefix: ChecklistAnswers }),
                    FaExclamationTriangle,
                    overdueInstanceStatusColour
                );
            default:
                return <></>;
        }
    };

    return buildInstanceStatus();
};

export default QuestionCaptureStatus;
