import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    CreateQuestionCategoryDtoFromParams,
    QuestionCategoryDto,
} from "../../../domain/dtos/question-categories/question-category-dto";
import {
    useEditQuestionCategory,
    useGetQuestionCategoryDetails,
} from "../../../domain/viewmodels/question-categories/edit-question-category-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const EditQuestionCategoryContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const questionCategoryId = Number(useParams().questionCategoryId);
    const questionCategoryDetails = useGetQuestionCategoryDetails(questionCategoryId);
    const questionCategoryDetailsData = questionCategoryDetails.data;

    const editQuestionCategory = useEditQuestionCategory();

    useLoader(
        isQueryLoading(questionCategoryDetails) || isMutationLoading(editQuestionCategory),
        EditQuestionCategoryContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionCategories);
    }, []);

    const handleSubmit = (dto: QuestionCategoryDto): void => {
        editQuestionCategory.mutate(dto, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const createQuestionCategorySchema = (): object => {
        return object({
            name: string()
                .max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common }))
                .required(t("NameIsRequired", { keyPrefix: Common })),
        });
    };

    return (
        <>
            <PageHeading>{t("EditQuestionCategory")}</PageHeading>
            <SectionVerticalSpace />
            {isQuerySuccessful(questionCategoryDetails) && (
                <TextTitledPanel title="Edit Question Category">
                    <Formik
                        initialValues={CreateQuestionCategoryDtoFromParams(
                            questionCategoryId,
                            questionCategoryDetailsData!.name
                        )}
                        onSubmit={handleSubmit}
                        validationSchema={createQuestionCategorySchema}
                    >
                        {({ handleChange, errors, touched }) => (
                            <Form>
                                <SbFormikTextFieldGroup
                                    name="name"
                                    label={t("Name")}
                                    type="text"
                                    defaultValue={questionCategoryDetailsData!.name}
                                    required
                                    onFormikChange={handleChange}
                                    error={errors.name}
                                    touched={touched.name}
                                />
                                <EndAlignedDiv>
                                    <SaveButton type="submit" />
                                    <CancelButton onClick={() => navigate(-1)} />
                                </EndAlignedDiv>
                            </Form>
                        )}
                    </Formik>
                </TextTitledPanel>
            )}
        </>
    );
};

export default EditQuestionCategoryContainer;
