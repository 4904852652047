import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { transferActionItem } from "../../../data/services/actions/action-plans-service";
import { createTransferActionItemRequest } from "../../requests/actions/transfer-action-item-request";
import { Response } from "../../responses/common/response-response";

interface TransferActionItemParameters {
    candidateActionPlanId: number;
    destinationActionPlanId: number;
    actionItemId: number;
}

export const useTransferActionItem = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    TransferActionItemParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: TransferActionItemParameters) => {
        const { candidateActionPlanId, destinationActionPlanId, actionItemId } = mutationKey;
        const request = createTransferActionItemRequest(
            candidateActionPlanId,
            destinationActionPlanId,
            actionItemId
        );

        return transferActionItem(url.baseUrl, request);
    });
};
