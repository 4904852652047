import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DissociateUserFromNodeEvent } from "../../../core/constants/application-insights-events";
import { Users } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import {
    createUserNodeAssociationParameters,
    useDissociateUser,
    useGetUserDetails,
} from "../../../domain/viewmodels/hierarchy/dissociate-user-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateUserContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: Users });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const nodeId = Number(urlSearchParams.get("nodeId"));
    const userId = Number(urlSearchParams.get("userId"));

    const getUserDetails = useGetUserDetails(userId);
    const dissociateUser = useDissociateUser();

    useLoader(
        isQueryLoading(getUserDetails) || isMutationLoading(dissociateUser),
        DissociateUserContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleDissociateUser = (): void => {
        dissociateUser.mutate(createUserNodeAssociationParameters(nodeId, userId), {
            onSuccess: async () => {
                trackAppInsightsEvent(
                    auth.email,
                    window.location.href,
                    DissociateUserFromNodeEvent
                );
                navigate(-1);
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    DissociateUserFromNodeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={handleDissociateUser} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow("Username", getUserDetails.data?.emailAddress),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("DissociateUserNode")}
            panelTitle={t("DissociateNodeUserConfirmationDisplay")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
            areQueriesSuccessful={isQuerySuccessful(getUserDetails)}
        />
    );
};

export default DissociateUserContainer;
