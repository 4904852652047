export class CreateActionPlanDto {
    name: string;
    description: string;
    userOwnerId: Number;

    public constructor(name: string, description: string, userOwnerId: Number) {
        this.name = name;
        this.description = description;
        this.userOwnerId = userOwnerId;
    }
}
