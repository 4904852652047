export class ActionItemRaciParticipantDetailsDto {
    raciSetId: number;
    userId: number;
    raciRelationship: number;

    public constructor(raciSetId: number, userId: number, raciRelationship: number) {
        this.raciSetId = raciSetId;
        this.userId = userId;
        this.raciRelationship = raciRelationship;
    }
}
