import { TransferActionItemDto } from "../../dtos/actions/transfer-action-item-dto";

export class TransferActionItemRequest {
    candidateActionPlanId: number;
    destinationActionPlanId: number;
    actionItemId: number;

    public constructor(dto: TransferActionItemDto) {
        const { candidateActionPlanId, destinationActionPlanId, actionItemId } = dto;

        this.candidateActionPlanId = candidateActionPlanId;
        this.destinationActionPlanId = destinationActionPlanId;
        this.actionItemId = actionItemId;
    }
}

export const createTransferActionItemRequest = (
    candidateActionPlanId: number,
    destinationActionPlanId: number,
    actionItemId: number
): TransferActionItemRequest => ({
    candidateActionPlanId: candidateActionPlanId,
    destinationActionPlanId: destinationActionPlanId,
    actionItemId: actionItemId,
});
