import { useQueries, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { FaCalendar, FaListOl } from "react-icons/fa";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useAuth } from "../../../core/store/auth-context";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { getActionInstanceStatus } from "../../../data/services/actions/action-instance-status-service";
import { filterAssociatedUserNodes } from "../../../data/services/hierarchy/nodes-service";
import {
    filterUserAssignedActionItems,
    getUserDetails,
} from "../../../data/services/users/users-service";
import {
    ActionInstanceStatusDto,
    toActionInstanceStatusDtos,
} from "../../dtos/actions/action-instance-status-dto";
import { ActionItemEvidenceDto } from "../../dtos/actions/action-item-evidence-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { FilterUserAssignedActionItemsDto } from "../../dtos/users/filter-user-actions-dto";
import { toViewUserDetailsDto, ViewUserDetailsDto } from "../../dtos/users/user-details-dto";
import { ActionInstanceStatus } from "../../enums/action-items/ActionInstanceStatus";
import FilterAssociatedUserNodesRequest from "../../requests/hierarchy/filter-associated-user-nodes-request";
import { createFilterUserAssignedActionItemsRequest } from "../../requests/users/filter-user-actions-requests";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { AssociatedUserNodesResponse } from "../../responses/hierarchy/associated-user-nodes-response";
import { FilterUserAssignedActionItemsResponse } from "../../responses/users/filter-user-actions-response";

export const useGetUserDetails = (
    userId: number
): UseQueryResult<ViewUserDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getUserDetails", userId], () => getUserDetails(url.baseUrl, userId), {
        select: toViewUserDetailsDto,
        onError: errorResponseToDisplayHandler,
    });
};

export const useFilterAssociatedUserNodes = (
    userId: number,
    associatedNodesPaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const auth = useAuth();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssociatedUserNodesFilter", userId, associatedNodesPaginationDto],
        () =>
            filterAssociatedUserNodes(
                url.baseUrl,
                new FilterAssociatedUserNodesRequest(userId, associatedNodesPaginationDto)
            ),
        {
            enabled: auth.isAdmin ? auth.isImpersonating : true,
            select: (response: Response<PaginationResponse<AssociatedUserNodesResponse>>) =>
                toAssociatedUserNodesToDataTableRows(response, navigate),
            keepPreviousData: true,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toAssociatedUserNodesToDataTableRows = (
    response: Response<PaginationResponse<AssociatedUserNodesResponse>>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.nodeId,
        showRibbon: x.isTraining,
        columns: [
            {
                value: x.longDescription,
                type: DataTableColumnTypes.Link,
                linkItemAction: (nodeId: number) => {
                    navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`);
                },
            },
            {
                value: x.path,
                type: DataTableColumnTypes.Text,
            },
            {
                value: x.hasChecklistAssignments.toString(),
                type: DataTableColumnTypes.IconLink,
                icon: FaListOl,
                linkItemAction: x.hasChecklistAssignments
                    ? (nodeId: number) => {
                          navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`); //TODO navigate to the Checklist Assignments Grid View
                      }
                    : undefined,
            },
            {
                value: x.hasApplicableChecklistAssignments.toString(),
                type: DataTableColumnTypes.IconLink,
                icon: FaCalendar,
                linkItemAction: x.hasApplicableChecklistAssignments
                    ? (nodeId: number) => {
                          navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`); //TODO navigate to the Applicable Checklist Assignments Grid View
                      }
                    : undefined,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useFilterUserAssignedActionItems = (
    basePaginationDto: BasePaginationDto,
    filterUserAssignedActionItemsDto: FilterUserAssignedActionItemsDto,
    userId: number
): [
    UseQueryResult<PaginatedTableDto<FilterUserAssignedActionItemsResponse>, HTTPError>,
    UseQueryResult<ActionInstanceStatusDto[], HTTPError>,
] => {
    const url = useUrl();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterUserAssignedActions", basePaginationDto, filterUserAssignedActionItemsDto],
                queryFn: () =>
                    filterUserAssignedActionItems(
                        url.baseUrl,
                        createFilterUserAssignedActionItemsRequest(
                            basePaginationDto,
                            filterUserAssignedActionItemsDto
                        ),
                        userId
                    ),
                keepPreviousData: true,
                select: transformToDataTableRows,
                enabled: auth.isAdmin ? auth.isImpersonating : true,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionInstanceStatus"],
                queryFn: () => getActionInstanceStatus(url.baseUrl),
                enabled: auth.isAdmin ? auth.isImpersonating : true,
                select: toActionInstanceStatusDtos,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PaginatedTableDto<FilterUserAssignedActionItemsResponse>, HTTPError>,
        UseQueryResult<ActionInstanceStatusDto[], HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<FilterUserAssignedActionItemsResponse>>
): PaginatedTableDto<ActionItemEvidenceDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((actionItem) => ({
        metadata: new ActionItemEvidenceDto(
            actionItem.actionItemId,
            actionItem.actionItemInstanceId
        ),
        columns: [
            {
                value: actionItem.actionItemId?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: actionItem.name?.toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: ActionInstanceStatus[actionItem.actionInstanceStatus].toString(),
                type: DataTableColumnTypes.Text,
            },
            {
                value: toDateAndTimeFormat(actionItem.dueDateUtc.toString()),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
