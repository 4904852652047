import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { getActionPlanStatuses } from "../../../data/services/actions/action-plans-service";
import {
    ActionPlanStatusDto,
    toActionPlanStatusDtos,
} from "../../dtos/actions/action-plan-status-dto";

export const useFilterActionPlanStatus = (): UseQueryResult<ActionPlanStatusDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(["getActionPlanStatuses"], () => getActionPlanStatuses(url.baseUrl), {
        keepPreviousData: true,
        select: toActionPlanStatusDtos,
        onError: errorResponseToDisplayHandler,
    });
};
