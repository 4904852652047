import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { useDeleteWizardCard } from "../../../domain/viewmodels/wizards/delete-wizard-card-viewmodel";
import { DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";
import { useTranslation } from "react-i18next";
import { Common } from "../../../core/constants/translation-namespace";

interface ConfirmationDetailsProps {
    wizardId: number;
    heading: string;
    description: string;
}

const DeleteWizardCardContainer: React.FC = () => {
    const wizardCardId = Number(useParams().wizardCardId);

    const menu = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation("translation", { keyPrefix: Common });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const { wizardId, heading, description } = location.state as ConfirmationDetailsProps; // TODO: Fetching Checklist data on this page vs passing it through from the previous page

    const deleteWizardCard = useDeleteWizardCard();

    useLoader(isMutationLoading(deleteWizardCard), DeleteWizardCardContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.WizardCards, AccordionTitles.Wizards);
    }, []);

    const handleDeleteWizardCard = (cardId: number): void => {
        deleteWizardCard.mutate(cardId, {
            onSuccess: async () => {
                queryClient.invalidateQueries(["getWizardCards", wizardId]);
                navigate(-1);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton onClick={() => handleDeleteWizardCard(wizardCardId)} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow("Heading", heading),
        new TextConfirmationRow("Description", description),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("DeleteWizardCard")}
            panelTitle={t("DeleteWizardCard")}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
        />
    );
};

export default DeleteWizardCardContainer;
