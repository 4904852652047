import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import {
    dissociateRaciParticipant,
    getAssociatedRaciParticipantDetails,
} from "../../../data/services/actions/action-items-service";
import { ActionItemRaciParticipantDetailsDto } from "../../dtos/actions/action-item-raci-participant-details-dto";
import { DissociateRaciParticipantDto } from "../../dtos/actions/dissociate-raci-participant-dto";
import { createRaciSetUserDto, RaciSetUserDto } from "../../dtos/actions/raci-set-user-dto";
import { ActionItemRaciParticipantDetailsRequest } from "../../requests/actions/action-item-raci-participant-details-request";
import { DissociateRaciParticipantRequest } from "../../requests/actions/dissociate-raci-participant-request";
import { RaciSetUserResponse } from "../../responses/actions/raci-set-user-response";
import { Response } from "../../responses/common/response-response";

export const useGetAssociatedRaciParticipantDetails = (
    dto: ActionItemRaciParticipantDetailsDto
): UseQueryResult<RaciSetUserDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["getAssociatedRaciParticipantDetails", dto],
        () =>
            getAssociatedRaciParticipantDetails(
                url.baseUrl,
                new ActionItemRaciParticipantDetailsRequest(dto)
            ),
        {
            select: (response: Response<RaciSetUserResponse>) =>
                createRaciSetUserDto(response.data),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useDissociateRaciParticipant = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    DissociateRaciParticipantDto
> => {
    const url = useUrl();

    return useMutation((dto: DissociateRaciParticipantDto) =>
        dissociateRaciParticipant(url.baseUrl, new DissociateRaciParticipantRequest(dto))
    );
};
