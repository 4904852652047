import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Reports } from "../../core/constants/translation-namespace";
import { AgreedActionDto } from "../../domain/dtos/review-reports/agreed-action-dto";
import { ReviewTypeFields } from "../../domain/enums/review-reports/ReviewTypeFields";
import { translateText } from "../helpers/translate";
import SbFormDateTimeFieldGroup from "../molecules/input/SbFormDateTimeFieldGroup";
import SbFormTextAreaGroup from "../molecules/input/SbFormTextAreaGroup";
import { toDateAndTimeFormat } from "../../core/utilities/date-helper"

const StyledNestedTd = styled.th`
    font-weight: normal;
`;

export const AgreedActionsForm: React.FC<{
    agreedAction: AgreedActionDto;
}> = ({ agreedAction }) => {
    const { t } = useTranslation("translation", { keyPrefix: Reports });

    return (
        <StyledNestedTd colSpan={11}>
            <>
                <SbFormTextAreaGroup
                    label={translateText(t, ReviewTypeFields.AgreedActions, Reports)}
                    name={`${ReviewTypeFields.AgreedActions}${agreedAction.agreedActionId}`}
                    defaultValue={agreedAction.agreedActionValue ?? ""}
                    expand
                    maxLength={3000}
                    textAlign={"left"}
                />

                <SbFormDateTimeFieldGroup
                    label={translateText(t, ReviewTypeFields.ActionItemDueDate, Reports)}
                    name={`${ReviewTypeFields.ActionItemDueDate}${agreedAction.agreedActionId}`}
                    defaultValue={
                        agreedAction.actionItemDueDate
                            ? new Date(toDateAndTimeFormat(agreedAction.actionItemDueDate.toString()))
                            : null
                    }
                    textAlign={"left"}
                />
            </>
        </StyledNestedTd>
    );
};
