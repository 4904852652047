import { ReactElement, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Path, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    ActionItems,
    ActionItemTypes,
    ActionPlans,
    ChecklistQuestions,
    Common,
    Nodes,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    createNavigateToForSearch,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    StyledBorderedSection,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    associatedReferentialLinkColumnNames,
    nodesColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isQueryLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { AssociateDissociateReferentialLinkToActionItemDto } from "../../../domain/dtos/actions/associate-dissociate-referential-link-to-action-item-dto";
import { AssociateRaciParticipantDto } from "../../../domain/dtos/actions/associate-raci-participant-dto";
import { AssociatedActionItemRaciSetUserDto } from "../../../domain/dtos/actions/associated-action-item-raci-set-user-dto";
import { DissociateNodeToActionItemDto } from "../../../domain/dtos/actions/dissociate-node-to-action-item-dto";
import { emptySearchActionItemAssociationDto } from "../../../domain/dtos/actions/search-action-item-association-dto";
import { emptySearchActionItemNodeAssociationDto } from "../../../domain/dtos/actions/search-action-item-node-association-dto";
import { BasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import {
    createPaginationDto,
    defaultPaginationDto,
    PaginationDto,
} from "../../../domain/dtos/common/pagination-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { ActionInstanceStatus } from "../../../domain/enums/action-items/ActionInstanceStatus";
import { ActionItemImportance } from "../../../domain/enums/action-items/ActionItemImportance";
import { ActionItemNodeAssociationMode } from "../../../domain/enums/action-items/ActionItemNodeAssociationMode";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import {
    useDissociateActionItemNode,
    useDownloadActionItemFile,
    useDownloadQuestionSetInstanceAnswerFile,
    useRemoveActionItemFile,
} from "../../../domain/viewmodels/action-items/my-action-item-details-viewmodel";
import {
    useAssociateRaciParticipant,
    useDissociateActionItemReferentialLink,
    useFilterAssociatedRaciSetUsers,
    useFilterUsers,
    useGetActionItemDetails,
} from "../../../domain/viewmodels/actions/action-item-details-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { CloneButton, SbButton } from "../../atoms/SbButton";
import { CreateLink, EditLink, ViewLink } from "../../atoms/SbLink";
import { CreateLabelToDetailRows } from "../../molecules/CreateLabelToDetailRows";
import { SbAccordion } from "../../molecules/SbAccordion";
import SbModal from "../../molecules/SbModal";
import {
    CheckboxConfirmationRow,
    ConfirmationRow,
    LinkConfirmationRow,
    LinkConfirmationValue,
    TextConfirmationRow,
} from "../../organisms/ActionConfirmation";
import { DataTable } from "../../organisms/DataTable";
import { FileGallery } from "../../organisms/FileGallery";

const ActionItemDetailsContainer = (): JSX.Element => {
    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [associatedRaciSetUsersPaginationDto, setAssociatedRaciSetUsersPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);
    const [downloadActionItemInstanceFileId, setDownloadActionItemInstanceFileId] = useState<
        number | null
    >(null);
    const [
        downloadQuestionSetInstanceAnswerFileDetailsId,
        setDownloadQuestionSetInstanceAnswerFileDetailsId,
    ] = useState<number | null>(null);
    const [removeActionItemFileId, setRemoveActionItemFileId] = useState<number | null>(null);
    const [
        showDeleteActionItemFileConfirmationModal,
        setShowDeleteActionItemFileConfirmationModal,
    ] = useState(false);
    const [organisationalNodesPaginationDto, setOrganisationalNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [geographyNodesPaginationDto, setGeographyNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [taxonomyNodesPaginationDto, setTaxonomyNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [referentialLinksPaginationDto, setReferentialLinksPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const actionItemId = Number(useParams().actionItemId);

    const searchDto = {
        ...emptySearchActionItemAssociationDto(),
        actionItemId: actionItemId,
    };

    const organisationalNodesSearchDto = {
        ...emptySearchActionItemNodeAssociationDto(),
        actionItemId: actionItemId,
        actionItemNodeAssociationMode: ActionItemNodeAssociationMode.Organisational,
    };

    const geographyNodesSearchDto = {
        ...emptySearchActionItemNodeAssociationDto(),
        actionItemId: actionItemId,
        actionItemNodeAssociationMode: ActionItemNodeAssociationMode.Geography,
    };

    const taxonomyNodesSearchDto = {
        ...emptySearchActionItemNodeAssociationDto(),
        actionItemId: actionItemId,
        actionItemNodeAssociationMode: ActionItemNodeAssociationMode.Taxonomy,
    };

    const getActionItemDetails = useGetActionItemDetails(
        actionItemId,
        organisationalNodesSearchDto,
        organisationalNodesPaginationDto,
        geographyNodesSearchDto,
        geographyNodesPaginationDto,
        taxonomyNodesSearchDto,
        taxonomyNodesPaginationDto,
        searchDto,
        referentialLinksPaginationDto
    );

    const actionItemDetailsResponseData = getActionItemDetails[0].data;

    const getAdditionalFieldValues = getActionItemDetails[1].data;
    const getActionItemInstanceFilesResponseData = getActionItemDetails[2].data;
    const associatedOrganisationalNodesResponseData = getActionItemDetails[3].data;
    const associatedGeographyNodesResponseData = getActionItemDetails[4].data;
    const associatedTaxonomyNodesResponseData = getActionItemDetails[5].data;
    const associatedReferentialLinksResponseData = getActionItemDetails[6].data;

    const filterUsers = useFilterUsers(createPaginationDto(1, 1000, null, false), userSearchText);
    const getRaciSetUsers = useFilterAssociatedRaciSetUsers(
        actionItemId,
        associatedRaciSetUsersPaginationDto
    );

    const associatedRaciSetUsersResponseData = getRaciSetUsers.data;

    const filterUsersResponseData = filterUsers.data;

    const associateRaciParticipant = useAssociateRaciParticipant();
    const downloadActionItemFile = useDownloadActionItemFile(downloadActionItemInstanceFileId);
    const downloadQuestionSetInstanceAnswerFile = useDownloadQuestionSetInstanceAnswerFile(
        downloadQuestionSetInstanceAnswerFileDetailsId
    );
    const removeActionItemFile = useRemoveActionItemFile();
    const dissociateActionItemReferentialLink = useDissociateActionItemReferentialLink();
    const dissociateActionItemNode = useDissociateActionItemNode();

    useLoader(
        areQueriesLoading([
            ...getActionItemDetails,
            downloadActionItemFile,
            downloadQuestionSetInstanceAnswerFile,
            getRaciSetUsers,
        ]) ||
            areMutationsLoading([
                associateRaciParticipant,
                removeActionItemFile,
                dissociateActionItemReferentialLink,
                dissociateActionItemNode,
            ]),
        ActionItemDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        associateRaciParticipant.mutate(
            new AssociateRaciParticipantDto(
                Number(formData.get("raciUser") as string),
                actionItemId,
                Number(formData.get("raciRelationship") as string)
            ),
            {
                onSuccess: async () => {
                    toast.addToast(
                        createSuccessToastProps([
                            t("SuccessfullyAssignedRACIParticipantToActionItem"),
                        ])
                    );
                    queryClient.refetchQueries([
                        "filterAssociatedRaciSetUsers",
                        associatedRaciSetUsersPaginationDto,
                        actionItemId,
                    ]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const onDownloadActionItemFile = (fileId: number): void =>
        setDownloadActionItemInstanceFileId(fileId);

    const onDownloadQuestionSetInstanceAnswerFile = (fileDetailsId: number): void =>
        setDownloadQuestionSetInstanceAnswerFileDetailsId(fileDetailsId);

    const onDeleteFile = (fileId: number): void => {
        setShowDeleteActionItemFileConfirmationModal(true);
        setRemoveActionItemFileId(fileId);
    };

    const onRemoveFile = (): void => {
        removeActionItemFile.mutate(removeActionItemFileId!, {
            onSuccess: async () => {
                toast.addToast(createSuccessToastProps([t("EvidenceRemovedFromActionItem")]));
                queryClient.refetchQueries(["getActionItemInstanceFiles", actionItemId]);
            },
        });
    };

    const handleDissociateRaciParticipant = (dto: AssociatedActionItemRaciSetUserDto): void => {
        const { raciSetId, userId, raciRelationship } = dto;

        const params = [
            createNavigateSearchParameter("userId", userId.toString()),
            createNavigateSearchParameter("actionItemId", actionItemId.toString()),
            createNavigateSearchParameter("raciRelationship", raciRelationship.toString()),
        ];

        navigateSearch(
            `${getPath(DrawerTitles.ActionItems)}/${raciSetId}/dissociate-raci-participant`,
            params
        );
    };

    const refetchAssociatedNodes = (
        actionItemNodeAssociationMode: ActionItemNodeAssociationMode
    ): void => {
        switch (actionItemNodeAssociationMode) {
            case ActionItemNodeAssociationMode.Organisational:
                queryClient.refetchQueries([
                    "getAssociatedNodes",
                    organisationalNodesPaginationDto,
                    organisationalNodesSearchDto,
                ]);
                break;

            case ActionItemNodeAssociationMode.Geography:
                queryClient.refetchQueries([
                    "getAssociatedNodes",
                    geographyNodesPaginationDto,
                    geographyNodesSearchDto,
                ]);
                break;

            case ActionItemNodeAssociationMode.Taxonomy:
                queryClient.refetchQueries([
                    "getAssociatedNodes",
                    taxonomyNodesPaginationDto,
                    taxonomyNodesSearchDto,
                ]);
                break;

            default:
        }
    };

    const handleDissociateNode = (
        nodeId: number,
        actionItemNodeAssociationMode: ActionItemNodeAssociationMode
    ): void => {
        dissociateActionItemNode.mutate(new DissociateNodeToActionItemDto(actionItemId, nodeId), {
            onSuccess: async () => {
                toast.addToast(
                    createSuccessToastProps([t("DissociateNodeFromActionItemSuccessMessage")])
                );

                refetchAssociatedNodes(actionItemNodeAssociationMode);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleDissociateReferentialLink = (referentialLinkId: number): void => {
        let referentialLinkIds = [];
        referentialLinkIds.push(referentialLinkId);

        dissociateActionItemReferentialLink.mutate(
            new AssociateDissociateReferentialLinkToActionItemDto(actionItemId, referentialLinkIds),
            {
                onSuccess: async () => {
                    toast.addToast(
                        createSuccessToastProps([
                            t("DissociateReferentialLinkFromChecklistSuccessMessage", {
                                keyPrefix: ChecklistQuestions,
                            }),
                        ])
                    );
                    queryClient.refetchQueries(["getAssociatedReferentialLinks"]);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const buildAssociateOrganisationalNodeNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "nodeAssociationMode",
                ActionItemNodeAssociationMode.Organisational.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(DrawerTitles.ActionItems)}/${actionItemId}/associate-node`,
            params
        );
    };

    const buildAssociateGeographyNodeNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "nodeAssociationMode",
                ActionItemNodeAssociationMode.Geography.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(DrawerTitles.ActionItems)}/${actionItemId}/associate-node`,
            params
        );
    };

    const buildAssociateTaxonomyNodeNavigateTo = (): Partial<Path> => {
        const params = [
            createNavigateSearchParameter(
                "nodeAssociationMode",
                ActionItemNodeAssociationMode.Taxonomy.toString()
            ),
        ];

        return createNavigateToForSearch(
            `${getPath(DrawerTitles.ActionItems)}/${actionItemId}/associate-node`,
            params
        );
    };

    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {actionItemDetailsResponseData?.actionInstanceDetailsDto
                    .canEditActionItemInstance && buildEditLink()}
                {buildCreateLink()}
                {buildMyActionItemCloneButton()}
                {buildMyActionItemsContainerLink()}
            </>
        );
    };

    const buildEditLink = (): ReactElement<typeof Link> => (
        <EditLink navigateTo={`${getPath(DrawerTitles.ActionItems)}/${actionItemId}/edit`} />
    );

    const buildCreateLink = (): ReactElement<typeof Link> => (
        <CreateLink
            label={t("CreateNew", { keyPrefix: Common })}
            navigateTo={`${getPath(DrawerTitles.ActionItems)}/create`}
        />
    );

    const buildMyActionItemsContainerLink = (): ReactElement<typeof Link> => (
        <ViewLink label={t("MyActionItems")} navigateTo={`${getPath(DrawerTitles.ActionItems)}`} />
    );

    const buildMyActionItemCloneButton = (): ReactElement<typeof Link> => (
        <CloneButton
            onClick={() => navigate(`${getPath(DrawerTitles.ActionItems)}/${actionItemId}/create`)}
            label={`${t("Clone")}`}
        />
    );

    const renderAdditionalInformationDetail = (): TextConfirmationRow[] => {
        if (getAdditionalFieldValues) {
            return getAdditionalFieldValues!.map((x) => {
                return new TextConfirmationRow(
                    x.actionItemDynamicFieldDetailsDto.fieldName,
                    x.value || "-"
                );
            });
        }
        return [];
    };

    const renderExternalSystemInformation = (): ConfirmationRow<any>[] => {
        if (actionItemDetailsResponseData!.externalSystemName) {
            return [
                new TextConfirmationRow(
                    t("SourceSystemName"),
                    actionItemDetailsResponseData!.externalSystemName
                ),
                new TextConfirmationRow(
                    t("ReferenceRelationshipName"),
                    actionItemDetailsResponseData!.externalSystemReferenceRelationshipName
                ),
                new LinkConfirmationRow(t("RelationshipLinkID"), [
                    new LinkConfirmationValue(
                        actionItemDetailsResponseData!.externalSystemReferenceId,
                        actionItemDetailsResponseData!.externalSystemLinkUrl
                    ),
                ]),
            ];
        }
        return [];
    };

    const confirmationRows = (): ConfirmationRow<any>[] => [
        new TextConfirmationRow(
            t("Name", { keyPrefix: Common }),
            actionItemDetailsResponseData!.name
        ),
        new TextConfirmationRow(
            t("Description", { keyPrefix: Common }),
            actionItemDetailsResponseData!.description
        ),
        new TextConfirmationRow(
            t("ActionItemUrgency"),
            ActionItemImportance[actionItemDetailsResponseData!.actionItemImportance].toString()
        ),
        new TextConfirmationRow(
            t("ActionItemType", { keyPrefix: ActionItemTypes }),
            actionItemDetailsResponseData!.actionItemTypeDto.value
        ),
        new TextConfirmationRow(
            t("ActionItemSubType"),
            actionItemDetailsResponseData!.actionItemSubTypeDto.value
        ),
        new TextConfirmationRow(
            t("ActionInstanceStatus"),
            ActionInstanceStatus[
                actionItemDetailsResponseData!.actionInstanceDetailsDto.actionInstanceStatus
            ].toString()
        ),
        new CheckboxConfirmationRow(
            t("ExcludeFromReporting"),
            actionItemDetailsResponseData!.isPersonalActionItem
        ),
        new TextConfirmationRow(
            t("StartDate"),
            actionItemDetailsResponseData!.actionInstanceDetailsDto.formattedStartDateLocal
        ),
        new TextConfirmationRow(
            t("DueDate"),
            actionItemDetailsResponseData!.actionInstanceDetailsDto.formattedDueDateLocal
        ),
        new TextConfirmationRow(
            t("PlaceOnHoldsRemaining"),
            actionItemDetailsResponseData!.actionInstanceDetailsDto.placeOnHoldsRemaining.toString() ||
                "-"
        ),
        new TextConfirmationRow(
            t("RemedialAction"),
            actionItemDetailsResponseData!.requiredOutcome || "-"
        ),
        ...renderAdditionalInformationDetail(),
        new TextConfirmationRow(
            t("DateCreated", { keyPrefix: Common }),
            actionItemDetailsResponseData!.dateCreatedLocal
        ),
        new TextConfirmationRow(
            t("DateLastModified", { keyPrefix: Common }),
            actionItemDetailsResponseData!.dateModifiedLocal
        ),
        new TextConfirmationRow(
            t("CreatedByUserEmail", { keyPrefix: Users }),
            actionItemDetailsResponseData!.createdByUserName
        ),
        new TextConfirmationRow(
            t("ModifiedByUserEmail", { keyPrefix: Users }),
            actionItemDetailsResponseData!.modifiedByUserName
        ),
        new TextConfirmationRow(
            t("CreatedByUser", { keyPrefix: Users }),
            actionItemDetailsResponseData!.createdByUserFullName
        ),
        new TextConfirmationRow(
            t("LastModifiedBy", { keyPrefix: Users }),
            actionItemDetailsResponseData!.modifiedByUserFullName
        ),
        new TextConfirmationRow(
            t("ActionPlanName", { keyPrefix: Users }),
            actionItemDetailsResponseData!.actionPlanName
        ),
        ...renderExternalSystemInformation(),
    ];

    const renderActionItemDetail = (): JSX.Element => (
        <ContentContainer>
            {CreateLabelToDetailRows(confirmationRows(), 2, 10)}
            <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
        </ContentContainer>
    );

    const renderRaciUsers = (): JSX.Element => (
        <SbAccordion title={t("RaciParticipants")}>
            <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                <EndAlignedDiv>
                    <Col md={2}>
                        <SbSelect
                            name={"raciRelationship"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            styles={maxContentWidthSelectStyle}
                            defaultSelectedItem={createReactSelectDto(
                                RaciRelationship.Responsible,
                                RaciRelationship[RaciRelationship.Responsible]
                            )}
                            items={getEnumsForType(RaciRelationship).map((x) =>
                                createReactSelectDto(x, RaciRelationship[x])
                            )}
                            itemLabel={(option: ReactSelectDto<RaciRelationship>) =>
                                t(RaciRelationship[option.value])
                            }
                            itemValue={(option: ReactSelectDto<RaciRelationship>) =>
                                option.value.toString()
                            }
                        />
                    </Col>
                    <Col md={2}>
                        <SbSelect
                            name={"raciUser"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable
                            styles={maxContentWidthSelectStyle}
                            loading={isQueryLoading(filterUsers)}
                            items={filterUsersResponseData}
                            itemLabel={(option: BaseUserDto) =>
                                `${option.fullName} - ${option.emailAddress}`
                            }
                            itemValue={(option: BaseUserDto) => option.userId.toString()}
                            onSearchTextChanged={(text: string) => setUserSearchText(text)}
                        />
                    </Col>
                    <Col md={"auto"}>
                        <SbButton
                            label={t("Assign", { keyPrefix: Common })}
                            variant={"primary"}
                            type={"submit"}
                        />
                    </Col>
                </EndAlignedDiv>
                <DataTable
                    rows={associatedRaciSetUsersResponseData!.rows}
                    deleteItem={handleDissociateRaciParticipant}
                    totalItems={associatedRaciSetUsersResponseData!.recordCount}
                    paginationDto={associatedRaciSetUsersPaginationDto}
                    setPaginationDto={setAssociatedRaciSetUsersPaginationDto}
                />
            </StyledHiddenOverflowForm>
        </SbAccordion>
    );

    const renderAttachedEvidence = (): JSX.Element => (
        <SbAccordion title={t("AttachedEvidence")}>
            <StyledBorderedSection>
                <FileGallery
                    files={getActionItemInstanceFilesResponseData!}
                    filesLabel={t("ActionItemFiles") as string}
                    onDownloadFile={onDownloadActionItemFile}
                    fileDownloadData={downloadActionItemFile.data!}
                    onRemoveFile={onDeleteFile}
                />
            </StyledBorderedSection>

            <StyledBorderedSection>
                <FileGallery
                    files={actionItemDetailsResponseData!.questionSetInstanceAnswerFileDtos}
                    filesLabel={t("QuestionSetInstanceAnswerFiles") as string}
                    onDownloadFile={onDownloadQuestionSetInstanceAnswerFile}
                    fileDownloadData={downloadQuestionSetInstanceAnswerFile.data!}
                />
            </StyledBorderedSection>
        </SbAccordion>
    );

    const renderAssociatedOrganisationalNodes = (): JSX.Element => (
        <SbAccordion title={t("Organisational", { keyPrefix: Common })} isCollapsedOnLoad>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssociateOrganisationalNode")}
                    navigateTo={buildAssociateOrganisationalNodeNavigateTo()}
                />
            </EndAlignedDiv>
            <DataTable
                keyPrefix={Nodes}
                noResultsMessage="No organisational nodes associated"
                columns={nodesColumnNames}
                rows={associatedOrganisationalNodesResponseData!.rows}
                deleteItem={(nodeId: number) =>
                    handleDissociateNode(nodeId, ActionItemNodeAssociationMode.Organisational)
                }
                totalItems={associatedOrganisationalNodesResponseData!.recordCount}
                paginationDto={organisationalNodesPaginationDto}
                setPaginationDto={setOrganisationalNodesPaginationDto}
            />
        </SbAccordion>
    );

    const renderAssociatedGeographyNodes = (): JSX.Element => (
        <SbAccordion title={t("Geography", { keyPrefix: Common })} isCollapsedOnLoad>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssociateGeographyNode")}
                    navigateTo={buildAssociateGeographyNodeNavigateTo()}
                />
            </EndAlignedDiv>
            <DataTable
                keyPrefix={Nodes}
                noResultsMessage="No geography nodes associated"
                columns={nodesColumnNames}
                rows={associatedGeographyNodesResponseData!.rows}
                deleteItem={(nodeId: number) =>
                    handleDissociateNode(nodeId, ActionItemNodeAssociationMode.Geography)
                }
                totalItems={associatedGeographyNodesResponseData!.recordCount}
                paginationDto={geographyNodesPaginationDto}
                setPaginationDto={setGeographyNodesPaginationDto}
            />
        </SbAccordion>
    );

    const renderAssociatedTaxonomyNodes = (): JSX.Element => (
        <SbAccordion title={t("Taxonomies", { keyPrefix: Common })} isCollapsedOnLoad>
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssignTaxonomyNode")}
                    navigateTo={buildAssociateTaxonomyNodeNavigateTo()}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage="No taxonomy nodes associated"
                columns={nodesColumnNames}
                rows={associatedTaxonomyNodesResponseData!.rows}
                deleteItem={(nodeId: number) =>
                    handleDissociateNode(nodeId, ActionItemNodeAssociationMode.Taxonomy)
                }
                totalItems={associatedTaxonomyNodesResponseData!.recordCount}
                paginationDto={taxonomyNodesPaginationDto}
                setPaginationDto={setTaxonomyNodesPaginationDto}
            />
        </SbAccordion>
    );

    const renderAssociatedReferentialLinks = (): JSX.Element => (
        <SbAccordion
            title={t("ReferentialLinks", { keyPrefix: ChecklistQuestions })}
            isCollapsedOnLoad
        >
            <EndAlignedDiv>
                <CreateLink
                    label={t("AssociateReferentialLink", { keyPrefix: ChecklistQuestions })}
                    navigateTo={`${getPath(
                        DrawerTitles.ActionItems
                    )}/${actionItemId}/associate-referential-links`}
                />
            </EndAlignedDiv>
            <DataTable
                noResultsMessage={`${t("NoReferentialLinksFound", {
                    keyPrefix: ChecklistQuestions,
                })}`}
                columns={associatedReferentialLinkColumnNames}
                rows={associatedReferentialLinksResponseData!.rows}
                deleteItem={handleDissociateReferentialLink}
                totalItems={associatedReferentialLinksResponseData!.recordCount}
                paginationDto={referentialLinksPaginationDto}
                setPaginationDto={setReferentialLinksPaginationDto}
            />
        </SbAccordion>
    );

    return (
        <>
            <PageHeading>{t("ActionItemDetails")}</PageHeading>
            <SectionVerticalSpace />

            <SbModal
                title={t("RemoveActionItemFile")}
                body={
                    t("RemoveActionPlanFileConfirmationText", { keyPrefix: ActionPlans }) as string
                }
                primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                onPrimaryButtonClicked={onRemoveFile}
                isVisible={showDeleteActionItemFileConfirmationModal}
                updateIsVisible={setShowDeleteActionItemFileConfirmationModal}
            />

            {areQueriesSuccessful([...getActionItemDetails, getRaciSetUsers]) && (
                <>
                    {renderActionItemDetail()}
                    <SectionVerticalSpace />

                    {renderRaciUsers()}
                    <SectionVerticalSpace />

                    {renderAssociatedOrganisationalNodes()}
                    <SectionVerticalSpace />

                    {renderAssociatedGeographyNodes()}
                    <SectionVerticalSpace />

                    {renderAssociatedTaxonomyNodes()}
                    <SectionVerticalSpace />

                    {renderAssociatedReferentialLinks()}
                    <SectionVerticalSpace />

                    {renderAttachedEvidence()}
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );
};

export default ActionItemDetailsContainer;
