import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import {
    ActionItems,
    ChecklistAnswers,
    Common,
} from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledColorDiv,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { useGetActionItemSummary } from "../../../domain/viewmodels/action-items/action-item-summary-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import SbLabelText from "../../atoms/SbLabelText";
import { ActionItemSummaryCardGallery } from "../../organisms/ActionItemSummaryCardGallery";

const MyActionSummaryContainer: React.FC = () => {
    const menu = useMenu();
    const themeContext = useContext(ThemeContext);

    const getActionItemSummary = useGetActionItemSummary();

    const { t } = useTranslation("translation");

    useLoader(isQueryLoading(getActionItemSummary), MyActionSummaryContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.MyActionSummary);
    }, []);

    const renderActionItemSummary = (): JSX.Element => {
        if (getActionItemSummary.data!.length < 1) {
            return (
                <SbAlert
                    variant={"primary"}
                    text={t("NoAssignedData", { keyPrefix: ChecklistAnswers })}
                />
            );
        }

        return (
            <>
                <SectionVerticalSpace />

                <EndAlignedDiv>
                    {/* // TODO: It looks like the legend is tied to the theme - Due being blue (primary), On Hold being pink (secondary variant), for examples - and therefore we should probably be referencing the theme colours here so that it scales and is appropriate for each differently themed tenant */}
                    <StyledColorDiv $color={themeContext!.palette.actionInstanceStatus.onhold} />
                    <SbLabelText label={t("OnHold", { keyPrefix: ActionItems })} />
                    <StyledColorDiv $color={themeContext!.palette.actionInstanceStatus.overdue} />
                    <SbLabelText label={t("Overdue", { keyPrefix: ActionItems })} />
                    <StyledColorDiv $color={themeContext!.palette.actionInstanceStatus.due} />
                    <SbLabelText label={t("Due", { keyPrefix: Common })} />
                    <StyledColorDiv $color={themeContext!.palette.actionInstanceStatus.open} />
                    <SbLabelText label={t("Open", { keyPrefix: ActionItems })} />
                    <StyledColorDiv $color={themeContext!.palette.actionInstanceStatus.pending} />
                    <SbLabelText label={t("Pending", { keyPrefix: ActionItems })} />
                </EndAlignedDiv>
                <SectionVerticalSpace />

                <ActionItemSummaryCardGallery actionItemSummaryCards={getActionItemSummary.data} />
            </>
        );
    };

    return (
        <>
            <PageHeading>{t("MyActionItemsSummary", { keyPrefix: ActionItems })}</PageHeading>

            {isQuerySuccessful(getActionItemSummary) && renderActionItemSummary()}
        </>
    );
};

export default MyActionSummaryContainer;
