export interface FilterUserAssignedActionItemsDto {
    actionItemId: string | null;
    name: string | null;
    actionInstanceStatusId: number | null;
}

export const createFilterUserAssignedActionItemsDto = (
    actionItemId: string | null,
    name: string | null,
    actionInstanceStatusId: number | null
): FilterUserAssignedActionItemsDto => ({
    actionItemId: actionItemId,
    name: name,
    actionInstanceStatusId: actionInstanceStatusId,
});

export const defaultFilterUserAssignedActionItemsDto = (): FilterUserAssignedActionItemsDto => {
    return createFilterUserAssignedActionItemsDto(null, null, null);
};
