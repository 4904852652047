import { ActionPlanStatusResponse } from "../../responses/actions/action-plan-status-response";
import { Response } from "../../responses/common/response-response";

export interface ActionPlanStatusDto {
    actionPlanStatusId: number;
    name: string;
}

export const createActionPlanStatusDto = (
    actionPlanStatusId: number,
    name: string
): ActionPlanStatusDto => ({
    actionPlanStatusId: actionPlanStatusId,
    name: name,
});

export const createActionPlanStatusDtoFromResponse = (
    response: ActionPlanStatusResponse
): ActionPlanStatusDto => ({
    ...response,
});

export const toActionPlanStatusDtos = (
    response: Response<ActionPlanStatusResponse[]>
): ActionPlanStatusDto[] => response.data?.map((x) => createActionPlanStatusDtoFromResponse(x));
