import { HTTPError } from "ky";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import {
    AssociateExistingQuestionToQuestionSetEvent,
    AssociateNewQuestionToQuestionSetEvent,
    AssociateRefLinkToQuestionSetEvent,
    CloneQuestionSet,
    DissociateQuestionSetQuestion,
    EditQuestionSetQuestionScheduleEvent,
} from "../../../core/constants/application-insights-events";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import {
    ActionItems,
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    disabledSelectStyles,
    EndAlignedDiv,
    LargeVerticalSpace,
    maxContentWidthSelectStyle,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
    StyledFormLabel,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { TableRow } from "../../../core/utilities/customTypes";
import {
    associatedReferentialLinkTypeColumnNames,
    existingQuestionsColumnNames,
    nestedQuestionColumnNames,
    questionSetQuestionsColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    columnMinutesValue,
    columnPeriodValue,
    columnStringValue,
} from "../../../core/utilities/questions-column-converter";
import {
    FrequencyOptions,
    NonWorkingDayRolloverOptions,
    PeriodTypeOptions,
} from "../../../core/utilities/QuestionSetValues";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { AggregationTypeDto } from "../../../domain/dtos/aggregation-type/aggregation-type-dto";
import { AnswerThresholdOperatorsDto } from "../../../domain/dtos/answer-threshold-operators/answer-threshold-operators-dto";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import {
    AnswerTypeSearchDto,
    createAnswerTypeSearchDto,
    createSearchDto,
    defaultAnswerTypeSearchDto,
    defaultSearchDto,
    SearchDto,
} from "../../../domain/dtos/common/search-dto";
import { QuestionCategoryDto } from "../../../domain/dtos/question-categories/question-category-dto";
import { QuestionImportanceDto } from "../../../domain/dtos/question-importance/question-importance-dto";
import { createAssociateDissociateQuestionToQuestionSetDto } from "../../../domain/dtos/question-sets/associate-dissociate-question-to-question-set-dto";
import {
    createDissociateQuestionSetQuestionDetailsDto,
    DissociateQuestionSetQuestionDetailsDto,
} from "../../../domain/dtos/question-sets/dissociate-question-set-question-details-dto";
import { createDissociateQuestionSetQuestionDto } from "../../../domain/dtos/question-sets/dissociate-question-set-question-dto";
import {
    createEditQuestionScheduleDto,
    EditQuestionScheduleDto,
    getDefaultEditQuestionScheduleDto,
} from "../../../domain/dtos/question-sets/edit-question-schedule-dto";
import {
    createExistingQuestionSetQuestionDto,
    ExistingQuestionSetQuestionDto,
} from "../../../domain/dtos/question-sets/existing-question-set-question-dto";
import {
    defaultFilterQuestionSetDto,
    FilterQuestionSetDto,
} from "../../../domain/dtos/question-sets/filter-question-set-dto";
import { QuestionTypeDto } from "../../../domain/dtos/question-type/question-type-dto";
import {
    defaultFilterQuestionsDto,
    FilterQuestionsDto,
} from "../../../domain/dtos/questions/filter-questions-dto";
import {
    defaultFilterReferentialLinkTypesDto,
    FilterReferentialLinkTypeDto,
} from "../../../domain/dtos/referential-links/filter-referential-link-type-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import { PeriodType } from "../../../domain/enums/PeriodType";
import { ARMonitoringDefaults } from "../../../domain/enums/questions/ar-monitoring-defaults";
import { default as QuestionImportance } from "../../../domain/enums/questions/question-importance";
import { FrequencyType } from "../../../domain/enums/questions/question-sets/FrequencyType";
import { QuestionTypes } from "../../../domain/enums/questions/question-types";
import { hasRoleTypeInGroup, QuestionSetRoleGroup } from "../../../domain/enums/Roles";
import { Response } from "../../../domain/responses/common/response-response";
import { QuestionSetResponse } from "../../../domain/responses/question-sets/question-set-response";
import {
    useAssociateQuestionSetReferentialLink,
    useGetAllAssociateReferentialLinks,
} from "../../../domain/viewmodels/common/associate-referential-link-viewmodel";
import {
    AssociateNewQuestionParams,
    useAssociateExistingQuestionToQuestionSet,
    useAssociateNewQuestionToQuestionSet,
} from "../../../domain/viewmodels/question-set/associate-question-viewmodel";
import { useCloneQuestionSet } from "../../../domain/viewmodels/question-set/clone-question-set-viewmodel";
import { useDissociateQuestionSetQuestion } from "../../../domain/viewmodels/question-set/dissociate-question-set-question-viewmodel";
import { useEditQuestionScheduleRequest } from "../../../domain/viewmodels/question-set/edit-question-schedule-viewmodel";
import {
    useFilterQuestions,
    useGetQuestionDropdownValues,
    useGetQuestionSetDetails,
    useLookupQuestionCategories,
} from "../../../domain/viewmodels/question-set/question-set-details-viewmodel";
import {
    useARMonitoringLookupAnswerTypes,
    useARMonitoringLookupQuestionCategories,
    useLookupAnswerTypes,
} from "../../../domain/viewmodels/questions/view-question-dropdown-values-viewmodel";
import SbNumberBox from "../../atoms/input/SbNumberBox";
import { SbSelect } from "../../atoms/input/SbSelect";
import { SbAlert } from "../../atoms/SbAlert";
import {
    AssociateButton,
    CancelButton,
    CloneButton,
    DissociateButton,
    SaveButton,
    SbButton,
} from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import { CreateLink, EditLink, OrderLink, SbLink, ViewLink } from "../../atoms/SbLink";
import { translateText } from "../../helpers/translate";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import { SbAccordion } from "../../molecules/SbAccordion";
import SbModal from "../../molecules/SbModal";
import { ComponentPanel, TextTitledPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";
import { QuestionFilter } from "../../organisms/filters/QuestionFilter";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";

const StyledCheckBoxLabel = styled.div`
    padding-top: ${(props) => props.theme.padding.md};
`;

const StyledDivFormField = styled.div`
    display: inline-block;
    padding-top: ${(props) => props.theme.padding.md};
    text-align: right;
    vertical-align: middle;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: ${(props) => props.theme.padding.md};
`;

const StyledSelectField = styled.div`
    width: 20rem;
`;

const StyledInput = styled.input`
    width: ${(props) => props.theme.dimensions.width.xxxl};
    height: ${(props) => props.theme.dimensions.height.xl};
    background-color: ${(props) => props.theme.palette.purewhite};
    background-image: none;
    border: ${(props) => props.theme.padding.xxs} solid ${(props) => props.theme.palette.grey};
    border-radius: 0.5rem;
    color: inherit;
    transition: border-color 0.15s;
    font-size: ${(props) => props.theme.font.md};
    text-align: center;
`;

const numberOfMinutesInAnHour = 60;
const numberOfMinutesInADay = 1440;
const numberOfMillisecondsInAMinute = 60000;
const arbitraryDateValue = "01 Jan 0000";

const viewableTimePeriodOptions: string[] = [
    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!.label,
    PeriodTypeOptions.find((x) => x.value === PeriodType.Days)!.label,
];

const questionTypesArray = getEnumsForType(QuestionTypes).map((x) =>
    createReactSelectDto(x, QuestionTypes[x])
);

interface SearchParams {
    questionId: number | null;
    questionSetName: string | null;
    questionText: string | null;
    questionTypeId: number | null;
    questionCategoryId: number | null;
    answerTypeId: number | null;
}

interface ReferentialLinkTypeSearchParams {
    referentialLinkTypeName: string | null;
    referentialLinkValue: string | null;
}

const createSearchParams = (
    questionId: number | null,
    questionSetName: string | null,
    questionText: string | null,
    questionTypeId: number | null,
    questionCategoryId: number | null,
    answerTypeId: number | null
): SearchParams => ({
    questionId: questionId,
    questionSetName: questionSetName,
    questionText: questionText,
    questionTypeId: questionTypeId,
    questionCategoryId: questionCategoryId,
    answerTypeId: answerTypeId,
});

const createReferentialLinkTypeSearchParamsSearchParams = (
    referentialLinkTypeName: string | null,
    referentialLinkValue: string | null
): ReferentialLinkTypeSearchParams => ({
    referentialLinkTypeName: referentialLinkTypeName,
    referentialLinkValue: referentialLinkValue,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null, null);
const defaultReferentialLinkTypeSearchParamsSearchParams: ReferentialLinkTypeSearchParams =
    createReferentialLinkTypeSearchParamsSearchParams(null, null);

const ViewQuestionSetContainer: React.FC = () => {
    const questionSetId = Number(useParams().questionSetId);

    const [searchDto, setSearchDto] = useState<SearchDto>(defaultSearchDto);
    const [questionSetQuestionsPaginationDto, setQuestionSetQuestionsPaginationDto] =
        useState<FilterQuestionSetDto>(defaultFilterQuestionSetDto);
    const [questionScheduleChanges, setQuestionScheduleChanges] = useState<EditQuestionScheduleDto>(
        createEditQuestionScheduleDto(false, questionSetId, [], [], [], [], [], [], [], [], [], [])
    );
    const [referentialLinksPaginationDto, setReferentialLinksPaginationDto] =
        useState<FilterQuestionSetDto>(defaultFilterQuestionSetDto);
    const [rows, setRows] = useState<TableRow<number>[]>([]);
    const [existingQuestionsRows, setExistingQuestionsRows] = useState<
        TableRow<number>[] | undefined
    >([]);
    const [existingQuestionsToAssociate, setExistingQuestionsToAssociate] = useState<
        ExistingQuestionSetQuestionDto[]
    >([]);
    const [questionSetQuestionsToDissociate, setQuestionSetQuestionsToDissociate] =
        useState<DissociateQuestionSetQuestionDetailsDto>(
            createDissociateQuestionSetQuestionDetailsDto(0, [])
        );
    const [questionTypeId, setQuestionTypeId] = useState<number>();
    const [questionCategoryId, setQuestionCategoryId] = useState<number>();
    const [questionImportanceId, setQuestionImportanceId] = useState<number>();
    const [answerTypeId, setAnswerTypeId] = useState<number>();
    const [aggregationTypeId, setAggregationTypeId] = useState<number>();
    const [redAnswerThresholdOperatorId, setRedAnswerThresholdOperatorId] = useState<number>();
    const [amberAnswerThresholdOperatorId, setAmberAnswerThresholdOperatorId] = useState<number>();
    const [desirableAnswer, setDesirableAnswer] = useState<string | null>();
    const [undesirableAnswer, setUndesirableAnswer] = useState<string | null>();
    const [neutralAnswer, setNeutralAnswer] = useState<string | null>();
    const [answerTypeName, setAnswerTypeName] = useState("");
    const [isAggregate, setIsAggregate] = useState(false);
    const [questionWeightings, setQuestionWeightings] = useState(false);
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [questionsPaginationDto, setQuestionsPaginationDto] =
        useState<FilterQuestionsDto>(defaultFilterQuestionsDto);
    const [
        showDissociateQuestionSetQuestionsConfirmationModal,
        setShowDissociateQuestionSetQuestionsConfirmationModal,
    ] = useState(false);
    const [filterReferentialLinkDto, setFilterReferentialLinkDto] =
        useState<FilterReferentialLinkTypeDto>(defaultFilterReferentialLinkTypesDto);
    const [arMonitoring, setARMonitoring] = useState(false);
    const [answerTypeSearchDto, setAnswerTypeSearchDto] = useState<AnswerTypeSearchDto>(
        defaultAnswerTypeSearchDto
    );

    const getAllAssociateReferentialLinks =
        useGetAllAssociateReferentialLinks(filterReferentialLinkDto);
    const associateNewQuestionFormRef = useRef<HTMLFormElement>(null);

    const menu = useMenu();
    const toast = useToast();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const editedQuestionSchedule = useEditQuestionScheduleRequest();
    const getQuestions = useFilterQuestions(questionsPaginationDto);
    const getQuestionSetDetails = useGetQuestionSetDetails(
        questionSetId,
        questionSetQuestionsPaginationDto,
        referentialLinksPaginationDto
    );
    const clonedQuestionSet = useCloneQuestionSet();
    const getQuestionDropdownValues = useGetQuestionDropdownValues();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const associateExistingQuestion = useAssociateExistingQuestionToQuestionSet();
    const associateNewQuestion = useAssociateNewQuestionToQuestionSet();
    const navigateSearch = useNavigateSearch();

    const lookupQuestionCategories = useLookupQuestionCategories(searchDto);
    const lookupQuestionCategoriesData = lookupQuestionCategories.data!;
    const lookupAnswerTypes = useLookupAnswerTypes(answerTypeSearchDto);
    const lookupAnswerTypesData = lookupAnswerTypes.data!;

    const arMonitoringQuestionCategory = useARMonitoringLookupQuestionCategories(
        createSearchDto(ARMonitoringDefaults.ARMonitoring, null)
    );
    const arMonitoringQuestioCategoryData = arMonitoringQuestionCategory.data!;

    const arMonitoringAnswerTypes = useARMonitoringLookupAnswerTypes(
        createAnswerTypeSearchDto(
            ARMonitoringDefaults.MonitoringAssessment,
            QuestionTypes.Control,
            null
        )
    );

    const dissociateQuestionSetQuestion = useDissociateQuestionSetQuestion();

    const arMonitoringAnswerTypesData = arMonitoringAnswerTypes.data!;

    const questionSetDetails = getQuestionSetDetails[0];
    const questionSetSchedule = questionSetDetails.data?.questionSetDefaultScheduleDto;
    const questionSetQuestions = getQuestionSetDetails[1];
    const getAssociatedReferentialLinkTypes = getQuestionSetDetails[2].data;

    const questionsData = getQuestions.data;

    const questionTypes = getQuestionDropdownValues[0].data!;
    const questionImportance = getQuestionDropdownValues[1].data!;
    const aggregationTypes = getQuestionDropdownValues[2].data!;
    const answerThresholdOperators = getQuestionDropdownValues[3].data!;

    const associateQuestionSetReferentialLink = useAssociateQuestionSetReferentialLink();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(
        areQueriesLoading([
            ...getQuestionSetDetails,
            ...getQuestionDropdownValues,
            ...getAllAssociateReferentialLinks,
            getQuestions,
        ]) ||
            areMutationsLoading([
                clonedQuestionSet,
                editedQuestionSchedule,
                associateExistingQuestion,
                associateNewQuestion,
                associateQuestionSetReferentialLink,
                dissociateQuestionSetQuestion,
            ]),
        ViewQuestionSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionSets);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useEffect(() => {
        if (
            questionSetQuestions.fetchStatus === "idle" &&
            questionSetQuestions.status === "success"
        ) {
            setRows(questionSetQuestions.data!.rows);
            const updatedQuestionScheduleChanges = getDefaultEditQuestionScheduleDto(questionSetId);
            setQuestionScheduleChanges(updatedQuestionScheduleChanges);
        }

        if (getQuestions.fetchStatus === "idle" && getQuestions.status === "success") {
            setExistingQuestionsRows(questionsData?.rows);
            setExistingQuestionsToAssociate([]);
            setQuestionSetQuestionsToDissociate({ questionIds: [], questionSetId: 0 });
        }
    }, [
        questionSetQuestions.fetchStatus,
        questionSetQuestions.status,
        getQuestions.fetchStatus,
        getQuestions.status,
    ]);

    useEffect(() => {
        if (arMonitoring) {
            const arMonitoringQuestionType = questionTypes.filter(
                (x) => x.questionTypeId === QuestionTypes.Control
            )[0];

            onQuestionTypeSelected(arMonitoringQuestionType);

            const arMonitoringQuestionCategory = arMonitoringQuestioCategoryData.filter(
                (x) => x.name.trim() === ARMonitoringDefaults.ARMonitoring
            )[0];

            onQuestionCategorySelected(arMonitoringQuestionCategory);

            const arMonitoringQuestionImportance = questionImportance.filter(
                (x) => x.questionImportanceId === QuestionImportance.High
            )[0];

            onQuestionImportanceSelected(arMonitoringQuestionImportance);

            const arMonitoringAnswerType = arMonitoringAnswerTypesData.filter(
                (x) => x.name.trim() === ARMonitoringDefaults.MonitoringAssessment
            )[0];

            onAnswerTypeSelected(arMonitoringAnswerType);
        } else {
            onQuestionTypeSelected(null);
            onQuestionCategorySelected(null);
            onQuestionImportanceSelected(null);
            onAnswerTypeSelected(null);
        }
    }, [arMonitoring]);

    const handleDissociateReferentialLink = (referentialLinkTypeId: number): void => {
        navigate(
            `${getPath(
                AccordionTitles.QuestionSets
            )}/${questionSetId}/dissociate-referential-link/${referentialLinkTypeId}`
        );
    };

    const cloneQuestionSet = (): void => {
        clonedQuestionSet.mutate(
            {
                questionSetId: questionSetId,
            },
            {
                onSuccess: async (response: Response<QuestionSetResponse | null>) => {
                    trackAppInsightsEvent(auth.email, window.location.href, CloneQuestionSet);
                    toast.addToast(createSuccessToastProps([t("QuestionSetCloneSuccess")]));
                    if (response.errors.length > 0) {
                        toast.addToast(createErrorToastProps(response.errors));
                    }

                    navigate(
                        `${getPath(AccordionTitles.QuestionSets)}/${response.data?.questionSetId}`
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        CloneQuestionSet,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const associateExistingQuestionToQuestionSet = (): void => {
        associateExistingQuestion.mutate(
            createAssociateDissociateQuestionToQuestionSetDto(existingQuestionsToAssociate),
            {
                onSuccess: async (_: Response<boolean>) => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateExistingQuestionToQuestionSetEvent
                    );
                    toast.addToast(
                        createSuccessToastProps([t("AssociateQuestionToQuestionSetSuccessMessage")])
                    );

                    setQuestionsPaginationDto({
                        ...questionsPaginationDto,
                        pageNumber: 1,
                        ...searchParams,
                    });

                    setExistingQuestionsToAssociate([]);

                    await getQuestions.refetch();
                    await questionSetDetails.refetch();
                    await questionSetQuestions.refetch();
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateExistingQuestionToQuestionSetEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const editQuestionSchedule = (): void => {
        editedQuestionSchedule.mutate(questionScheduleChanges, {
            onSuccess: async (_: Response<boolean>) => {
                trackAppInsightsEvent(
                    auth.email,
                    window.location.href,
                    EditQuestionSetQuestionScheduleEvent
                );

                toast.addToast(createSuccessToastProps([t("QuestionScheduleUpdateSuccess")]));
                questionSetDetails.refetch();
                questionSetQuestions.refetch();
                getQuestions.refetch();
                // TODO: Reload the page on update and cancel - there is a current bug that doesn't allow this
            },
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    EditQuestionSetQuestionScheduleEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const deletedAndDisassociateQuestion = (): void => {
        dissociateQuestionSetQuestion.mutate(
            createDissociateQuestionSetQuestionDto(questionSetQuestionsToDissociate),
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        DissociateQuestionSetQuestion
                    );
                    toast.addToast(
                        createSuccessToastProps([
                            t("DissociateQuestionToQuestionSetSuccessMessage"),
                        ])
                    );

                    setQuestionsPaginationDto({
                        ...questionsPaginationDto,
                        pageNumber: 1,
                        ...searchParams,
                    });

                    await getQuestions.refetch();
                    await questionSetDetails.refetch();
                    await questionSetQuestions.refetch();
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        DissociateQuestionSetQuestion,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );

        setQuestionSetQuestionsToDissociate({ questionIds: [], questionSetId: 0 });
    };

    const createAndAssociateQuestion = (event: React.FormEvent<HTMLFormElement>): void => {
        const formData = new FormData(event.currentTarget);
        event.preventDefault();

        if (!questionCategoryId || !questionImportanceId || !answerTypeId || !questionTypeId) {
            toast.addToast(
                createErrorToastProps([t("PleaseCompleteAllRequiredFields", { keyPrefix: Common })])
            );
            return;
        }

        associateNewQuestion.mutate(
            new AssociateNewQuestionParams(
                questionSetId,
                questionTypeId!,
                answerTypeId!,
                answerTypeName,
                questionCategoryId!,
                questionImportanceId!,
                aggregationTypeId!,
                redAnswerThresholdOperatorId!,
                amberAnswerThresholdOperatorId!,
                isAggregate,
                questionSetDetails.data!.questionSetDefaultScheduleDto.baseStartTimeOfDay,
                arMonitoring,
                formData
            ),
            {
                onSuccess: async (_: Response<boolean>) => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateNewQuestionToQuestionSetEvent
                    );
                    toast.addToast(
                        createSuccessToastProps([t("QuestionSetQuestionAssociationSuccessMessage")])
                    );

                    resetAssociateNewQuestionForm();

                    await questionSetDetails.refetch();
                    await questionSetQuestions.refetch();
                    await getQuestions.refetch();
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateNewQuestionToQuestionSetEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const navigateToEditNestedQuestionTrigger = (nestedQuestionId: number): void => {
        const params = [
            createNavigateSearchParameter("nestedQuestionId", nestedQuestionId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Questions)}/update-nested-question-trigger-selection`,
            params
        );
    };

    const handleQuestionWeightingsChange = (_: string, checked: boolean): void => {
        setQuestionWeightings(checked);
    };

    const navigateToNestedQuestion = (
        tableRows: TableRow<number>[] | undefined,
        nestedQuestionId: number
    ): void => {
        let nestedRow: TableRow<number> | undefined;

        tableRows?.find(
            (x) => (nestedRow = x.nestedRows?.find((n) => n.metadata === nestedQuestionId))
        );

        const questionId = nestedRow?.columns.find((x) => x.metadata === "ID")?.value;
        if (nestedRow && questionId) {
            navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`);
        }
    };

    const handleSetIntervalDefaults = (): void => {
        if (!questionScheduleChanges.setIntervalDefaults) {
            questionSetQuestions.data?.rows.forEach((row) =>
                handleUpdatesToQuestionSetQuestions(row)
            );
        }

        const updatedQuestionScheduleChanges = questionScheduleChanges;

        updatedQuestionScheduleChanges.setIntervalDefaults =
            !updatedQuestionScheduleChanges.setIntervalDefaults;
        setQuestionScheduleChanges(updatedQuestionScheduleChanges);
    };

    const resetAssociateNewQuestionForm = (): void => {
        setAggregationTypeId(undefined);
        setAmberAnswerThresholdOperatorId(undefined);
        setDesirableAnswer(undefined);
        setIsAggregate(false);
        setQuestionWeightings(false);
        setNeutralAnswer(undefined);
        setUndesirableAnswer(undefined);
        setQuestionCategoryId(undefined);
        setQuestionImportanceId(undefined);
        setQuestionTypeId(undefined);
        setAnswerTypeId(undefined);
        setRedAnswerThresholdOperatorId(undefined);
        setAmberAnswerThresholdOperatorId(undefined);
        setAnswerTypeName("");
        setARMonitoring(false);

        associateNewQuestionFormRef.current ? associateNewQuestionFormRef.current.reset() : null;
    };

    const calculateNumberOfMinutesBetweenTimes = (
        timeOneStringValue: string | undefined,
        timeTwoStringValue: string | undefined
    ): number => {
        if (!timeOneStringValue || !timeTwoStringValue) {
            return 0;
        }

        const timeOne = new Date(`${arbitraryDateValue} ${timeOneStringValue}`);
        const timeTwo = new Date(`${arbitraryDateValue} ${timeTwoStringValue}`);

        return Math.abs(
            Math.round((timeTwo.getTime() - timeOne.getTime()) / numberOfMillisecondsInAMinute)
        );
    };

    const handleUpdatesToQuestionSetQuestions = (row: TableRow<number>): void => {
        const questionId = columnStringValue("ID", row);
        const requiredByTime = columnStringValue("DueByTime", row);
        const openIntervalBeforeDueTime = columnStringValue("OpenBeforeDue", row);
        const openPeriodBeforeDueTime = columnPeriodValue("OpenBeforeDue", row);
        const escalationL1Interval = columnStringValue("EL1", row);
        const escalationL1Period = columnPeriodValue("EL1", row);
        const escalationL2Interval = columnStringValue("EL2", row);
        const escalationL2Period = columnPeriodValue("EL2", row);
        const escalationL3Interval = columnStringValue("EL3", row);
        const escalationL3Period = columnPeriodValue("EL3", row);

        const isValuesValid =
            questionId &&
            requiredByTime &&
            openIntervalBeforeDueTime &&
            openPeriodBeforeDueTime !== undefined &&
            escalationL1Interval &&
            escalationL1Period !== undefined;

        if (
            isValuesValid &&
            questionScheduleChanges.questionIds.find((x) => x === Number(questionId))
        ) {
            const index = questionScheduleChanges.questionIds.indexOf(Number(questionId));

            updateExistingQuestionSetQuestionChanges(
                index,
                questionId,
                requiredByTime,
                openIntervalBeforeDueTime,
                openPeriodBeforeDueTime,
                escalationL1Interval,
                escalationL1Period,
                escalationL2Interval,
                escalationL2Period,
                escalationL3Interval,
                escalationL3Period
            );
        } else if (isValuesValid) {
            updateNewQuestionSetQuestionChanges(
                questionId,
                requiredByTime,
                openIntervalBeforeDueTime,
                openPeriodBeforeDueTime,
                escalationL1Interval,
                escalationL1Period,
                escalationL2Interval,
                escalationL2Period,
                escalationL3Interval,
                escalationL3Period
            );
        }
    };

    const updateNewQuestionSetQuestionChanges = (
        questionId: string,
        requiredByTime: string,
        openIntervalBeforeDueTime: string,
        openPeriodBeforeDueTime: PeriodType,
        escalationL1Interval: string,
        escalationL1Period: PeriodType,
        escalationL2Interval?: string,
        escalationL2Period?: PeriodType,
        escalationL3Interval?: string,
        escalationL3Period?: PeriodType
    ): void => {
        const updatedQuestionScheduleChanges = questionScheduleChanges;

        updatedQuestionScheduleChanges.questionIds.push(Number(questionId));
        updatedQuestionScheduleChanges.requiredByTimes.push(requiredByTime);
        updatedQuestionScheduleChanges.openIntervalsBeforeDueTime.push(
            Number(openIntervalBeforeDueTime)
        );
        updatedQuestionScheduleChanges.openPeriodTypesBeforeDueTime.push(openPeriodBeforeDueTime);
        updatedQuestionScheduleChanges.escalationL1IntervalsAfterDueTime.push(
            Number(escalationL1Interval)
        );
        updatedQuestionScheduleChanges.escalationL1PeriodTypesAfterDueTime.push(escalationL1Period);
        updatedQuestionScheduleChanges.escalationL2IntervalsAfterEscalationL1.push(
            escalationL2Interval ? Number(escalationL2Interval) : null
        );
        updatedQuestionScheduleChanges.escalationL2PeriodTypesAfterEscalationL1.push(
            escalationL2Period ?? null
        );
        updatedQuestionScheduleChanges.escalationL3IntervalsAfterEscalationL2.push(
            escalationL3Interval ? Number(escalationL3Interval) : null
        );
        updatedQuestionScheduleChanges.escalationL3PeriodTypesAfterEscalationL2.push(
            escalationL3Period ? Number(escalationL3Period) : null
        );

        setQuestionScheduleChanges(updatedQuestionScheduleChanges);
    };

    const updateExistingQuestionSetQuestionChanges = (
        index: number,
        questionId: string,
        requiredByTime: string,
        openIntervalBeforeDueTime: string,
        openPeriodBeforeDueTime: PeriodType,
        escalationL1Interval: string,
        escalationL1Period: PeriodType,
        escalationL2Interval?: string,
        escalationL2Period?: PeriodType,
        escalationL3Interval?: string,
        escalationL3Period?: PeriodType
    ): void => {
        const updatedQuestionScheduleChanges = questionScheduleChanges;

        updatedQuestionScheduleChanges.questionIds[index] = Number(questionId);
        updatedQuestionScheduleChanges.requiredByTimes[index] = requiredByTime;
        updatedQuestionScheduleChanges.openIntervalsBeforeDueTime[index] =
            Number(openIntervalBeforeDueTime);
        updatedQuestionScheduleChanges.openPeriodTypesBeforeDueTime[index] =
            openPeriodBeforeDueTime;
        updatedQuestionScheduleChanges.escalationL1IntervalsAfterDueTime[index] =
            Number(escalationL1Interval);
        updatedQuestionScheduleChanges.escalationL1PeriodTypesAfterDueTime[index] =
            escalationL1Period;
        updatedQuestionScheduleChanges.escalationL2IntervalsAfterEscalationL1[index] =
            escalationL2Interval ? Number(escalationL2Interval) : null;
        updatedQuestionScheduleChanges.escalationL2PeriodTypesAfterEscalationL1[index] =
            escalationL2Period ?? null;
        updatedQuestionScheduleChanges.escalationL3IntervalsAfterEscalationL2[index] =
            escalationL3Interval ? Number(escalationL3Interval) : null;
        updatedQuestionScheduleChanges.escalationL3PeriodTypesAfterEscalationL2[index] =
            escalationL3Period ?? null;

        setQuestionScheduleChanges(updatedQuestionScheduleChanges);
    };

    const handleQuestionDisassociate = (
        row: TableRow<number> | undefined,
        checkIfQuestionHasBeenSelected: boolean
    ): void => {
        if (row) {
            const questionId = columnStringValue("ID", row);

            let isQuestionSelected = true;

            if (checkIfQuestionHasBeenSelected) {
                isQuestionSelected =
                    questionSetQuestionsToDissociate.questionIds.find(
                        (x) => x === Number(questionId)
                    ) !== undefined;
            }

            if (questionId && isQuestionSelected) {
                updateExistingQuestionToDissociateChanges(Number(questionId));
            }
        }
    };

    const handleExistingQuestionToAssociate = (
        row: TableRow<number> | undefined,
        checkIfQuestionHasBeenSelected: boolean
    ): void => {
        if (row) {
            const orderIndex = 0;
            const questionId = columnStringValue("ID", row);
            const dueByTime = columnStringValue("DueByTime", row);
            const openDeltaMinutes = columnMinutesValue("OpenBeforeDue", row);
            const escalationL1MinutesFromBaseStart = columnMinutesValue("EL1", row);
            const isDefaultOpenDelta = false;
            let isQuestionSelected = true;

            const dueByMinutes = calculateNumberOfMinutesBetweenTimes(
                dueByTime,
                questionSetDetails.data?.questionSetDefaultScheduleDto.baseStartTimeOfDay
            );

            const totalEscalationL1MinutesFromBaseStart =
                (escalationL1MinutesFromBaseStart ?? 0) + dueByMinutes;

            if (checkIfQuestionHasBeenSelected) {
                isQuestionSelected =
                    existingQuestionsToAssociate.find(
                        (x) => x.questionId === Number(questionId)
                    ) !== undefined;
            }

            if (
                questionId &&
                dueByTime &&
                openDeltaMinutes &&
                escalationL1MinutesFromBaseStart &&
                isQuestionSelected
            ) {
                updateExistingQuestionChanges(
                    orderIndex,
                    Number(questionId),
                    dueByMinutes,
                    Number(openDeltaMinutes),
                    totalEscalationL1MinutesFromBaseStart,
                    isDefaultOpenDelta
                );
            }
        }
    };

    const updateExistingQuestionChanges = (
        orderIndex: number,
        questionId: number,
        dueMinutesFromBaseStart: number,
        openDeltaMinutes: number,
        escalationL1MinutesFromBaseStart: number,
        isDefaultOpenDelta: boolean
    ): void => {
        const updatedExistingQuestionSchedules = existingQuestionsToAssociate;

        const question = existingQuestionsToAssociate.find((x) => x.questionId === questionId);
        const index = question ? existingQuestionsToAssociate.indexOf(question) : null;

        const updatedExistingQuestion = createExistingQuestionSetQuestionDto(
            orderIndex,
            questionSetId,
            questionId,
            dueMinutesFromBaseStart,
            openDeltaMinutes,
            escalationL1MinutesFromBaseStart,
            null,
            null,
            isDefaultOpenDelta
        );

        index != null && question
            ? updatedExistingQuestionSchedules.splice(index, 1)
            : updatedExistingQuestionSchedules.push(updatedExistingQuestion);

        setExistingQuestionsToAssociate(updatedExistingQuestionSchedules);
    };

    const updateExistingQuestionToDissociateChanges = (questionId: number): void => {
        const updatedExistingQuestionToDissociate = questionSetQuestionsToDissociate;

        const question = questionSetQuestionsToDissociate.questionIds.find((x) => x === questionId);
        const index = question
            ? questionSetQuestionsToDissociate.questionIds.indexOf(question)
            : null;

        const updatedExistingQuestion = questionId;
        updatedExistingQuestionToDissociate.questionSetId = questionSetId;

        index != null && question
            ? updatedExistingQuestionToDissociate.questionIds.splice(index, 1)
            : updatedExistingQuestionToDissociate.questionIds.push(updatedExistingQuestion);

        setQuestionSetQuestionsToDissociate(updatedExistingQuestionToDissociate);
    };

    const search = (): void => {
        setQuestionsPaginationDto({ ...questionsPaginationDto, pageNumber: 1, ...searchParams });
        setFilterReferentialLinkDto({
            ...filterReferentialLinkDto,
            pageNumber: 1,
            ...defaultReferentialLinkTypeSearchParamsSearchParams,
        });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setQuestionsPaginationDto({
            ...questionsPaginationDto,
            ...defaultBasePaginationDto,
            ...defaultSearchParams,
        });
        setFilterReferentialLinkDto(defaultFilterReferentialLinkTypesDto);
        setSearchDto(defaultSearchDto);
        setAnswerTypeSearchDto(defaultAnswerTypeSearchDto);
    };

    const answerTextToDisplayForFlagMode = (
        option: SingleValue<AnswerTypeDto>,
        flagMode: AnswerFlagMode
    ): string => {
        let answerToDisplay = "";

        const desirablePredefinedAnswers = option?.predefinedAnswerDtos.filter(
            (x) => x.answerFlagMode === flagMode
        );

        desirablePredefinedAnswers?.forEach(
            (x) =>
                (answerToDisplay = `${answerToDisplay} ${answerToDisplay.length > 0 ? "|" : ""} ${
                    x.predefinedAnswerTextDto.textValue
                }`)
        );

        return answerToDisplay.trim();
    };

    const handleARMonitoringChange = (_: string, checked: boolean): void => {
        if (checked) {
            setSearchDto({
                searchText: ARMonitoringDefaults.ARMonitoring,
                maxSearchResults: null,
            });
        } else {
            setSearchDto(defaultSearchDto);
        }
        setARMonitoring(checked);
    };

    const onQuestionTypeSelected = (option: SingleValue<QuestionTypeDto>): void => {
        setQuestionTypeId(option ? option.questionTypeId : undefined);
        setAnswerTypeSearchDto(
            createAnswerTypeSearchDto(null, option ? option.questionTypeId : null, null)
        );
    };

    const onQuestionCategorySelected = (option: SingleValue<QuestionCategoryDto>): void =>
        setQuestionCategoryId(option ? option.questionCategoryId : undefined);

    const onQuestionImportanceSelected = (option: SingleValue<QuestionImportanceDto>): void =>
        setQuestionImportanceId(option ? option.questionImportanceId : undefined);

    const onAnswerTypeSelected = (option: SingleValue<AnswerTypeDto>): void => {
        setAnswerTypeId(option ? option.answerTypeId : undefined);
        setAnswerTypeName(option ? option.name : "");

        const desirableAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Desirable);
        const undesirableAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Undesirable);
        const neutralAnswer =
            option && answerTextToDisplayForFlagMode(option, AnswerFlagMode.Neutral);

        setDesirableAnswer(
            desirableAnswer && desirableAnswer.length > 0 ? desirableAnswer : undefined
        );
        setUndesirableAnswer(
            undesirableAnswer && undesirableAnswer.length > 0 ? undesirableAnswer : undefined
        );
        setNeutralAnswer(neutralAnswer && neutralAnswer.length > 0 ? neutralAnswer : undefined);
    };

    const buildQuestionSetLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, QuestionSetRoleGroup.WriteRoles) &&
                    buildQuestionSetEditLink()}
                {buildQuestionSetsContainerLink()}
                {buildQuestionSetCreateNewLink()}
                {hasRoleTypeInGroup(auth.userRoles, QuestionSetRoleGroup.WriteRoles) &&
                    buildQuestionSetCloneButton()}
            </>
        );
    };

    const buildQuestionSetsContainerLink = (): ReactElement<typeof Link> => (
        <ViewLink
            label={t("QuestionSets")}
            navigateTo={`${getPath(AccordionTitles.QuestionSets)}`}
        />
    );

    const buildQuestionSetCloneButton = (): ReactElement<typeof Button> => (
        <CloneButton onClick={cloneQuestionSet} />
    );

    const buildQuestionSetEditLink = (): ReactElement<typeof Link> => (
        <EditLink navigateTo={`${getPath(AccordionTitles.QuestionSets)}/${questionSetId}/edit`} />
    );

    const buildQuestionSetCreateNewLink = (): ReactElement<typeof Link> => (
        <CreateLink
            navigateTo={`${getPath(AccordionTitles.QuestionSets)}/create`}
            label={t("CreateNew", { keyPrefix: Common })}
        />
    );

    const renderAssociatedQuestions = (): JSX.Element => (
        <SbAccordion title={t("AssociatedQuestions")}>
            <EndAlignedDiv>
                <OrderLink
                    label={t("OrderQuestions")}
                    navigateTo={`${getPath(AccordionTitles.QuestionSets)}/${questionSetId}/order`}
                />
            </EndAlignedDiv>

            {isQuerySuccessful(getQuestions) && (
                <DataTable
                    columns={questionSetQuestionsColumnNames}
                    rows={rows}
                    keyPrefix={ChecklistQuestions}
                    totalItems={questionSetQuestions.data!.recordCount}
                    paginationDto={questionSetQuestionsPaginationDto}
                    selectItem={(questionId) => {
                        handleQuestionDisassociate(
                            questionSetQuestions.data!.rows?.find((x) => x.metadata === questionId),
                            false
                        );
                    }}
                    selectedItem={(questionId: number): boolean => {
                        return (
                            questionSetQuestionsToDissociate?.questionIds.find(
                                (x) => x === questionId
                            ) !== undefined
                        );
                    }}
                    setPaginationDto={setQuestionSetQuestionsPaginationDto}
                    linkItem={(questionId: number) =>
                        navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`)
                    }
                    linkNestedItem={(nestedQuestionId) =>
                        navigateToNestedQuestion(questionSetQuestions.data!.rows, nestedQuestionId)
                    }
                    editNestedItem={navigateToEditNestedQuestionTrigger}
                    handleUpdatesToRows={(row) =>
                        row ? handleUpdatesToQuestionSetQuestions(row) : {}
                    }
                    nestedColumnNames={nestedQuestionColumnNames}
                    setRows={setRows}
                />
            )}

            <EndAlignedDiv>
                <StyledCheckBoxLabel>
                    <Form.Check
                        onChange={handleSetIntervalDefaults}
                        key={questionScheduleChanges.questionIds.length}
                        defaultChecked={questionScheduleChanges.setIntervalDefaults}
                    />
                </StyledCheckBoxLabel>
                <StyledCheckBoxLabel>{t("SetDefaultsForQuestionIntervals")}</StyledCheckBoxLabel>
                <SaveButton
                    label={`${t("Update", { keyPrefix: Common })}`}
                    type={null}
                    onClick={editQuestionSchedule}
                />
                <DissociateButton
                    onClick={() => setShowDissociateQuestionSetQuestionsConfirmationModal(true)}
                />
                <CancelButton onClick={(): void => navigate(-1)} />
            </EndAlignedDiv>
            <SectionVerticalSpace />

            {renderAssociateExistingQuestionsFilter()}
            <SectionVerticalSpace />

            {renderAssociateNewQuestionFilter()}
        </SbAccordion>
    );

    const renderAssociateExistingQuestionsFilter = (): JSX.Element => (
        <SbAccordion title={t("AssociateExistingQuestions")} isCollapsedOnLoad>
            <ComponentPanel>
                <QuestionFilter
                    questionId={searchParams.questionId}
                    changeQuestionId={(questionId: string): void => {
                        setSearchParams({
                            ...searchParams,
                            questionId: Number(questionId),
                        });
                    }}
                    questionSetName={searchParams.questionSetName}
                    changeQuestionSetName={(questionSetName: string): void => {
                        setSearchParams({
                            ...searchParams,
                            questionSetName: questionSetName,
                        });
                    }}
                    questionText={searchParams.questionText}
                    changeQuestionText={(questionText: string): void => {
                        setSearchParams({
                            ...searchParams,
                            questionText: questionText,
                        });
                    }}
                    questionType={createReactSelectDto(
                        searchParams.questionTypeId!,
                        QuestionTypes[searchParams.questionTypeId!]
                    )}
                    questionTypes={questionTypesArray ?? []}
                    changeQuestionType={(
                        option: SingleValue<ReactSelectDto<QuestionTypes>>
                    ): void => {
                        setSearchParams({
                            ...searchParams,
                            questionTypeId: option?.value ?? null,
                        });
                        setAnswerTypeSearchDto({
                            ...answerTypeSearchDto,
                            questionTypeId: option?.value ?? null,
                        });
                    }}
                    questionCategories={lookupQuestionCategoriesData}
                    changeQuestionCategory={(option: SingleValue<QuestionCategoryDto>): void => {
                        setSearchParams({
                            ...searchParams,
                            questionCategoryId: option?.questionCategoryId ?? null,
                        });
                    }}
                    answerTypes={lookupAnswerTypesData}
                    changeAnswerType={(option: SingleValue<AnswerTypeDto>): void => {
                        setSearchParams({
                            ...searchParams,
                            answerTypeId: option?.answerTypeId ?? null,
                        });
                    }}
                    search={search}
                    resetFilter={resetFilter}
                    areQuestionCategoriesLoading={isQueryLoading(lookupQuestionCategories)}
                    setQuestionCategorySearchText={setSearchDto}
                    areAnswerTypesLoading={isQueryLoading(lookupAnswerTypes)}
                    setAnswerTypeSearchText={setAnswerTypeSearchDto}
                />
            </ComponentPanel>
            <DataTable
                columns={existingQuestionsColumnNames}
                keyPrefix={ChecklistQuestions}
                rows={existingQuestionsRows}
                linkItem={(questionId: number) =>
                    navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`)
                }
                selectItem={(questionId) =>
                    handleExistingQuestionToAssociate(
                        questionsData?.rows.find((x) => x.metadata === questionId),
                        false
                    )
                }
                selectedItem={(questionId: number): boolean => {
                    return (
                        existingQuestionsToAssociate.find((x) => x.questionId === questionId) !==
                        undefined
                    );
                }}
                totalItems={questionsData?.recordCount}
                paginationDto={questionsPaginationDto}
                setPaginationDto={setQuestionsPaginationDto}
                nestedColumnNames={nestedQuestionColumnNames}
                deleteNestedItem={(nestedQuestionId: number): void => {
                    navigate(
                        `${getPath(
                            AccordionTitles.Questions
                        )}/${nestedQuestionId}/dissociate-nested-question`
                    );
                }}
                linkNestedItem={(nestedQuestionId) =>
                    navigateToNestedQuestion(questionsData?.rows, nestedQuestionId)
                }
                editNestedItem={navigateToEditNestedQuestionTrigger}
                setRows={setExistingQuestionsRows}
                handleUpdatesToRows={(row) => handleExistingQuestionToAssociate(row, true)}
            />

            <EndAlignedDiv>
                <AssociateButton onClick={associateExistingQuestionToQuestionSet} />
                <CancelButton onClick={(): void => navigate(-1)} />
            </EndAlignedDiv>
        </SbAccordion>
    );

    const renderAnswerType = (): JSX.Element => (
        <>
            {questionTypeId && (
                <SbFormSelectFieldGroup
                    name={"answerType"}
                    label={t("AnswerType", { keyPrefix: ChecklistAnswers })}
                    placeholderText={t("LookupSearchPlaceholderText", { keyPrefix: Common })!}
                    searchable
                    clearable
                    required
                    items={arMonitoring ? arMonitoringAnswerTypesData : lookupAnswerTypesData}
                    itemDisplayText={(option: AnswerTypeDto) => option.name}
                    onChange={onAnswerTypeSelected}
                    loading={areQueriesLoading([lookupAnswerTypes, arMonitoringAnswerTypes])}
                    onSearchTextChanged={(searchText: string) => {
                        setAnswerTypeSearchDto({
                            ...answerTypeSearchDto,
                            searchText: searchText,
                        });
                    }}
                    defaultSelectedItem={
                        questionTypeId === QuestionTypes.Control && arMonitoring
                            ? arMonitoringAnswerTypesData.filter(
                                  (x) => x.name.trim() === ARMonitoringDefaults.MonitoringAssessment
                              )[0]
                            : null
                    }
                    disabled={arMonitoring}
                />
            )}
        </>
    );

    const renderControlContent = (): JSX.Element => (
        <>
            {renderAnswerType()}
            {desirableAnswer && (
                <SbFormTextFieldGroup
                    name="desirablePredefinedAnswer"
                    label={t("DesirableAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={desirableAnswer}
                />
            )}
            {undesirableAnswer && (
                <SbFormTextFieldGroup
                    name="undesirablePredefinedAnswer"
                    label={t("UndesirableAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={undesirableAnswer}
                />
            )}
            {neutralAnswer && (
                <SbFormTextFieldGroup
                    name="neutralPredefinedAnswer"
                    label={t("NeutralAnswers", { keyPrefix: ChecklistAnswers })}
                    type="text"
                    disabled
                    value={neutralAnswer}
                />
            )}
            <SbFormCheckFieldGroup
                fieldLabel={t("EnableQuestionWeightings", { keyPrefix: ChecklistQuestions })}
                type="checkbox"
                values={[
                    {
                        name: "QuestionWeighting",
                        onChangeHandler: handleQuestionWeightingsChange,
                    },
                ]}
            />
        </>
    );

    const renderNumberValueInput = (threshold: string): JSX.Element => (
        <SbFormTextFieldGroup name={threshold + "Number"} label="the value" type="number" />
    );

    const renderDecimalValueInput = (threshold: string): JSX.Element => (
        <SbFormTextFieldGroup
            name={threshold + "Decimal"}
            label="the value"
            type="text"
            children={"00.00"}
        />
    );

    const renderPredefinedAnswerValues = (): JSX.Element => {
        const predefinedAnswers = lookupAnswerTypesData?.filter(
            (x: AnswerTypeDto) => x.answerTypeId === answerTypeId
        )[0].predefinedAnswerDtos;

        return (
            <>
                {predefinedAnswers?.map((x) => {
                    return (
                        <SbFormNumberBoxGroup
                            key={`predefinedAnswerId${x.predefinedAnswerId.toString()}`}
                            name={`predefinedAnswerId${x.predefinedAnswerId.toString()}`}
                            label={x.predefinedAnswerTextDto.textValue}
                            required={false}
                        />
                    );
                })}
            </>
        );
    };

    const renderQuestionWeightingsContent = (): JSX.Element => (
        <TextTitledPanel title={t("QuestionWeightings", { keyPrefix: ChecklistQuestions })}>
            <SbAlert
                variant={"primary"}
                text={t("QuestionWeightingsHelperText", { keyPrefix: ChecklistQuestions })}
            />
            {answerTypeName && renderPredefinedAnswerValues()}
        </TextTitledPanel>
    );

    const renderIndicatorContent = (): JSX.Element => (
        <>
            <SbFormCheckFieldGroup
                fieldLabel={t("IsAggregate")}
                type="checkbox"
                values={[
                    {
                        name: "Aggregate",
                        onChangeHandler: (_: string, checked: boolean) => setIsAggregate(checked),
                    },
                ]}
            />

            {renderAnswerType()}

            {isAggregate && answerTypeName === "Number" && (
                <SbFormSelectFieldGroup
                    name={"aggregationType"}
                    label={t("AggregationType")}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    items={aggregationTypes} //TODO: Add translations for dynamic data
                    itemDisplayText={(option: AggregationTypeDto) => option.name}
                    onChange={(option: SingleValue<AggregationTypeDto>) =>
                        setAggregationTypeId(option!.aggregationTypeId)
                    }
                    value={
                        aggregationTypeId
                            ? aggregationTypes.find(
                                  (x) => x.aggregationTypeId === aggregationTypeId
                              )
                            : null
                    }
                />
            )}

            <SbFormSelectFieldGroup
                name={"redAnswerThresholdOperators"}
                label={t("RedThreshold")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                searchable
                clearable
                items={answerThresholdOperators}
                itemDisplayText={(option: AnswerThresholdOperatorsDto) =>
                    translateText(t, option.name, ChecklistAnswers)
                }
                onChange={(option: SingleValue<AnswerThresholdOperatorsDto>) =>
                    setRedAnswerThresholdOperatorId(option?.answerThresholdOperatorId)
                }
                variant={"danger"}
                children={
                    answerTypeName === "Number"
                        ? renderNumberValueInput("red")
                        : renderDecimalValueInput("red")
                }
                value={
                    redAnswerThresholdOperatorId
                        ? answerThresholdOperators.find(
                              (x) => x.answerThresholdOperatorId === redAnswerThresholdOperatorId
                          )
                        : null
                }
            />

            <SbFormSelectFieldGroup
                name={"amberAnswerThresholdOperators"}
                label={t("AmberThreshold")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                searchable
                clearable
                items={answerThresholdOperators}
                itemDisplayText={(option: AnswerThresholdOperatorsDto) =>
                    translateText(t, option.name, ChecklistAnswers)
                }
                onChange={(option: SingleValue<AnswerThresholdOperatorsDto>) =>
                    setAmberAnswerThresholdOperatorId(option?.answerThresholdOperatorId)
                }
                variant={"warning"}
                children={
                    answerTypeName === "Number"
                        ? renderNumberValueInput("amber")
                        : renderDecimalValueInput("amber")
                }
                value={
                    amberAnswerThresholdOperatorId
                        ? answerThresholdOperators.find(
                              (x) => x.answerThresholdOperatorId === amberAnswerThresholdOperatorId
                          )
                        : null
                }
            />
        </>
    );

    const renderScheduleContent = (): JSX.Element => (
        <>
            <Row className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("OpenDeltaMinutes")} required />
                </StyledFormLabel>
                <Col sm={4}>
                    <StyledDivFormField>
                        <SbNumberBox
                            name={"OpenTimeValueForNewQuestion"}
                            value={arMonitoring ? "1" : undefined}
                            defaultValue={1}
                            minValue={0}
                            readonly={arMonitoring}
                            disabledStyling={arMonitoring}
                        />
                    </StyledDivFormField>

                    <StyledDivFormField>
                        <SbSelect
                            styles={
                                arMonitoring ? disabledSelectStyles : maxContentWidthSelectStyle
                            }
                            name={"OpenTimePeriodForNewQuestion"}
                            key={"Period open time for new question"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={
                                arMonitoring
                                    ? valuesToReactSelectDtos(viewableTimePeriodOptions).filter(
                                          (x) =>
                                              x.value ===
                                              PeriodTypeOptions.find(
                                                  (x) => x.value === PeriodType.Days
                                              )!.label
                                      )
                                    : valuesToReactSelectDtos(viewableTimePeriodOptions)
                            }
                            itemLabel={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={
                                arMonitoring
                                    ? valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                          (x) =>
                                              x.value ===
                                              PeriodTypeOptions.find(
                                                  (x) => x.value === PeriodType.Days
                                              )!.label
                                      )
                                    : valuesToReactSelectDtos(viewableTimePeriodOptions).find(
                                          (x) =>
                                              x.value ===
                                              PeriodTypeOptions.find(
                                                  (x) => x.value === PeriodType.Hours
                                              )!.label
                                      )
                            }
                        />
                    </StyledDivFormField>
                </Col>
            </Row>
            <Row className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("DueByTime")} required />
                </StyledFormLabel>
                <Col sm={4}>
                    <StyledDivFormField>
                        <StyledInput
                            type="time"
                            name="DueByTimeForNewQuestion"
                            key={"Time for new question"}
                            defaultValue={"00:00"}
                        />
                    </StyledDivFormField>
                </Col>
            </Row>
            <Row className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("EscalationL1MinutesFromBaseStart")} required />
                </StyledFormLabel>
                <Col sm={4}>
                    <StyledDivFormField>
                        <SbNumberBox
                            name={"EL1ValueForNewQuestion"}
                            defaultValue={1}
                            minValue={0}
                        />
                    </StyledDivFormField>

                    <StyledDivFormField>
                        <SbSelect
                            styles={maxContentWidthSelectStyle}
                            name={"EL1PeriodForNewQuestion"}
                            key={"Select period option for EL1"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                            itemLabel={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={valuesToReactSelectDtos(
                                viewableTimePeriodOptions
                            ).find(
                                (x) =>
                                    x.value ===
                                    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!
                                        .label
                            )}
                        />
                    </StyledDivFormField>
                </Col>
            </Row>
            <Row className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("EscalationL2MinutesFromBaseStart")} />
                </StyledFormLabel>
                <Col sm={4}>
                    <StyledDivFormField>
                        <SbNumberBox name={"EL2ValueForNewQuestion"} minValue={0} />
                    </StyledDivFormField>

                    <StyledDivFormField>
                        <SbSelect
                            styles={maxContentWidthSelectStyle}
                            name={"EL2PeriodForNewQuestion"}
                            key={"Period option for EL2"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                            itemLabel={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={valuesToReactSelectDtos(
                                viewableTimePeriodOptions
                            ).find(
                                (x) =>
                                    x.value ===
                                    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!
                                        .label
                            )}
                        />
                    </StyledDivFormField>
                </Col>
            </Row>
            <Row className="mb-3">
                <StyledFormLabel column sm={2}>
                    <SbLabelText label={t("EscalationL3MinutesFromBaseStart")} />
                </StyledFormLabel>
                <Col sm={4}>
                    <StyledDivFormField>
                        <SbNumberBox name={"EL3ValueForNewQuestion"} minValue={0} />
                    </StyledDivFormField>

                    <StyledDivFormField>
                        <SbSelect
                            styles={maxContentWidthSelectStyle}
                            name={"EL3PeriodForNewQuestion"}
                            key={"Select period option for EL3"}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable={false}
                            clearable={false}
                            items={valuesToReactSelectDtos(viewableTimePeriodOptions)}
                            itemLabel={(option: ReactSelectDto<string>) =>
                                t(option.label, { keyPrefix: Common })
                            }
                            itemValue={(option: ReactSelectDto<string>) => option.value}
                            defaultSelectedItem={valuesToReactSelectDtos(
                                viewableTimePeriodOptions
                            ).find(
                                (x) =>
                                    x.value ===
                                    PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!
                                        .label
                            )}
                        />
                    </StyledDivFormField>
                </Col>
            </Row>
        </>
    );

    const handleAssociateReferentialLinkTypeSubmit = (
        event: React.FormEvent<HTMLFormElement>
    ): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        associateQuestionSetReferentialLink.mutate(
            {
                questionSetId: questionSetId,
                referentialLinkTypeId: Number(formData.get("referentialLinkType")),
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateRefLinkToQuestionSetEvent
                    );
                    toast.addToast(
                        createSuccessToastProps([
                            t("AssociateReferentialLinkTypeToQuestionSetSuccessMessage"),
                        ])
                    );
                    queryClient.invalidateQueries(["getAssociatedReferentialLinkTypes"]);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateRefLinkToQuestionSetEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    const renderAssociateNewQuestionFilter = (): JSX.Element => (
        <SbAccordion title={t("AssociateNewQuestion")} isCollapsedOnLoad>
            <Form
                ref={associateNewQuestionFormRef}
                name="associateNewQuestionForm"
                onSubmit={createAndAssociateQuestion}
            >
                <SbFormCheckFieldGroup
                    fieldLabel={t("ARMonitoring", { keyPrefix: Common })}
                    type={"checkbox"}
                    values={[
                        {
                            name: "arMonitoring",
                            label: "",
                            onChangeHandler: handleARMonitoringChange,
                        },
                    ]}
                />

                <SbFormSelectFieldGroup
                    name={"questionType"}
                    label={t("QuestionType")}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    required
                    items={questionTypes}
                    itemDisplayText={(option: QuestionTypeDto) =>
                        translateText(t, option.name, ChecklistQuestions)
                    }
                    onChange={onQuestionTypeSelected}
                    value={
                        questionTypeId
                            ? questionTypes.find((x) => x.questionTypeId === questionTypeId)
                            : null
                    }
                    defaultSelectedItem={
                        arMonitoring
                            ? questionTypes.filter(
                                  (x) => x.questionTypeId === QuestionTypes.Control
                              )[0]
                            : null
                    }
                    disabled={arMonitoring}
                />

                <SbFormSelectFieldGroup
                    name={"questionCategory"}
                    label={t("QuestionCategory")}
                    placeholderText={t("LookupSearchPlaceholderText", { keyPrefix: Common })!}
                    searchable
                    clearable
                    required
                    items={lookupQuestionCategoriesData}
                    itemDisplayText={(option: QuestionCategoryDto) => option.name}
                    onChange={onQuestionCategorySelected}
                    value={
                        questionCategoryId
                            ? lookupQuestionCategoriesData.find(
                                  (x) => x.questionCategoryId === questionCategoryId
                              )
                            : null
                    }
                    disabled={arMonitoring}
                />

                <SbFormSelectFieldGroup
                    name={"questionImportance"}
                    label={t("QuestionImportance")}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    searchable
                    clearable
                    required
                    items={questionImportance}
                    itemDisplayText={(option: QuestionImportanceDto) =>
                        t(QuestionImportance[option.questionImportanceId], {
                            keyPrefix: Common,
                        })
                    }
                    onChange={onQuestionImportanceSelected}
                    value={
                        questionImportanceId
                            ? questionImportance.find(
                                  (x) => x.questionImportanceId === questionImportanceId
                              )
                            : null
                    }
                    defaultSelectedItem={
                        arMonitoring
                            ? questionImportance.filter(
                                  (x) => x.questionImportanceId === QuestionImportance.High
                              )[0]
                            : null
                    }
                />

                <SbFormTextAreaGroup
                    name="questionText"
                    label={t("QuestionText")}
                    required
                    rows={4}
                    maxLength={4000}
                />

                {questionTypeId === QuestionTypes.Control && renderControlContent()}

                {questionTypeId === QuestionTypes.Indicator && renderIndicatorContent()}

                {questionWeightings && renderQuestionWeightingsContent()}
                <LargeVerticalSpace />

                {renderScheduleContent()}

                <EndAlignedDiv>
                    <SaveButton type="submit" />
                    <CancelButton onClick={resetAssociateNewQuestionForm} />
                </EndAlignedDiv>
            </Form>
        </SbAccordion>
    );

    const renderAssociatedReferentialLinks = (): JSX.Element => (
        <SbAccordion title={t("AssociateReferentialLinkType")}>
            <EndAlignedDiv>
                <Form onSubmit={handleAssociateReferentialLinkTypeSubmit}>
                    <EndAlignedDiv>
                        {isQuerySuccessful(getAllAssociateReferentialLinks[1]) && (
                            <StyledSelectField>
                                <SbSelect
                                    name={"referentialLinkType"}
                                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                    searchable
                                    clearable={false}
                                    items={getAllAssociateReferentialLinks[1].data!}
                                    itemLabel={(option) => option.name}
                                    itemValue={(option) => option.referentialLinkTypeId.toString()}
                                />
                            </StyledSelectField>
                        )}
                        <SbButton
                            variant="primary"
                            type="submit"
                            label={t("Associate", { keyPrefix: Common })}
                        />
                    </EndAlignedDiv>
                </Form>
            </EndAlignedDiv>
            <DataTable
                noResultsMessage={`${t("NoReferentialLinkTypesAssociated", {
                    keyPrefix: ActionItems,
                })}`}
                keyPrefix={ChecklistQuestions}
                columns={associatedReferentialLinkTypeColumnNames}
                rows={getAssociatedReferentialLinkTypes!.rows ?? []}
                deleteItem={handleDissociateReferentialLink}
                totalItems={getAssociatedReferentialLinkTypes!.recordCount}
                paginationDto={referentialLinksPaginationDto}
                setPaginationDto={setReferentialLinksPaginationDto}
            />
        </SbAccordion>
    );

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("QuestionSetDetails")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextQuestionSetDetails")}</PageSubHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([...getQuestionSetDetails, ...getQuestionDropdownValues]) && (
                <ContentContainer>
                    <SbModal
                        title={t("DissociateQuestionDisplay")}
                        body={t("DissociateQuestionConfirmationDisplay")}
                        primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                        secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                        onPrimaryButtonClicked={deletedAndDisassociateQuestion}
                        isVisible={showDissociateQuestionSetQuestionsConfirmationModal}
                        updateIsVisible={setShowDissociateQuestionSetQuestionsConfirmationModal}
                    />
                    <Row>
                        <Col md="auto">
                            <DetailsLabel>{t("Name")}</DetailsLabel>
                            <DetailsLabel>{t("Description", { keyPrefix: Common })}</DetailsLabel>
                            <DetailsLabel>{t("Process")}</DetailsLabel>
                            <DetailsLabel>{t("ARMonitoring", { keyPrefix: Common })}</DetailsLabel>
                            <DetailsLabel>{t("CreatedByUser", { keyPrefix: Users })}</DetailsLabel>
                            <DetailsLabel>
                                {t("CreatedByUserEmail", { keyPrefix: Users })}
                            </DetailsLabel>
                            <DetailsLabel>{t("DateCreated", { keyPrefix: Common })}</DetailsLabel>
                            <DetailsLabel>
                                {t("LastModifiedBy", { keyPrefix: Common })}
                            </DetailsLabel>
                            <DetailsLabel>
                                {t("ModifiedByUserEmail", { keyPrefix: Users })}
                            </DetailsLabel>
                            <DetailsLabel>
                                {t("DateLastModified", { keyPrefix: Common })}
                            </DetailsLabel>
                            <DetailsLabel>{t("Checklists")}</DetailsLabel>
                            <DetailsLabel>{t("FrequencyType")}</DetailsLabel>
                            <DetailsLabel>{t("ScheduleDescription")}</DetailsLabel>
                            <DetailsLabel>{t("ViewableFrom")}</DetailsLabel>
                            {questionSetDetails.data!.questionSetDefaultScheduleDto
                                .frequencyType !== FrequencyType.Adhoc && (
                                <DetailsLabel>{t("BaseStartTimeOfDay")}</DetailsLabel>
                            )}
                            <DetailsLabel>{t("MaxOccurrences")}</DetailsLabel>
                            <DetailsLabel>{t("HolidayRollover")}</DetailsLabel>
                            <DetailsLabel>{t("IsLockoutEnabled")}</DetailsLabel>
                        </Col>
                        <Col md="auto">
                            <DetailsValue>{questionSetDetails.data!.name || "-"}</DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.description || "-"}
                            </DetailsValue>
                            <DetailsValue>{questionSetDetails.data!.process || "-"}</DetailsValue>
                            <DetailsValue>
                                <Form.Check
                                    type="checkbox"
                                    label=""
                                    disabled
                                    checked={questionSetDetails.data!.isARMonitoring ? true : false}
                                />
                            </DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.createdByUserFullName || "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.createdByUserName || "-"}
                            </DetailsValue>
                            <DetailsValue>{questionSetDetails.data!.dateCreatedLocal}</DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.modifiedByUserFullName || "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.modifiedByUserName || "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.dateModifiedLocal}
                            </DetailsValue>
                            <DetailsValue>
                                {questionSetDetails.data!.activeChecklistResponses?.length > 0
                                    ? questionSetDetails.data!.activeChecklistResponses?.map(
                                          (checklistResponse, index) => [
                                              index > 0 && ", ",
                                              <SbLink
                                                  variant="primary"
                                                  label={checklistResponse.name}
                                                  navigateTo={`${getPath(
                                                      AccordionTitles.Checklists
                                                  )}/${checklistResponse.checklistId}`}
                                              />,
                                          ]
                                      )
                                    : "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {
                                    FrequencyOptions.find(
                                        (x) => x.value == questionSetSchedule!.frequencyType
                                    )?.label
                                }
                            </DetailsValue>
                            <DetailsValue>{questionSetSchedule!.scheduleDescription}</DetailsValue>
                            <DetailsValue>
                                {questionSetSchedule!.viewableMinutesBeforeBaseStart >=
                                numberOfMinutesInADay
                                    ? questionSetSchedule!.viewableMinutesBeforeBaseStart /
                                      numberOfMinutesInADay
                                    : questionSetSchedule!.viewableMinutesBeforeBaseStart /
                                      numberOfMinutesInAnHour}{" "}
                                {questionSetSchedule!.viewableMinutesBeforeBaseStart >=
                                numberOfMinutesInADay
                                    ? PeriodTypeOptions.find((x) => x.value === PeriodType.Days)!
                                          .label
                                    : PeriodTypeOptions.find((x) => x.value === PeriodType.Hours)!
                                          .label}{" "}
                                {t("Before", { keyPrefix: Common })?.toLowerCase()}
                            </DetailsValue>
                            {questionSetDetails.data!.questionSetDefaultScheduleDto
                                .frequencyType !== FrequencyType.Adhoc && (
                                <DetailsValue>
                                    {questionSetSchedule!.baseStartTimeOfDay}
                                </DetailsValue>
                            )}
                            <DetailsValue>
                                {questionSetSchedule!.maxOccurrences || "-"}
                            </DetailsValue>
                            <DetailsValue>
                                {
                                    NonWorkingDayRolloverOptions.find(
                                        (x) =>
                                            x.value ==
                                            questionSetDetails.data!.questionSetDefaultScheduleDto
                                                .nonWorkingDayRolloverType
                                    )?.label
                                }
                            </DetailsValue>
                            <DetailsValue>
                                <Form.Check
                                    type="checkbox"
                                    label=""
                                    disabled
                                    checked={questionSetSchedule!.isLockoutEnabled}
                                />
                            </DetailsValue>
                        </Col>
                    </Row>

                    <EndAlignedDiv>{buildQuestionSetLinks()}</EndAlignedDiv>
                    <SectionVerticalSpace />

                    {renderAssociatedQuestions()}
                    <SectionVerticalSpace />

                    {renderAssociatedReferentialLinks()}
                </ContentContainer>
            )}
        </>
    );
};

export default ViewQuestionSetContainer;
