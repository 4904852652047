import {
    buildPaginationSearchParameters,
    PaginationRequest,
} from "../../../domain/requests/common/pagination-request";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import { RoleResponse } from "../../../domain/responses/users/role-response";
import client from "../../http-client";

const route = "roles";

export const getRoles = async (
    prefixUrl: string,
    request: PaginationRequest
): Promise<Response<PaginationResponse<RoleResponse>>> => {
    const searchParams = buildPaginationSearchParameters(request);

    return await client
        .get(`${route}/filter`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};
