import { Response } from "../../responses/common/response-response";
import { UserNodeSuspensionDetailsResponse } from "../../responses/users/user-node-suspension-details-response";

export class UserNodeSuspensionDetailsDto {
    userNodeSuspensionId: number | null;
    userId: number | null;
    suspensionStartDate: Date;
    suspensionEndDate: Date;
    secondaryUserId: number | null;

    public constructor(responseData: UserNodeSuspensionDetailsResponse) {
        this.userNodeSuspensionId = responseData.userNodeSuspensionId;
        this.userId = responseData.userId;
        this.suspensionStartDate = responseData.suspensionStartDate;
        this.suspensionEndDate = responseData.suspensionEndDate;
        this.secondaryUserId = responseData.secondaryUserId;
    }

    public static createUserNodeSuspensionDto = (
        response: Response<UserNodeSuspensionDetailsResponse>
    ): UserNodeSuspensionDetailsDto => new UserNodeSuspensionDetailsDto(response.data);
}
