import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { filterActionItemsColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { getNullableString } from "../../../core/utilities/string-helper";
import {
    ActionItemTypeDto,
    createActionItemTypeDto,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import {
    ActionInstanceStatusDto,
    createActionInstanceStatusDto,
} from "../../../domain/dtos/actions/action-instance-status-dto";
import { ActionItemEvidenceDto } from "../../../domain/dtos/actions/action-item-evidence-dto";
import {
    ActionItemImportanceDto,
    createActionItemImportanceDto,
} from "../../../domain/dtos/actions/action-item-importance-dto";
import {
    defaultFilterActionItemsDto,
    FilterActionItemDto,
} from "../../../domain/dtos/actions/filter-action-items-dto";
import {
    emptySearchActionItemsDto,
    SearchActionItemsDto,
} from "../../../domain/dtos/actions/search-action-items-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { MenuRoleGroup } from "../../../domain/enums/MenuRoles";
import { ActionItemRoleGroup, hasRoleTypeInGroup } from "../../../domain/enums/Roles";
import { useFilterActionItems } from "../../../domain/viewmodels/actions/view-action-items-viewmodel";
import { SbAlert } from "../../atoms/SbAlert";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { ActionItemsFilter } from "../../organisms/filters/ActionItemsFilter";

export const allActionItemTypeOption = createActionItemTypeDto(0, 0, "All");
export const allActionItemSubTypeOption = createActionItemTypeDto(0, 0, "All");
export const allActionItemImportanceOption = createActionItemImportanceDto(0, "All");
export const allActionInstanceStatusOption = createActionInstanceStatusDto(-1, "All");

const ActionItemsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchActionItemsDto>(
        emptySearchActionItemsDto()
    );
    const [filterDto, setFilterDto] = useState<FilterActionItemDto>(defaultFilterActionItemsDto);
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    const auth = useAuth();

    const getFilterActionItems = useFilterActionItems(filterDto, paginationDto);
    const filterActionItemsResponseData = getFilterActionItems[0].data;
    const actionItemTypesResponseData = getFilterActionItems[1].data;
    const actionItemSubTypesResponseData = getFilterActionItems[2].data;
    const actionItemsImportanceResponseData = getFilterActionItems[3].data;
    const actionInstanceStatusResponseData = getFilterActionItems[4].data;

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(areQueriesLoading(getFilterActionItems), ActionItemsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const changeActionItemName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemType: option!,
        });
    };

    const onChangeActionItemImportance = (option: SingleValue<ActionItemImportanceDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemImportanceId:
                option!.actionItemImportanceId !== 0 ? option!.actionItemImportanceId : null,
            actionItemImportance: option!,
        });
    };

    const onChangeActionInstanceStatus = (option: SingleValue<ActionInstanceStatusDto>): void => {
        setSearchParams({
            ...searchParams,
            actionInstanceStatusId:
                option!.actionInstanceStatusId !== -1 ? option!.actionInstanceStatusId : null,
            actionInstanceStatus: option!,
        });
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemSubTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemSubType: option!,
        });
    };

    const changeRaciParticipant = (raciParticipant: string): void => {
        setSearchParams({
            ...searchParams,
            raciParticipant: getNullableString(raciParticipant),
        });
    };

    // TODO: Should rather be accepting a number type
    const changeActionItemId = (actionItemId: string): void => {
        setSearchParams({
            ...searchParams,
            actionItemId: getNullableString(actionItemId),
        });
    };

    const changeDueDateTo = (dueDateTo: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateTo: dueDateTo,
        });
    };

    const changeDueDateFrom = (dueDateFrom: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateFrom: dueDateFrom,
        });
    };
    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(emptySearchActionItemsDto());
        setPaginationDto({
            ...paginationDto,
            ...defaultBasePaginationDto,
        });
        setFilterDto({ ...filterDto, ...emptySearchActionItemsDto() });
    };

    const navigateToViewActionItem = (dto: ActionItemEvidenceDto): void => {
        navigate(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}`);
    };

    const navigateToEditActionItem = (dto: ActionItemEvidenceDto): void => {
        navigate(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}/edit`);
    };

    const navigateToCancelActionItem = (dto: ActionItemEvidenceDto): void =>
        navigate(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}/cancel`);

    useKeyPress(EnterKey, search, searchParams);

    if (!hasRoleTypeInGroup(auth.userRoles, MenuRoleGroup.ActionTracker.ActionTrackerAdmin)) {
        return (
            <SbAlert
                variant="warning"
                text={t("YouDoNotHavePermissionToViewThisPage", { keyPrefix: Common })}
            />
        );
    } else {
        return (
            <>
                <PageHeading>{t("ActionItemIndexTitle")}</PageHeading>
                <PageSubHeading>{t("ActionItemHeaderHelpText")}</PageSubHeading>
                <SectionVerticalSpace />

                {areQueriesSuccessful(getFilterActionItems) && (
                    <>
                        <ActionItemsFilter
                            actionItemName={searchParams.name}
                            changeActionItemName={changeActionItemName}
                            actionItemType={searchParams.actionItemType}
                            actionItemTypes={[
                                allActionItemTypeOption,
                                ...actionItemTypesResponseData!,
                            ]}
                            changeActionItemType={onChangeActionTypeValue}
                            actionItemSubType={searchParams.actionItemSubType}
                            actionItemSubTypes={[
                                allActionItemSubTypeOption,
                                ...actionItemSubTypesResponseData!,
                            ]}
                            changeActionItemSubType={onChangeActionSubTypeValue}
                            raciParticipant={searchParams.raciParticipant}
                            changeRaciParticipant={changeRaciParticipant}
                            actionItemId={searchParams.actionItemId}
                            changeActionItemId={changeActionItemId}
                            actionItemImportance={searchParams.actionItemImportance}
                            actionItemImportances={[
                                allActionItemImportanceOption,
                                ...actionItemsImportanceResponseData!,
                            ]}
                            changeActionItemImportance={onChangeActionItemImportance}
                            actionInstanceStatus={searchParams.actionInstanceStatus}
                            actionInstanceStatuses={[
                                allActionInstanceStatusOption,
                                ...actionInstanceStatusResponseData!,
                            ]}
                            changeActionInstanceStatus={onChangeActionInstanceStatus}
                            dueDateFrom={searchParams.dueDateFrom}
                            changeDueDateFrom={changeDueDateFrom}
                            dueDateTo={searchParams.dueDateTo}
                            changeDueDateTo={changeDueDateTo}
                            search={search}
                            resetFilter={resetFilter}
                        />
                        <SectionVerticalSpace />

                        <EndAlignedDiv>
                            <CreateLink
                                label={t("CreateNew", { keyPrefix: Common })}
                                navigateTo={`${getPath(DrawerTitles.ActionItems)}/create`}
                            />
                        </EndAlignedDiv>
                        <LargeVerticalSpace />

                        <DataTable
                            keyPrefix={ActionItems}
                            columns={filterActionItemsColumnNames}
                            rows={filterActionItemsResponseData!.rows}
                            noResultsMessage={t("NoActionItemsFound") as string}
                            totalItems={filterActionItemsResponseData!.recordCount}
                            viewItem={
                                hasRoleTypeInGroup(auth.userRoles, ActionItemRoleGroup.ReadRoles)
                                    ? navigateToViewActionItem
                                    : undefined
                            }
                            editItem={navigateToEditActionItem}
                            deleteItem={
                                hasRoleTypeInGroup(auth.userRoles, ActionItemRoleGroup.WriteRoles)
                                    ? navigateToCancelActionItem
                                    : undefined
                            }
                            paginationDto={paginationDto}
                            setPaginationDto={setPaginationDto}
                        />
                    </>
                )}
            </>
        );
    }
};

export default ActionItemsContainer;
