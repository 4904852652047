import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import { PageHeading, SectionVerticalSpace } from "../../../core/theme/global-styles";
import { pendingAdhocsColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import CancelAdhocChecklistDto from "../../../domain/dtos/checklists/cancel-adhoc-checklist-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import {
    defaultFilterPendingAdhocsDto,
    FilterPendingAdhocsDto,
} from "../../../domain/dtos/pending-adhocs/filter-pending-adhocs-dto";
import {
    emptySearchPendingAdhocsDto,
    SearchPendingAdhocsDto,
} from "../../../domain/dtos/pending-adhocs/search-pending-adhocs-dto";
import { hasRoleTypeInGroup, QuestionSetInstanceRoleGroup } from "../../../domain/enums/Roles";
import { useFilterPendingAdhocs } from "../../../domain/viewmodels/pending-adhocs/view-pending-adhocs-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import { PendingAdhocsFilter } from "../../organisms/filters/PendingAdhocsFilter";

const PendingAdhocsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchPendingAdhocsDto>(
        emptySearchPendingAdhocsDto()
    );
    const [filterDto, setFilterDto] = useState<FilterPendingAdhocsDto>(
        defaultFilterPendingAdhocsDto
    );
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const auth = useAuth();

    const filterPendingAdhocs = useFilterPendingAdhocs(filterDto, paginationDto);
    const filterPendingAdhocsResponseData = filterPendingAdhocs.data;

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(filterPendingAdhocs), PendingAdhocsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.PendingAdhocs);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const changeNode = (node: string): void => {
        setSearchParams({ ...searchParams, node: node ? node : null });
    };

    const changeChecklistName = (checklistName: string): void => {
        setSearchParams({ ...searchParams, checklistName: checklistName ? checklistName : null });
    };

    const changeReferentialLinks = (referentialLinks: string): void => {
        setSearchParams({
            ...searchParams,
            referentialLinks: referentialLinks ? referentialLinks : null,
        });
    };

    const changeTriggeredFor = (triggeredFor: string): void => {
        setSearchParams({ ...searchParams, triggeredFor: triggeredFor ? triggeredFor : null });
    };

    const changeTriggeredBy = (triggeredBy: string): void => {
        setSearchParams({ ...searchParams, triggeredBy: triggeredBy ? triggeredBy : null });
    };

    const changeData = (option: SingleValue<ReactSelectDto<string>>): void => {
        setSearchParams({
            ...searchParams,
            isTraining:
                option!.value === "Training" ? true : option!.value === "Live" ? false : null,
            data: option ? option : null,
        });
    };

    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setPaginationDto({
            ...paginationDto,
            ...defaultBasePaginationDto,
        });
        setSearchParams(emptySearchPendingAdhocsDto());
        setFilterDto(defaultFilterPendingAdhocsDto);
    };

    const navigateToCancelPendingAdhoc = (dto: CancelAdhocChecklistDto): void => {
        const params = [
            createNavigateSearchParameter("questionSetId", dto.questionSetId.toString()),
        ];

        navigateSearch(
            `${getPath(DrawerTitles.PendingAdhocs)}/${dto.questionSetInstanceId}/cancel`,
            params
        );
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("PendingAdhocChecklists")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterPendingAdhocs) && (
                <>
                    <PendingAdhocsFilter
                        node={searchParams.node}
                        changeNode={changeNode}
                        checklistName={searchParams.checklistName}
                        changeChecklistName={changeChecklistName}
                        referentialLinks={searchParams.referentialLinks}
                        changeReferentialLinks={changeReferentialLinks}
                        triggeredFor={searchParams.triggeredFor}
                        changeTriggeredFor={changeTriggeredFor}
                        triggeredBy={searchParams.triggeredBy}
                        changeTriggeredBy={changeTriggeredBy}
                        data={searchParams.data}
                        changeData={changeData}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <SectionVerticalSpace />

                    <DataTable
                        keyPrefix={ChecklistQuestions}
                        columns={pendingAdhocsColumnNames}
                        rows={filterPendingAdhocsResponseData!.rows}
                        totalItems={filterPendingAdhocsResponseData!.recordCount}
                        deleteItem={
                            hasRoleTypeInGroup(
                                auth.userRoles,
                                QuestionSetInstanceRoleGroup.DeleteRoles
                            )
                                ? navigateToCancelPendingAdhoc
                                : undefined
                        }
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default PendingAdhocsContainer;
