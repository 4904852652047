import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import { filterReviewReportSets } from "../../../data/services/review-reports/review-reports-service";
import { translateText } from "../../../presentation/helpers/translate";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { toRatingLabel } from "../../enums/review-reports/review-ratings";
import ReviewStatus from "../../enums/review-reports/review-status";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { ReviewReportSetResponse } from "../../responses/review-reports/review-report-set-response";

export const useFilerReviewReportSets = (
    paginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const { t } = useTranslation("translation", { keyPrefix: Common });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterReviewResportSets", paginationDto],
        () => filterReviewReportSets(url.baseUrl, createPaginationRequestFromDto(paginationDto)),
        {
            keepPreviousData: true,
            select: (response: Response<PaginationResponse<ReviewReportSetResponse>>) =>
                transformToDataTableRows(response, t),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<ReviewReportSetResponse>>,
    t: any
): PaginatedTableDto<number> => {
    const rows = response.data.results!.map((reviewReportSet) => {
        return {
            metadata: reviewReportSet.reviewReportSetId,
            columns: [
                {
                    //Report Name
                    value: reviewReportSet.reviewReportName.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Rating
                    value: translateText(t, toRatingLabel(reviewReportSet.reviewRating), Common),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Status
                    value: translateText(
                        t,
                        ReviewStatus[reviewReportSet.reviewStatus].toString(),
                        ChecklistQuestions
                    ),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};
