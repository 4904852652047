import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    AssociateRaciParticipant,
    dissociateReferentialLink,
    filterAssociatedRaciSetUsers,
    getActionItemDetails,
    getActionItemInstanceFiles,
    getAdditionalFieldValues,
    getAssociatedNodes,
    getAssociatedReferentialLinks,
} from "../../../data/services/actions/action-items-service";
import { filterUsers } from "../../../data/services/users/users-service";
import { ActionItemDetailsDto } from "../../dtos/actions/action-item-details-dto";
import { ActionItemDynamicFieldValueDto } from "../../dtos/actions/action-item-dynamic-field-values-dto";
import { AssociateDissociateReferentialLinkToActionItemDto } from "../../dtos/actions/associate-dissociate-referential-link-to-action-item-dto";
import { AssociateRaciParticipantDto } from "../../dtos/actions/associate-raci-participant-dto";
import { AssociatedActionItemRaciSetUserDto } from "../../dtos/actions/associated-action-item-raci-set-user-dto";
import { SearchActionItemAssociationDto } from "../../dtos/actions/search-action-item-association-dto";
import { SearchActionItemNodeAssociationDto } from "../../dtos/actions/search-action-item-node-association-dto";
import { BasePaginationDto } from "../../dtos/common/base-pagination-dto";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { FileDto } from "../../dtos/file-storage/file-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import RaciRelationship from "../../enums/action-items/RaciRelationship";
import { AssociateDissociateReferentialLinkToActionItemRequest } from "../../requests/actions/associate-dissociate-referential-link-to-action-item-request";
import { AssociateRaciParticipantRequest } from "../../requests/actions/associate-raci-participant-request";
import { createFilterActionItemAssociationRequest } from "../../requests/actions/filter-action-item-association-request";
import { createFilterActionItemNodeAssociationRequest } from "../../requests/actions/filter-action-item-node-association-request";
import { createFilterAssociatedRaciSetUsersRequest } from "../../requests/actions/filter-associated-raci-set-users-request";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { createFilterUsersSearchRequest } from "../../requests/users/filter-users-request";
import { ActionItemReferentialLinkResponse } from "../../responses/actions/action-item-referential-link-response";
import { RaciSetUserResponse } from "../../responses/actions/raci-set-user-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseNodeDetailsResponse } from "../../responses/hierarchy/base-node-details-response";

export const useGetActionItemDetails = (
    actionItemId: number,
    organisationalNodesSearchDto: SearchActionItemNodeAssociationDto,
    organisationalNodesPaginationDto: PaginationDto,
    geographyNodesSearchDto: SearchActionItemNodeAssociationDto,
    geographyNodesPaginationDto: PaginationDto,
    taxonomyNodesSearchDto: SearchActionItemNodeAssociationDto,
    taxonomyNodesPaginationDto: PaginationDto,
    searchDto: SearchActionItemAssociationDto,
    referentialLinksPaginationDto: PaginationDto
): [
    UseQueryResult<ActionItemDetailsDto, HTTPError>,
    UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
    UseQueryResult<FileDto[], HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const navigate = useNavigate();

    return useQueries({
        queries: [
            {
                queryKey: ["getActionItemDetails", actionItemId],
                queryFn: () => getActionItemDetails(url.baseUrl, actionItemId),
                select: ActionItemDetailsDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },

            {
                queryKey: ["getAdditionalFieldValues", actionItemId],
                queryFn: () => getAdditionalFieldValues(url.baseUrl, actionItemId!),
                select: ActionItemDynamicFieldValueDto.toActionItemDynamicFieldValueDtos,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["getActionItemInstanceFiles", actionItemId],
                queryFn: () => getActionItemInstanceFiles(url.baseUrl, actionItemId),
                select: FileDto.constructFromActionItemInstanceFileDownloadResponses,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedNodes",
                    organisationalNodesPaginationDto,
                    organisationalNodesSearchDto,
                ],
                queryFn: () =>
                    getAssociatedNodes(
                        url.baseUrl,
                        createFilterActionItemNodeAssociationRequest(
                            createPaginationRequestFromDto(organisationalNodesPaginationDto),
                            organisationalNodesSearchDto
                        )
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<BaseNodeDetailsResponse>>) =>
                    transformToNodesDataTableRows(response, navigate),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedNodes",
                    geographyNodesPaginationDto,
                    geographyNodesSearchDto,
                ],
                queryFn: () =>
                    getAssociatedNodes(
                        url.baseUrl,
                        createFilterActionItemNodeAssociationRequest(
                            createPaginationRequestFromDto(geographyNodesPaginationDto),
                            geographyNodesSearchDto
                        )
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<BaseNodeDetailsResponse>>) =>
                    transformToNodesDataTableRows(response, navigate),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedNodes",
                    taxonomyNodesPaginationDto,
                    taxonomyNodesSearchDto,
                ],
                queryFn: () =>
                    getAssociatedNodes(
                        url.baseUrl,
                        createFilterActionItemNodeAssociationRequest(
                            createPaginationRequestFromDto(taxonomyNodesPaginationDto),
                            taxonomyNodesSearchDto
                        )
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<BaseNodeDetailsResponse>>) =>
                    transformToNodesDataTableRows(response, navigate),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: [
                    "getAssociatedReferentialLinks",
                    referentialLinksPaginationDto,
                    searchDto,
                ],
                queryFn: () =>
                    getAssociatedReferentialLinks(
                        url.baseUrl,
                        createFilterActionItemAssociationRequest(
                            createPaginationRequestFromDto(referentialLinksPaginationDto),
                            searchDto
                        )
                    ),
                keepPreviousData: true,
                select: transformToReferentialLinksDataTableRows,
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<ActionItemDetailsDto, HTTPError>,
        UseQueryResult<ActionItemDynamicFieldValueDto[], HTTPError>,
        UseQueryResult<FileDto[], HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

export const useFilterAssociatedRaciSetUsers = (
    actionItemId: number,
    raciParticipantsPaginationDto: BasePaginationDto
): UseQueryResult<PaginatedTableDto<AssociatedActionItemRaciSetUserDto>, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterAssociatedRaciSetUsers", raciParticipantsPaginationDto, actionItemId],
        () =>
            filterAssociatedRaciSetUsers(
                url.baseUrl,
                createFilterAssociatedRaciSetUsersRequest(
                    raciParticipantsPaginationDto,
                    actionItemId
                )
            ),
        {
            keepPreviousData: true,
            enabled: actionItemId != null,
            select: toAssociatedRaciParticipantsToDataTableRows,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useFilterUsers = (
    paginationDto: PaginationDto,
    searchText: string | null
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsers", paginationDto, searchText],
        () =>
            filterUsers(
                url.baseUrl,
                createFilterUsersSearchRequest(
                    searchText,
                    paginationDto.pageNumber,
                    paginationDto.pageSize,
                    false
                )
            ),
        { select: toBaseUserDtosFromPaginatedUserResponse, onError: errorResponseToDisplayHandler }
    );
};

export const useGetActionItemDetailsByActionItemId = (
    actionItemId: number
): UseQueryResult<ActionItemDetailsDto, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["useGetActionItemDetailsByActionItemId", actionItemId],
        () => getActionItemDetails(url.baseUrl, actionItemId),
        {
            select: ActionItemDetailsDto.constructFromResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

const toAssociatedRaciParticipantsToDataTableRows = (
    response: Response<PaginationResponse<RaciSetUserResponse>>
): PaginatedTableDto<AssociatedActionItemRaciSetUserDto> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((raciSetUser) => ({
        metadata: new AssociatedActionItemRaciSetUserDto(
            raciSetUser.userId,
            raciSetUser.raciSetId,
            raciSetUser.raciRelationship
        ),
        columns: [
            {
                value: `${raciSetUser.isDeleted ? "(inactive)" : ""} ${raciSetUser.firstName} ${
                    raciSetUser.lastName
                } - ${raciSetUser.emailAddress}`,
                type: DataTableColumnTypes.Text,
            },
            {
                value: RaciRelationship[raciSetUser.raciRelationship].toString(),
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToNodesDataTableRows = (
    response: Response<PaginationResponse<BaseNodeDetailsResponse>>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((x) => ({
        metadata: x.nodeId,
        columns: [
            {
                value: `${x.nodeType.name} - ${x.nodeTypeValue?.value}`,
                type: DataTableColumnTypes.Link,
                linkItemAction: (nodeId: number) =>
                    navigate(`${getPath(AccordionTitles.VisualTree)}/${nodeId}`),
            },
            {
                value: x.path,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToReferentialLinksDataTableRows = (
    response: Response<PaginationResponse<ActionItemReferentialLinkResponse>>
): PaginatedTableDto<number> => {
    const responseData = response.data;
    const { pageCount, pageSize, currentPage, recordCount } = responseData;

    const rows = responseData.results!.map((referentialLink) => ({
        metadata: referentialLink.referentialLinkId,
        columns: [
            {
                value: referentialLink.referentialLinkTypeName,
                type: DataTableColumnTypes.Text,
            },
            {
                value: referentialLink.value,
                type: DataTableColumnTypes.Text,
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

export const useAssociateRaciParticipant = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateRaciParticipantDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateRaciParticipantDto) =>
        AssociateRaciParticipant(url.baseUrl, new AssociateRaciParticipantRequest(dto))
    );
};

export const useDissociateActionItemReferentialLink = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    AssociateDissociateReferentialLinkToActionItemDto
> => {
    const url = useUrl();

    return useMutation((dto: AssociateDissociateReferentialLinkToActionItemDto) => {
        const request = new AssociateDissociateReferentialLinkToActionItemRequest(dto);

        return dissociateReferentialLink(url.baseUrl, request);
    });
};
