import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Common, Users } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { userManagementColumnNames } from "../../../core/utilities/dataTableColumns";
import { userManagementColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import { toBoolean } from "../../../core/utilities/string-helper";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { defaultFilterUsersDto, FilterUsersDto } from "../../../domain/dtos/users/filter-users-dto";
import { hasRoleTypeInGroup, UserRoleGroup } from "../../../domain/enums/Roles";
import { useFilterUsers } from "../../../domain/viewmodels/users/view-users-viewmodel";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { UsersFilter } from "../../organisms/filters/UsersFilter";

interface SearchParams {
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    isDeleted: boolean | null;
    isLockedOut: boolean | null;
}

const createSearchParams = (
    firstName: string | null,
    lastName: string | null,
    emailAddress: string | null,
    isDeleted: boolean | null,
    isLockedOut: boolean | null
): SearchParams => ({
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    isDeleted: isDeleted,
    isLockedOut: isLockedOut,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null);

const UsersContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterUsersDto>(defaultFilterUsersDto);

    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const filterUsers = useFilterUsers(filterDto);

    useLoader(isQueryLoading(filterUsers), UsersContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, DrawerTitles.UserManagement);
    }, []);

    const sortBy = (column: string): void => {
        setFilterDto({
            ...filterDto,
            sortByColumn: userManagementColumnToProperty[column],
            sortByDescending: !filterDto.sortByDescending,
        });
    };

    const navigateToViewUser = (metadata: BaseUserDto): void =>
        navigate(`${getPath(DrawerTitles.UserManagement)}/${metadata.userId}`);

    const navigateToEditUser = (metadata: BaseUserDto): void =>
        navigate(`${getPath(DrawerTitles.UserManagement)}/${metadata.userId}/edit`);

    const navigateToDeleteUser = (metadata: BaseUserDto): void => {
        const { userId, emailAddress } = metadata;

        return hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.SystemAdminRoles)
            ? navigate(`${getPath(DrawerTitles.UserManagement)}/${userId}/delete`, {
                  state: { username: emailAddress },
              })
            : undefined;
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultBasePaginationDto, ...defaultSearchParams });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const changeFirstName = (firstName: string): void => {
        setSearchParams({
            ...searchParams,
            firstName: firstName !== "" ? firstName : null,
        });
    };

    const changeLastName = (lastName: string): void => {
        setSearchParams({
            ...searchParams,
            lastName: lastName !== "" ? lastName : null,
        });
    };

    const changeEmailAddress = (emailAddress: string): void => {
        setSearchParams({
            ...searchParams,
            emailAddress: emailAddress !== "" ? emailAddress : null,
        });
    };

    const changeIsDeleted = (option: SingleValue<ReactSelectDto<string>>): void => {
        setSearchParams({
            ...searchParams,
            isDeleted: option?.value ? toBoolean(option.value) : null,
        });
    };

    const changeIsLockedOut = (option: SingleValue<ReactSelectDto<string>>): void => {
        setSearchParams({
            ...searchParams,
            isLockedOut: option?.value ? toBoolean(option.value) : null,
        });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("UserManagement")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}.</PageSubHeading>
            <SectionVerticalSpace />

            <UsersFilter
                lastName={searchParams.lastName}
                changeLastName={changeLastName}
                firstName={searchParams.firstName}
                changeFirstName={changeFirstName}
                emailAddress={searchParams.emailAddress}
                changeEmailAddress={changeEmailAddress}
                isDeleted={searchParams.isDeleted}
                changeIsDeleted={changeIsDeleted}
                isLockedOut={searchParams.isLockedOut}
                changeIsLockedOut={changeIsLockedOut}
                search={search}
                resetFilter={resetFilter}
            />
            <LargeVerticalSpace />

            <EndAlignedDiv>
                {/* // TODO: Update after role-aware menu work is pushed */}
                {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.SystemAdminRoles) && (
                    <CreateLink
                        label={t("CreateNew", { keyPrefix: Common })}
                        navigateTo={`${getPath(DrawerTitles.UserManagement)}/create`}
                    />
                )}
            </EndAlignedDiv>
            <LargeVerticalSpace />

            {isQuerySuccessful(filterUsers) && (
                <DataTable
                    columns={userManagementColumnNames}
                    rows={filterUsers.data!.rows}
                    keyPrefix={Users}
                    viewItem={navigateToViewUser}
                    editItem={
                        hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.WriteRoles)
                            ? navigateToEditUser
                            : undefined
                    }
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.SystemAdminRoles)
                            ? navigateToDeleteUser
                            : undefined
                    }
                    sortBy={sortBy}
                    totalItems={filterUsers.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default UsersContainer;
