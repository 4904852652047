import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Audits } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { auditColumnNames } from "../../../core/utilities/dataTableColumns";
import { auditColumnToProperty } from "../../../core/utilities/dataTableColumnToProperty";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    defaultFilterAuditsChangelogDto,
    FilterAuditsChangeLogDto,
} from "../../../domain/dtos/audits/filter-audits-change-log-dto";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { useGetAuditChangeLogs } from "../../../domain/viewmodels/audits/audits-change-logs-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import { AuditFilter } from "../../organisms/filters/AuditFilter";
import ViewAuditsChangelogContainer from "./ViewAuditsChangelogContainer";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";

interface SearchParams {
    tableName: string | null;
    username: string | null;
    startDate: string | null;
    endDate: string | null;
    actionType: string | null;
    entityKey: string | null;
}

const createSearchParams = (
    tableName: string | null,
    username: string | null,
    startDate: string | null,
    endDate: string | null,
    actionType: string | null,
    entityKey: string | null
): SearchParams => ({
    tableName: tableName,
    username: username,
    startDate: startDate,
    endDate: endDate,
    actionType: actionType,
    entityKey: entityKey,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null, null);

const AuditsChangelogContainer: React.FC = () => {
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);
    const [show, setShow] = useState(false);
    const [changelogId, setChangelogId] = useState<number>(0);
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterAuditsChangeLogDto>(
        defaultFilterAuditsChangelogDto
    );

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: Audits });

    const getAuditsChangeLogs = useGetAuditChangeLogs(filterDto);

    useLoader(isQueryLoading(getAuditsChangeLogs), AuditsChangelogContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Audits, DrawerTitles.Audits);
    }, []);

    const sortBy = (column: string): void => {
        setPaginationDto({
            ...paginationDto,
            sortByColumn: auditColumnToProperty[column],
            sortByDescending: !paginationDto.sortByDescending,
        });
    };

    const changeTableName = (tableName: string): void => {
        setSearchParams({
            ...searchParams,
            tableName: tableName !== "" ? tableName : null,
        });
    };

    const changeUsername = (username: string): void => {
        setSearchParams({
            ...searchParams,
            username: username !== "" ? username : null,
        });
    };

    const changeStartDate = (startDate: string): void => {
        setSearchParams({
            ...searchParams,
            startDate: startDate !== "" ? startDate : null,
        });
    };

    const changeEndDate = (endDate: string): void => {
        setSearchParams({
            ...searchParams,
            endDate: endDate !== "" ? endDate : null,
        });
    };

    const changeActionType = (option: SingleValue<ReactSelectDto<string>>): void => {
        setSearchParams({
            ...searchParams,
            actionType: option?.value ?? null,
        });
    };

    const changeEntityKey = (entityKey: string): void => {
        setSearchParams({
            ...searchParams,
            entityKey: entityKey !== "" ? entityKey : null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultBasePaginationDto, ...defaultSearchParams });
    };

    const handleOpen = (): void => {
        setShow(true);
    };

    const handleClose = (): void => {
        setShow(false);
    };

    const viewChangeLogItem = (id: number): void => {
        setChangelogId(id);
        handleOpen();
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            {show && (
                <ViewAuditsChangelogContainer
                    changelogId={changelogId}
                    show={show}
                    handleClose={handleClose}
                />
            )}
            <PageHeading>{t("AuditLogDataChanges")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            <AuditFilter
                username={searchParams.username}
                tableName={searchParams.tableName}
                startDate={searchParams.startDate}
                endDate={searchParams.endDate}
                entityKey={searchParams.entityKey}
                actionType={searchParams.actionType}
                changeTableName={changeTableName}
                changeUsername={changeUsername}
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
                changeActionType={changeActionType}
                changeEntityKey={changeEntityKey}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            {isQuerySuccessful(getAuditsChangeLogs) && (
                <DataTable
                    columns={auditColumnNames}
                    rows={getAuditsChangeLogs.data!.rows}
                    viewItem={viewChangeLogItem}
                    sortBy={sortBy}
                    totalItems={getAuditsChangeLogs.data!.recordCount}
                    paginationDto={filterDto}
                    setPaginationDto={setFilterDto}
                />
            )}
        </>
    );
};

export default AuditsChangelogContainer;
