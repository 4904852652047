import { ActionItemRaciParticipantDetailsDto } from "../../dtos/actions/action-item-raci-participant-details-dto";

export class ActionItemRaciParticipantDetailsRequest {
    raciSetId: number;
    userId: number;
    raciRelationship: number;

    public constructor(dto: ActionItemRaciParticipantDetailsDto) {
        const { raciSetId, userId, raciRelationship } = dto;

        this.raciSetId = raciSetId;
        this.userId = userId;
        this.raciRelationship = raciRelationship;
    }
}

export const constructRaciParticipantDetailsSearchParamsFromActionItem = (
    request: ActionItemRaciParticipantDetailsRequest
): URLSearchParams => {
    const searchParams = new URLSearchParams();

    if (request.raciSetId) {
        searchParams.set("raciSetId", request.raciSetId.toString());
    }

    if (request.userId) {
        searchParams.set("userId", request.userId.toString());
    }

    if (request.raciRelationship) {
        searchParams.set("raciRelationship", request.raciRelationship.toString());
    }

    return searchParams;
};
