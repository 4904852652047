import { Response } from "../../responses/common/response-response";
import { QuestionSetInstanceAnswerCommentResponse } from "../../responses/review-reports/question-set-instance-answer-comment-response";

export class QuestionSetInstanceAnswerCommentDto {
    text: string;
    dateCreatedUtc: Date;
    questionSetInstanceAnswerId: number;

    public constructor(response: QuestionSetInstanceAnswerCommentResponse) {
        this.text = response.text;
        this.dateCreatedUtc = response.dateCreatedUtc;
        this.questionSetInstanceAnswerId = response.questionSetInstanceAnswerId;
    }

    public static constructFromResponse = (
        response: Response<QuestionSetInstanceAnswerCommentResponse>
    ): QuestionSetInstanceAnswerCommentDto =>
        new QuestionSetInstanceAnswerCommentDto(response.data);
}
