import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { AssociateNestedQuestionEvent } from "../../../core/constants/application-insights-events";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { TableRow } from "../../../core/utilities/customTypes";
import {
    associateChildQuestionsColumnNames,
    nestedQuestionColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import AnswerTypeDto from "../../../domain/dtos/answer-types/answer-type-dto";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import {
    AnswerTypeSearchDto,
    defaultAnswerTypeSearchDto,
    defaultSearchDto,
    SearchDto,
} from "../../../domain/dtos/common/search-dto";
import { QuestionCategoryDto } from "../../../domain/dtos/question-categories/question-category-dto";
import { AssociateChildQuestionDto } from "../../../domain/dtos/questions/associate-child-question-dto";
import {
    defaultFilterQuestionsDto,
    FilterQuestionsDto,
} from "../../../domain/dtos/questions/filter-questions-dto";
import { QuestionTypes } from "../../../domain/enums/questions/question-types";
import {
    useAssociateChildQuestion,
    useGetQuestions,
    useLookupQuestionCategories,
} from "../../../domain/viewmodels/questions/associate-child-question-viewmodel";
import { useLookupAnswerTypes } from "../../../domain/viewmodels/questions/view-question-dropdown-values-viewmodel";
import { AssociateButton, CancelButton } from "../../atoms/SbButton";
import { DataTable } from "../../organisms/DataTable";
import { QuestionFilter } from "../../organisms/filters/QuestionFilter";

interface SearchParams {
    questionId: number | null;
    questionSetName: string | null;
    questionText: string | null;
    questionTypeId: number | null;
    questionCategoryId: number | null;
    answerTypeId: number | null;
}

const createSearchParams = (
    questionId: number | null,
    questionSetName: string | null,
    questionText: string | null,
    questionTypeId: number | null,
    questionCategoryId: number | null,
    answerTypeId: number | null
): SearchParams => ({
    questionId: questionId,
    questionSetName: questionSetName,
    questionText: questionText,
    questionTypeId: questionTypeId,
    questionCategoryId: questionCategoryId,
    answerTypeId: answerTypeId,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null, null);

const questionTypesArray = getEnumsForType(QuestionTypes).map((x) =>
    createReactSelectDto(x, QuestionTypes[x])
);

const AssociateChildQuestionContainer: React.FC = () => {
    const parentQuestionId = Number(useParams().parentQuestionId);

    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterQuestionsDto>(defaultFilterQuestionsDto);

    const [, setRows] = useState<TableRow<number>[]>([]);
    const [childQuestionToAssociate, setChildQuestionToAssociate] =
        useState<AssociateChildQuestionDto | null>();
    const [questionIdsThatTriggerWhenParentDesirable, setQuestionIdThatTriggerWhenParentDesirable] =
        useState<number[]>([]);
    const [answerTypeSearchDto, setAnswerTypeSearchDto] = useState<AnswerTypeSearchDto>(
        defaultAnswerTypeSearchDto
    );
    const [searchDto, setSearchDto] = useState<SearchDto>(defaultSearchDto);

    const lookupQuestionCategories = useLookupQuestionCategories(searchDto);
    const lookupQuestionCategoriesData = lookupQuestionCategories.data!;

    const lookupAnswerTypes = useLookupAnswerTypes(answerTypeSearchDto);
    const lookupAnswerTypesData = lookupAnswerTypes.data!;

    const getQuestions = useGetQuestions(parentQuestionId, filterDto);
    const associateChildQuestion = useAssociateChildQuestion();
    const parentQuestionDetails = getQuestions[0];
    const questions = getQuestions[1];

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    useLoader(areQueriesLoading(getQuestions), AssociateChildQuestionContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    useEffect(() => {
        setChildQuestionToAssociate(null);
    }, [filterDto.pageNumber]);

    const handleAssociateChilQuestion = (): void => {
        childQuestionToAssociate &&
            associateChildQuestion.mutate(childQuestionToAssociate, {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateNestedQuestionEvent
                    );
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "AssociateNestedQuestionSuccessMessage"
                        ),
                    ];

                    navigateSearch(
                        `${getPath(AccordionTitles.Questions)}/${parentQuestionId}`,
                        params
                    );
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateNestedQuestionEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            });
    };

    const navigateToViewQuestion = (questionId: number): void =>
        navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`);

    const navigateToEditNestedQuestionTrigger = (nestedQuestionId: number): void => {
        const params = [
            createNavigateSearchParameter("nestedQuestionId", nestedQuestionId.toString()),
        ];

        navigateSearch(
            `${getPath(AccordionTitles.Questions)}/update-nested-question-trigger-selection`,
            params
        );
    };

    const navigateToNestedQuestion = (nestedQuestionId: number): void => {
        let nestedRow: TableRow<number> | undefined;

        questions!.data?.rows.find(
            (x) => (nestedRow = x.nestedRows?.find((n) => n.metadata === nestedQuestionId))
        );

        const questionId = nestedRow?.columns.find((x) => x.metadata === "ID")?.value;
        if (nestedRow && questionId) {
            navigate(`${getPath(AccordionTitles.Questions)}/${questionId}`);
        }
    };

    const handleTriggerWhenParentDesirableCheckBox = (childQuestionId: number): void => {
        const questionIds = questionIdsThatTriggerWhenParentDesirable;
        const question = questionIds?.find((x) => x === childQuestionId);

        if (question) {
            questionIds?.splice(
                questionIds?.findIndex((x) => x === childQuestionId),
                1
            );
        } else {
            questionIds?.push(childQuestionId);
        }
        setQuestionIdThatTriggerWhenParentDesirable(questionIds);

        if (childQuestionToAssociate?.childQuestionId === childQuestionId) {
            setChildQuestionToAssociate({
                parentQuestionId: parentQuestionId,
                childQuestionId: childQuestionId,
                triggerWhenParentDesirable: questionIdsThatTriggerWhenParentDesirable?.find(
                    (x) => x === childQuestionId
                )
                    ? true
                    : false,
            });
        }
    };

    const changeQuestionId = (questionId: string): void => {
        setSearchParams({
            ...searchParams,
            questionId: Number(questionId),
        });
    };

    const changeQuestionSetName = (questionSetName: string): void => {
        setSearchParams({
            ...searchParams,
            questionSetName: questionSetName,
        });
    };

    const changeQuestionText = (questionText: string): void => {
        setSearchParams({
            ...searchParams,
            questionText: questionText,
        });
    };

    const changeQuestionType = (option: SingleValue<ReactSelectDto<QuestionTypes>>): void => {
        setSearchParams({
            ...searchParams,
            questionTypeId: option?.value ?? null,
        });

        setAnswerTypeSearchDto({
            ...answerTypeSearchDto,
            questionTypeId: option?.value ?? null,
        });
    };

    const changeQuestionCategory = (option: SingleValue<QuestionCategoryDto>): void => {
        setSearchParams({
            ...searchParams,
            questionCategoryId: option?.questionCategoryId ?? null,
        });
    };

    const changeAnswerType = (option: SingleValue<AnswerTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            answerTypeId: option?.answerTypeId ?? null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);

        setFilterDto({ ...filterDto, ...defaultBasePaginationDto, ...defaultSearchParams });
        setSearchDto(defaultSearchDto);
        setAnswerTypeSearchDto(defaultAnswerTypeSearchDto);
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("AssociateChildQuestion")}</PageHeading>
            <PageSubHeading>
                {t("AssociateNodeHelpTextQuestionChild")}
                {isQuerySuccessful(parentQuestionDetails) && (
                    <>
                        <SectionVerticalSpace />
                        <Row>
                            <Col md={"2"}>
                                <DetailsLabel>{t("ParentQuestionText")}</DetailsLabel>
                            </Col>
                            <Col md={"auto"}>
                                <DetailsValue>
                                    {parentQuestionDetails.data!.questionTextDto.text}
                                </DetailsValue>
                            </Col>
                        </Row>
                    </>
                )}
            </PageSubHeading>

            <SectionVerticalSpace />
            <QuestionFilter
                questionId={searchParams.questionId}
                changeQuestionId={changeQuestionId}
                questionSetName={searchParams.questionSetName}
                changeQuestionSetName={changeQuestionSetName}
                questionText={searchParams.questionText}
                changeQuestionText={changeQuestionText}
                questionType={createReactSelectDto(
                    searchParams.questionTypeId!,
                    QuestionTypes[searchParams.questionTypeId!]
                )}
                questionTypes={questionTypesArray}
                changeQuestionType={changeQuestionType}
                questionCategories={lookupQuestionCategoriesData}
                changeQuestionCategory={changeQuestionCategory}
                answerTypes={lookupAnswerTypesData}
                changeAnswerType={changeAnswerType}
                search={search}
                resetFilter={resetFilter}
                areQuestionCategoriesLoading={isQueryLoading(lookupQuestionCategories)}
                setQuestionCategorySearchText={setSearchDto}
                areAnswerTypesLoading={isQueryLoading(lookupAnswerTypes)}
                setAnswerTypeSearchText={setAnswerTypeSearchDto}
            />

            {isQuerySuccessful(questions) && (
                <>
                    <DataTable
                        keyPrefix={ChecklistQuestions}
                        columns={associateChildQuestionsColumnNames}
                        rows={questions.data!.rows}
                        linkItem={navigateToViewQuestion}
                        totalItems={questions.data!.recordCount}
                        paginationDto={filterDto}
                        setPaginationDto={setFilterDto}
                        nestedColumnNames={nestedQuestionColumnNames}
                        deleteNestedItem={(nestedQuestionId: number): void => {
                            navigate(
                                `${getPath(
                                    AccordionTitles.Questions
                                )}/${nestedQuestionId}/dissociate-nested-question`
                            );
                        }}
                        linkNestedItem={navigateToNestedQuestion}
                        editNestedItem={navigateToEditNestedQuestionTrigger}
                        setRows={setRows}
                        selectItem={(childQuestionId: number): void => {
                            setChildQuestionToAssociate(
                                new AssociateChildQuestionDto(
                                    parentQuestionId,
                                    childQuestionId,
                                    questionIdsThatTriggerWhenParentDesirable?.find(
                                        (x) => x === childQuestionId
                                    )
                                        ? true
                                        : false
                                )
                            );
                        }}
                        radioSelect={{ label: "", type: "radio" }}
                        handleColumnCheckBox={handleTriggerWhenParentDesirableCheckBox}
                    />
                    <SectionVerticalSpace />

                    <EndAlignedDiv>
                        <Col sm={"auto"}>
                            <AssociateButton
                                onClick={handleAssociateChilQuestion}
                                disabled={!childQuestionToAssociate}
                            />
                        </Col>
                        <Col sm={"auto"}>
                            <CancelButton onClick={() => navigate(-1)} />
                        </Col>
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default AssociateChildQuestionContainer;
