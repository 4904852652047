import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { RoleResponse } from "../../responses/users/role-response";

export interface RoleDto {
    roleId: number;
    name: string;
}

export const toRoleDto = (response: RoleResponse): RoleDto => ({
    roleId: response.roleId,
    name: response.name,
});

export const toRoleDtoFromResponse = (response: Response<RoleResponse>): RoleDto => {
    const responseData = response.data;

    return {
        roleId: responseData.roleId,
        name: responseData.name,
    };
};

export const toRoleDtos = (response: Response<PaginationResponse<RoleResponse>>): RoleDto[] =>
    response.data.results.map((x) => toRoleDto(x));
