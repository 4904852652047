import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterChecklistsByNode } from "../../../data/services/checklists/checklist-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { createFilterByNodeRequestFromPaginationDto } from "../../requests/common/filter-by-node-request";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";

export const useGetAssignedChecklistsToNode = (
    nodeId: number | null,
    assignedChecklistsPaginationDto: PaginationDto,
): UseQueryResult<PaginatedTableDto<number>, HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterChecklistsByNode", nodeId, assignedChecklistsPaginationDto],
        () =>
            filterChecklistsByNode(
                url.baseUrl,
                createFilterByNodeRequestFromPaginationDto(nodeId!, assignedChecklistsPaginationDto)
            ),
        {
            enabled: nodeId !== null,
            keepPreviousData: true,
            select: (response: Response<PaginationResponse<ChecklistResponse>>) =>
                transformToChecklistDataTableRows(response.data, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

const transformToChecklistDataTableRows = (
    response: PaginationResponse<ChecklistResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: x.checklistId,
        columns: [
            {
                value: x.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (checklistId: number) =>
                    navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
