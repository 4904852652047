import { ActionPlanStatusDto, createActionPlanStatusDto } from "./action-plan-status-dto";

const allActionPlanStatusOption = createActionPlanStatusDto(-1, "All");

export interface FilterActionPlansDto {
    name: string | null;
    actionPlanStatus: ActionPlanStatusDto;
    actionPlanStatusId: number;
}

export const createFilterActionPlansDto = (
    name: string | null,
    actionPlanStatus: ActionPlanStatusDto,
    actionPlanStatusId: number
): FilterActionPlansDto => ({
    name: name,
    actionPlanStatus: actionPlanStatus,
    actionPlanStatusId: actionPlanStatusId,
});

export const defaultFilterActionPlansDto: FilterActionPlansDto = createFilterActionPlansDto(
    null,
    allActionPlanStatusOption,
    0
);
