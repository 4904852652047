import { Form } from "react-bootstrap";
import styled from "styled-components";
import { StyledDisabledProps } from "../../../core/theme/global-styles";
import { getNullableString } from "../../../core/utilities/string-helper";
import { BaseSbFieldProps } from "../../props/input/InputFieldProps";

const StyledFormControl = styled(Form.Control)<StyledDisabledProps>`
    pointer-events: ${(props) => (props.$disabledStyling ? "none" : "auto")};
    background-color: ${(props) => props.$disabledStyling && props.theme.palette.disableGrey};
`;

export interface SbNumberBoxProps extends BaseSbFieldProps {
    value?: string;
    defaultValue?: string | number | null;
    minValue?: number;
    maxValue?: number;
    onInputChange?: (_: number | null) => void;
    readonly?: boolean;
    disabledStyling?: boolean;
}

const SbNumberBox: React.FC<SbNumberBoxProps> = ({
    name,
    value,
    defaultValue,
    placeholder,
    minValue,
    maxValue,
    onInputChange,
    required,
    disabled,
    readonly,
    disabledStyling
}) => (
    <StyledFormControl
        name={name}
        type={"number"}
        key={defaultValue}
        value={value}
        defaultValue={defaultValue ?? undefined}
        placeholder={placeholder}
        min={minValue && minValue}
        max={maxValue && maxValue}
        required={required}
        onChange={(event: { target: { value: any } }) => {
            if (onInputChange) {
                const input = event.target.value;

                onInputChange(getNullableString(input) ? Math.floor(Number(input)) : null);
            }
        }}
        disabled={disabled}
        readOnly={readonly}
        $disabledStyling={disabledStyling}
        onWheel={(e) => e.currentTarget.blur()}
    />
);

export default SbNumberBox;
