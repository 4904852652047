import { ReactivateOnHoldActionItemDto } from "../../dtos/action-items/reactivate-on-hold-action-item-dto";

export class ReactivateOnHoldActionItemRequest {
    actionItemInstanceId: number;
    comment: string;

    public constructor(dto: ReactivateOnHoldActionItemDto) {
        this.actionItemInstanceId = dto.actionItemInstanceId;
        this.comment = dto.comment;
    }
}
