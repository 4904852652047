import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    Common,
} from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const ActionItemDynamicFieldsFilter: React.FC<{
    name?: string | null;
    changeName?: (name: string) => void;
    actionItemType: ActionItemTypeDto;
    actionItemTypes: ActionItemTypeDto[];
    changeActionItemType: (option: SingleValue<ActionItemTypeDto>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    name,
    changeName,
    actionItemType,
    actionItemTypes,
    changeActionItemType,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    {(name !== undefined || changeName !== undefined) && (
                        <Col>
                            <FilterInput
                                title={t("Name", { keyPrefix: Common })}
                                name={"name"}
                                type={"text"}
                                onChangeEventHandler={changeName!}
                                value={name ?? ""}
                            />
                        </Col>
                    )}
                    <Col>
                        <FilterSelect
                            name={"actionItemTypes"}
                            label={`${t("ActionItemType", { keyPrefix: ActionItemTypes })}`}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={actionItemTypes}
                            itemDisplayText={(option: ActionItemTypeDto) => {
                                if (option.value === "All") {
                                    return t("All", { keyPrefix: Common });
                                }
                                return option.value;
                            }}
                            onChange={changeActionItemType}
                            value={actionItemType}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
