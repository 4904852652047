import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { ActionPlans, Common } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
} from "../../../core/theme/global-styles";
import { ActionPlanStatusDto } from "../../../domain/dtos/actions/action-plan-status-dto";
import { ResetButton, SearchButton } from "../../atoms/SbButton";
import { FilterInput } from "../../molecules/FilterInput";
import { FilterSelect } from "../../molecules/FilterSelect";

export const ActionPlanFilter: React.FC<{
    actionPlanName: string | null;
    changeActionPlanName: (actionPlanName: string) => void;
    actionPlanStatus: ActionPlanStatusDto;
    actionPlanStatuses: ActionPlanStatusDto[];
    changeActionPlanStatus: (option: SingleValue<ActionPlanStatusDto>) => void;
    search: () => void;
    resetFilter: () => void;
}> = ({
    actionPlanName,
    changeActionPlanName,
    actionPlanStatus,
    actionPlanStatuses,
    changeActionPlanStatus,
    search,
    resetFilter,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <FilterInput
                            title={t("Name")}
                            name={"actionPlanName"}
                            type={"text"}
                            onChangeEventHandler={changeActionPlanName}
                            value={actionPlanName ?? ""}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"actionPlanStatus"}
                            label={t("ActionPlanStatus", { keyPrefix: ActionPlans })}
                            placeholderText={""}
                            searchable
                            clearable={false}
                            items={actionPlanStatuses}
                            itemDisplayText={(option: ActionPlanStatusDto) => option.name} //TODO: Add translations for dynamic data
                            onChange={changeActionPlanStatus}
                            value={actionPlanStatus}
                        />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <SearchButton onClick={search} />
                <ResetButton onClick={resetFilter} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};
