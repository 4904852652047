import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { Common, Hierarchy } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { hierarchyImportColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { createPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    defaultFilterHierarchyImportDto,
    FilterHierarchyImportDto,
} from "../../../domain/dtos/hierarchy/filter-hierarchy-import-dto";
import {
    emptySearchHierarchyImportDto,
    SearchHierarchyImportDto,
} from "../../../domain/dtos/hierarchy/search-hierarchy-import-dto";
import { useFilterHierarchyImport } from "../../../domain/viewmodels/hierarchy/view-hierarchy-import-viewmodel";
import { SbButton } from "../../atoms/SbButton";
import { CreateLink } from "../../atoms/SbLink";
import { DataTable } from "../../organisms/DataTable";
import { HierarchyImportFilter } from "../../organisms/filters/HierarchyImportFilter";

const HierarchyImportContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchHierarchyImportDto>(
        emptySearchHierarchyImportDto()
    );
    const [filterDto, setFilterDto] = useState<FilterHierarchyImportDto>(
        defaultFilterHierarchyImportDto
    );
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const toast = useToast();
    const { t } = useTranslation("translation", { keyPrefix: Hierarchy });

    const filterHierarchyImport = useFilterHierarchyImport(
        filterDto,
        createPaginationDto(
            paginationDto.pageNumber,
            paginationDto.pageSize,
            filterDto.sortByColumn,
            filterDto.sortByDescending
        )
    );
    const filterHierarchyImportResponseData = filterHierarchyImport.data;

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(isQueryLoading(filterHierarchyImport), HierarchyImportContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.HierarchyImport);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey, { keyPrefix: Common })]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const changeFileName = (fileName: string): void => {
        setSearchParams({ ...searchParams, fileName: fileName ? fileName : null });
    };

    const changeDateTimeFrom = (dateTimeFrom: Date): void => {
        setSearchParams({ ...searchParams, dateTimeFrom: dateTimeFrom ? dateTimeFrom : null });
    };

    const changeDateTimeTo = (dateTimeTo: Date): void => {
        setSearchParams({ ...searchParams, dateTimeTo: dateTimeTo ? dateTimeTo : null });
    };

    const changeCreatedBy = (createdBy: string): void => {
        setSearchParams({ ...searchParams, createdBy: createdBy ? createdBy : null });
    };

    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setPaginationDto({
            ...paginationDto,
            ...defaultBasePaginationDto,
        });
        setSearchParams(emptySearchHierarchyImportDto());
        setFilterDto(defaultFilterHierarchyImportDto);
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("HierarchyImport")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(filterHierarchyImport) && (
                <>
                    <HierarchyImportFilter
                        fileName={searchParams.fileName}
                        changeFileName={changeFileName}
                        dateTimeFrom={searchParams.dateTimeFrom}
                        changeDateTimeFrom={changeDateTimeFrom}
                        dateTimeTo={searchParams.dateTimeTo}
                        changeDateTimeTo={changeDateTimeTo}
                        createdBy={searchParams.createdBy}
                        changeCreatedBy={changeCreatedBy}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <CreateLink
                            label={t("Import", { keyPrefix: Common })}
                            navigateTo={`${getPath(AccordionTitles.HierarchyImport)}/create`}
                        />
                        <SbButton
                            variant="primary"
                            type="button"
                            label={t("DownloadTemplate", { keyPrefix: Common })}
                            icon={FaDownload}
                            onClick={() => window.location.replace("HierarchyImportTemplate.csv")}
                        />
                        <SbButton
                            variant="primary"
                            type="button"
                            label={t("Refresh", { keyPrefix: Common })}
                            icon={FiRefreshCcw}
                            onClick={() => queryClient.invalidateQueries(["filterHierarchyImport"])}
                        />
                    </EndAlignedDiv>
                    <LargeVerticalSpace />

                    <DataTable
                        columns={hierarchyImportColumnNames}
                        rows={filterHierarchyImportResponseData!.rows}
                        totalItems={filterHierarchyImportResponseData!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default HierarchyImportContainer;
