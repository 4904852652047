import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegBell } from "react-icons/fa";
import { VscTriangleLeft, VscTriangleRight } from "react-icons/vsc";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChecklistQuestions, Common, Reports } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { TableRow } from "../../../core/utilities/customTypes";
import {
    questionSetInstanceAnsweDetailsColumnNames,
    questionSetInstanceChildAnsweDetailsColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { createBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { EditQuestionSetInstanceReviewRatingDto } from "../../../domain/dtos/review-reports/edit-question-set-instance-review-rating-dto";
import { RemoveResolveReviewFindingDto } from "../../../domain/dtos/review-reports/remove-review-finding-dto";
import { ReviewReportChecklistNavigation } from "../../../domain/enums/review-reports/review-checklist-navigation";
import ReviewRating from "../../../domain/enums/review-reports/review-ratings";
import ReviewStatus from "../../../domain/enums/review-reports/review-status";
import { hasRoleTypeInGroup, Role } from "../../../domain/enums/Roles";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useEditQuestionSetInstanceReviewRating,
    useGetQuestionSetInstanceDetails,
    useNotifyAll,
    useRemoveReviewFinding,
    useResolveReviewFinding,
    useSubmitAndPublishItems,
    useValidateQuestionSetInstanceAnswerDetails,
} from "../../../domain/viewmodels/review-reports/view-question-set-instance-review-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import { BackButton, SaveButton, SbButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import SbToolTipButton from "../../atoms/SbToolTipButton";
import { ComponentPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";
import { StyledFormLabelLeft } from "./ViewReviewReportSetContainer";

const QuestionSetInstanceReviewContainer: React.FC = () => {
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    let questionSetInstanceId = Number(urlSearchParams.get("questionSetInstanceId"));
    let reviewReportSetId = Number(urlSearchParams.get("reviewReportSetId"));
    const reviewRatings = getEnumsForType(ReviewRating);

    const menu = useMenu();
    const navigate = useNavigate();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);
    const { t } = useTranslation("translation", { keyPrefix: Reports });

    const questionSetInstance = useGetQuestionSetInstanceDetails(
        questionSetInstanceId,
        createBasePaginationDto(1, 1000),
        reviewReportSetId
    );

    const validateQuestionSetInstanceAnswerDetails = useValidateQuestionSetInstanceAnswerDetails();
    const removeReviewFinding = useRemoveReviewFinding();
    const resolveReviewFinding = useResolveReviewFinding();
    const submitAndPublishItems = useSubmitAndPublishItems();
    const notifyAll = useNotifyAll();
    const editQuestionSetInstanceReviewRating = useEditQuestionSetInstanceReviewRating();

    const questionSetInstanceDetails = questionSetInstance[0];
    const answerDetails = questionSetInstance[1];
    const questionSetInstanceReviews = questionSetInstance[2];
    const questionSetInstanceReviewChecklists = questionSetInstance[3];

    let totalChecklists = questionSetInstanceReviewChecklists.data?.length;

    const [rows, setRows] = useState<TableRow<number>[]>([]);

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(
        areQueriesLoading([
            questionSetInstanceDetails,
            answerDetails,
            questionSetInstanceReviews,
        ]) ||
            areMutationsLoading([
                removeReviewFinding,
                resolveReviewFinding,
                submitAndPublishItems,
                validateQuestionSetInstanceAnswerDetails,
                editQuestionSetInstanceReviewRating,
                notifyAll,
            ]),
        QuestionSetInstanceReviewContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useEffect(() => {
        questionSetInstanceId = Number(urlSearchParams.get("questionSetInstanceId"));
        reviewReportSetId = Number(urlSearchParams.get("reviewReportSetId"));
    }, [rows]);

    useEffect(() => {
        if (answerDetails.fetchStatus === "idle" && answerDetails.status === "success") {
            const answerDetailsDataRows = answerDetails.data!.rows;

            setRows(answerDetailsDataRows);
        }
    }, [answerDetails.fetchStatus, answerDetails.status]);

    const handleRemoveReviewFinding = (
        event: React.FormEvent<HTMLFormElement>,
        questionSetInstanceAnswerId: number
    ): void => {
        const questionSetInstanceReviewId = answerDetails.data!.rows.find(
            (x) => x.metadata == questionSetInstanceAnswerId
        )?.secondaryMetadata;

        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const comment = formData.get(`deleteItemComment ${questionSetInstanceAnswerId}`) as string;

        questionSetInstanceReviewId &&
            removeReviewFinding.mutate(
                new RemoveResolveReviewFindingDto(
                    questionSetInstanceAnswerId,
                    questionSetInstanceReviewId,
                    comment
                ),
                {
                    onSuccess: async (_: Response<boolean>) => {
                        toast.addToast(
                            createSuccessToastProps([t("FindingRemoved", { keyPrefix: Common })])
                        );
                        questionSetInstance.forEach((x) => x.refetch());
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
    };

    const handleResolveReviewFinding = (
        event: React.FormEvent<HTMLFormElement>,
        questionSetInstanceAnswerId: number
    ): void => {
        const questionSetInstanceReviewId = answerDetails.data!.rows.find(
            (x) => x.metadata == questionSetInstanceAnswerId
        )?.secondaryMetadata;

        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const comment = formData.get(`resolveItemComment ${questionSetInstanceAnswerId}`) as string;

        questionSetInstanceReviewId &&
            resolveReviewFinding.mutate(
                new RemoveResolveReviewFindingDto(
                    questionSetInstanceAnswerId,
                    questionSetInstanceReviewId,
                    comment
                ),
                {
                    onSuccess: async (_: Response<boolean>) => {
                        toast.addToast(
                            createSuccessToastProps([t("Resolved", { keyPrefix: Common })])
                        );
                        questionSetInstance.forEach((x) => x.refetch());
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
    };

    const handleNotifyAll = (): void => {
        notifyAll.mutate(questionSetInstanceId, {
            onSuccess: async (_: Response<boolean>) => {
                toast.addToast(
                    createSuccessToastProps([
                        t("SuccessfullySentNotification", { keyPrefix: Common }),
                    ])
                );
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleSubmitAndPublishItems = (): void => {
        submitAndPublishItems.mutate(questionSetInstanceId, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "SuccessfullyPublishedReview"),
                ];

                navigateSearch(`${getPath(DrawerTitles.Reviews)}/${reviewReportSetId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleChangeInReviewFindingRating = (reviewRating: ReviewRating): void => {
        editQuestionSetInstanceReviewRating.mutate(
            new EditQuestionSetInstanceReviewRatingDto(questionSetInstanceId, reviewRating),
            {
                onSuccess: async (_: Response<boolean>) => {
                    toast.addToast(
                        createSuccessToastProps([t("RatingUpdated", { keyPrefix: Common })])
                    );
                    questionSetInstanceDetails.refetch();
                    questionSetInstanceReviews.refetch();
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const navigateToPreviewItems = (): void => {
        validateQuestionSetInstanceAnswerDetails.mutate(questionSetInstanceId, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter(
                        "questionSetInstanceId",
                        questionSetInstanceId.toString()
                    ),
                    createNavigateSearchParameter(
                        "reviewReportSetId",
                        reviewReportSetId.toString()
                    ),
                ];

                navigateSearch(`${getPath(DrawerTitles.Reviews)}/preview-items`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const isResolvedItem = (questionSetInstanceAnswerId: number): boolean => {
        const reviewStatus = questionSetInstanceReviews.data!.find(
            (x) => x.questionSetInstanceAnswerId === questionSetInstanceAnswerId
        )?.reviewStatus;

        return reviewStatus === ReviewStatus.Resolved;
    };

    const getChecklistIndex = (): number | undefined =>
        questionSetInstanceReviewChecklists.data?.findIndex(
            (x) => x.questionSetInstanceId === questionSetInstanceId
        );

    const getChecklistPosition = (): number | undefined => {
        let index = getChecklistIndex();

        if (index !== undefined && index >= 0) {
            index = index + 1;
        }

        return index;
    };

    const getToolTip = (reviewChecklistNavigation: ReviewReportChecklistNavigation): string => {
        let position = getChecklistPosition();

        if (
            reviewChecklistNavigation === ReviewReportChecklistNavigation.Next &&
            totalChecklists &&
            position &&
            position < totalChecklists
        ) {
            return `${t("NextChecklist")}: ${
                questionSetInstanceReviewChecklists.data![getChecklistIndex()! + 1].checklistName
            }`;
        }

        if (
            reviewChecklistNavigation === ReviewReportChecklistNavigation.Previous &&
            totalChecklists &&
            position &&
            position > 1
        ) {
            return `${t("PreviousChecklist")}: ${
                questionSetInstanceReviewChecklists.data![getChecklistIndex()! - 1].checklistName
            }`;
        }

        return "";
    };

    const getQuestionSetInstanceId = (navigate: string): number => {
        if (navigate === ReviewReportChecklistNavigation.Next) {
            return questionSetInstanceReviewChecklists.data![getChecklistIndex()! + 1]
                .questionSetInstanceId;
        }

        return questionSetInstanceReviewChecklists.data![getChecklistIndex()! - 1]
            .questionSetInstanceId;
    };

    const navigateToViewReviewFindingsDetail = (questionSetInstanceAnswerId: number) =>
        navigate(
            `${getPath(
                DrawerTitles.Reviews
            )}/questionSetInstanceAnswer/${questionSetInstanceAnswerId}`
        );

    const navigateToViewQuestionSetInstanceReviewDetails = (
        questionSetInstanceId: number
    ): void => {
        const params = [
            createNavigateSearchParameter(
                "questionSetInstanceId",
                questionSetInstanceId.toString()
            ),
            createNavigateSearchParameter("reviewReportSetId", reviewReportSetId.toString()),
        ];

        navigateSearch(`${getPath(DrawerTitles.Reviews)}/question-set-instance-review`, params);
    };

    return (
        <>
            <PageHeading>{t("ReviewFindings")}</PageHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([
                questionSetInstanceDetails,
                answerDetails,
                questionSetInstanceReviews,
            ]) && (
                <>
                    <ComponentPanel>
                        <Form.Group as={Row} className="mb-3">
                            <StyledFormLabelLeft column sm={2}>
                                <SbLabelText
                                    label={t("AssignedNode", { keyPrefix: ChecklistQuestions })}
                                />
                            </StyledFormLabelLeft>
                            <Col sm={3}>
                                <DetailsValue>
                                    {
                                        questionSetInstanceDetails.data!.assignedNode.nodeTypeValue
                                            .value
                                    }
                                </DetailsValue>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <StyledFormLabelLeft column sm={2}>
                                <SbLabelText label={t("Path", { keyPrefix: Common })} />
                            </StyledFormLabelLeft>
                            <Col sm={10}>
                                <DetailsValue>
                                    {questionSetInstanceDetails.data!.assignedNode.path}
                                </DetailsValue>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <StyledFormLabelLeft column sm={2}>
                                <SbLabelText label={t("Rating", { keyPrefix: Common })} />
                            </StyledFormLabelLeft>
                            <Col sm={3}>
                                <SbSelect
                                    name={"rating"}
                                    searchable={false}
                                    clearable={false}
                                    styles={maxContentWidthSelectStyle}
                                    itemLabel={(option: ReactSelectDto<ReviewRating>) =>
                                        t(option.label, { keyPrefix: Common })
                                    }
                                    itemValue={(option: ReactSelectDto<ReviewRating>) =>
                                        option.label
                                    }
                                    items={reviewRatings.map((x) =>
                                        createReactSelectDto(x, ReviewRating[x])
                                    )}
                                    defaultSelectedItem={createReactSelectDto(
                                        questionSetInstanceDetails.data!.reviewRating,
                                        ReviewRating[questionSetInstanceDetails.data!.reviewRating]
                                    )}
                                    onChange={(option): void => {
                                        handleChangeInReviewFindingRating(option!.value);
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </ComponentPanel>
                    <SectionVerticalSpace />

                    <DataTable
                        noResultsMessage={`${t("NoFindings", {
                            keyPrefix: Reports,
                        })}`}
                        rows={rows}
                        keyPrefix={Reports}
                        columns={questionSetInstanceAnsweDetailsColumnNames}
                        deleteItem={() => {}}
                        setRows={setRows}
                        handleUpdatesToRows={() => {}}
                        nestedColumnNames={questionSetInstanceChildAnsweDetailsColumnNames}
                        handleDeleteItemComment={(event, questionSetInstanceAnswerId) =>
                            handleRemoveReviewFinding(event, questionSetInstanceAnswerId)
                        }
                        resolvedItem={(questionSetInstanceAnswerId) =>
                            isResolvedItem(questionSetInstanceAnswerId)
                        }
                        handleResolveItemComment={(event, questionSetInstanceAnswerId) =>
                            handleResolveReviewFinding(event, questionSetInstanceAnswerId)
                        }
                        viewItem={navigateToViewReviewFindingsDetail}
                        linkItem={navigateToViewReviewFindingsDetail}
                        setPaginationDto={setPaginationDto}
                        paginationDto={paginationDto}
                        totalItems={answerDetails.data!.recordCount}
                    />
                    <SectionVerticalSpace />

                    <EndAlignedDiv>
                        <SbButton
                            variant="secondary"
                            type={"button"}
                            hoverVariant="border"
                            label={t("NotifyAll", { keyPrefix: Common })}
                            onClick={() => handleNotifyAll()}
                            icon={FaRegBell}
                        />

                        {questionSetInstanceReviews.data!.filter(
                            (x) =>
                                x.reviewStatus == ReviewStatus.PrePublish ||
                                x.reviewStatus == ReviewStatus.Resolved
                        ).length === questionSetInstanceReviews.data!.length &&
                        questionSetInstanceReviews.data!.filter(
                            (x) => x.reviewStatus == ReviewStatus.PrePublish
                        ).length > 0 ? (
                            <SaveButton
                                type={"button"}
                                label={t("Publish", { keyPrefix: ChecklistQuestions })}
                                onClick={handleSubmitAndPublishItems}
                                disabled={!hasRoleTypeInGroup(auth.userRoles, [Role.Monitoring])}
                            />
                        ) : (
                            <SaveButton
                                type={"button"}
                                label={t("PreviewCategory", { keyPrefix: ChecklistQuestions })}
                                onClick={() => navigateToPreviewItems()}
                            />
                        )}

                        <BackButton
                            onClick={() =>
                                navigate(`${getPath(DrawerTitles.Reviews)}/${reviewReportSetId}`)
                            }
                        />

                        <SbToolTipButton
                            onClick={() =>
                                navigateToViewQuestionSetInstanceReviewDetails(
                                    getQuestionSetInstanceId(
                                        ReviewReportChecklistNavigation.Previous
                                    )
                                )
                            }
                            toolTip={getToolTip(ReviewReportChecklistNavigation.Previous)}
                            label={t("PreviousChecklist")}
                            icon={VscTriangleLeft}
                            disabled={getChecklistPosition() === 1}
                            variant="secondary"
                        />

                        <SbToolTipButton
                            onClick={() =>
                                navigateToViewQuestionSetInstanceReviewDetails(
                                    getQuestionSetInstanceId(ReviewReportChecklistNavigation.Next)
                                )
                            }
                            toolTip={getToolTip(ReviewReportChecklistNavigation.Next)}
                            label={t("NextChecklist")}
                            icon={VscTriangleRight}
                            iconPosition="right"
                            disabled={getChecklistPosition() === totalChecklists}
                            variant="secondary"
                        />
                    </EndAlignedDiv>
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <DetailsLabel>
                            {`${t("Checklist", { keyPrefix: ChecklistQuestions })} 
                            ${getChecklistPosition()} 
                            ${t("Of", { keyPrefix: ChecklistQuestions })}  ${totalChecklists}`}
                        </DetailsLabel>
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default QuestionSetInstanceReviewContainer;
