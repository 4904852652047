import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AllowedExtensionsForActionPlan } from "../../../core/constants/FileExtension";
import { ActionItems, ActionPlans, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../../core/store/toast-context";
import { StyledBorderedSection } from "../../../core/theme/global-styles";
import { getBase64FileString } from "../../../core/utilities/file-helper";
import {
    areMutationsLoading,
    areQueriesLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import NestedQuestionAnswerDto from "../../../domain/dtos/answer-capture/nested-question-answer-dto";
import QuestionAnswerDto from "../../../domain/dtos/answer-capture/question-answer-dto";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import { FileDto } from "../../../domain/dtos/file-storage/file-dto";
import QuestionAnswerFileUploadDto from "../../../domain/dtos/question-set-instance-answers/question-answer-file-upload-dto";
import { useAnswerCapture } from "../../../domain/stores/answer-capture-context";
import {
    useDownloadFile,
    useFilterFiles,
    useSoftDeleteFile,
    useUploadFiles,
} from "../../../domain/viewmodels/answer-capture/question-capture-file-details-viewmodel";
import { FileGallery } from "../../organisms/FileGallery";

const QuestionCaptureFileDetailsContainer: React.FC<{
    questionAnswerInstanceDto: QuestionAnswerInstanceDto;
    isNestedQuestion: boolean;
}> = ({ questionAnswerInstanceDto, isNestedQuestion }) => {
    const [questionSetInstanceAnswerStagingId, setQuestionSetInstanceAnswerStagingId] = useState<
        number | null
    >(null);
    const [
        questionSetInstanceAnswerEntityFileIdForDownload,
        setQuestionSetInstanceAnswerEntityFileIdForDownload,
    ] = useState<number | null>(null);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);

    const [savedFiles, setSavedFiles] = useState<FileDto[] | undefined>();

    const answerCapture = useAnswerCapture();
    const toast = useToast();
    const { t } = useTranslation("translation");
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const questionSetInstanceAnswerId = questionAnswerInstanceDto.questionSetInstanceAnswerId;

    const filterFiles = useFilterFiles(
        isNestedQuestion,
        isNestedQuestion ? questionSetInstanceAnswerStagingId : questionSetInstanceAnswerId,
        fileUploaded
    );
    const downloadFile = useDownloadFile(
        isNestedQuestion,
        questionSetInstanceAnswerEntityFileIdForDownload
    );
    const uploadFiles = useUploadFiles(isNestedQuestion);
    const softDeleteFile = useSoftDeleteFile(isNestedQuestion);

    useLoader(
        areQueriesLoading([filterFiles, downloadFile]) ||
            areMutationsLoading([uploadFiles, softDeleteFile]),
        QuestionCaptureFileDetailsContainer
    );

    useEffect(() => {
        let savedFileDtos: FileDto[];
        if (isNestedQuestion) {
            const nestedQuestionAnswerDto = answerCapture.getQuestionAnswer(
                questionAnswerInstanceDto
            ) as NestedQuestionAnswerDto;
            savedFileDtos = nestedQuestionAnswerDto?.questionSetInstanceAnswerStagingFileDtos;

            if (nestedQuestionAnswerDto) {
                setQuestionSetInstanceAnswerStagingId(
                    nestedQuestionAnswerDto.questionSetInstanceAnswerStagingId
                );
            }
        } else {
            savedFileDtos = (
                answerCapture.getQuestionAnswer(questionAnswerInstanceDto) as QuestionAnswerDto
            )?.questionSetInstanceAnswerFileDtos;
        }

        setSavedFiles(savedFileDtos);
    }, [questionAnswerInstanceDto]);

    const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        const file = selectedFiles?.[0];

        const fileBase64String = await getBase64FileString(file);

        if (fileBase64String === null) {
            toast.addToast(
                createErrorToastProps([t("FailedToReadFile", { keyPrefix: ActionPlans })])
            );
        } else {
            if (
                (isNestedQuestion && !questionSetInstanceAnswerStagingId) ||
                (!isNestedQuestion && !questionSetInstanceAnswerId)
            ) {
                return;
            }

            uploadFiles.mutate(
                new QuestionAnswerFileUploadDto(
                    isNestedQuestion
                        ? questionSetInstanceAnswerStagingId!
                        : questionSetInstanceAnswerId!,
                    file,
                    fileBase64String
                ),
                {
                    onSuccess: async () => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("EvidenceSuccessfullyUploaded", { keyPrefix: ActionPlans }),
                            ])
                        );

                        if (!fileUploaded) {
                            setFileUploaded(true);
                        } else {
                            queryClient.fetchQuery([
                                isNestedQuestion ? "filterNestedAnswerFiles" : "filterAnswerFiles",
                                isNestedQuestion
                                    ? questionSetInstanceAnswerStagingId
                                    : questionSetInstanceAnswerId,
                            ]);
                        }
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }

        event.target.value = "";
    };

    const onDownloadFile = (questionSetInstanceAnswerEntityFileId: number): void => {
        setQuestionSetInstanceAnswerEntityFileIdForDownload(questionSetInstanceAnswerEntityFileId);
    };

    const onRemoveFile = (questionSetInstanceAnswerEntityFileId: number): void => {
        softDeleteFile.mutate(questionSetInstanceAnswerEntityFileId, {
            onSuccess: async () => {
                toast.addToast(
                    createSuccessToastProps([
                        t("EvidenceSuccessfullyRemoved", { keyPrefix: Common }),
                    ])
                );
                queryClient.fetchQuery([
                    isNestedQuestion ? "filterNestedAnswerFiles" : "filterAnswerFiles",
                    isNestedQuestion
                        ? questionSetInstanceAnswerStagingId
                        : questionSetInstanceAnswerId,
                ]);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    return (
        <StyledBorderedSection>
            <FileGallery
                uploadLabel={t("AttachEvidence", { keyPrefix: ActionItems })}
                filesLabel={t("AttachedEvidence", { keyPrefix: ActionItems })}
                files={filterFiles.data ?? savedFiles}
                onUploadFile={onUploadFile}
                onDownloadFile={onDownloadFile}
                fileDownloadData={downloadFile.data}
                onRemoveFile={onRemoveFile}
                supportedFileExtensions={AllowedExtensionsForActionPlan}
                disabled={isNestedQuestion && !questionSetInstanceAnswerStagingId}
                disabledToolTipText={
                    "Please save this nested question before attempting to attach evidence"
                }
            />
        </StyledBorderedSection>
    );
};

export default QuestionCaptureFileDetailsContainer;
