import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChecklistAnswers } from "../../../core/constants/translation-namespace";
import {
    StyledQuestionCaptureContainer,
    StyledQuestionCaptureHeader,
} from "../../../core/theme/global-styles";
import IndicatorAnswerDto from "../../../domain/dtos/answer-capture/indicator-answer-dto";
import IndicatorQuestionAnswerDto from "../../../domain/dtos/answer-capture/indicator-question-answer-dto";
import NestedQuestionCaptureDto from "../../../domain/dtos/answer-capture/nested-question-capture-dto";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import PredefinedAnswerDto from "../../../domain/dtos/predefined-answers/predefined-answer-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import { useGetIndicatorQuestionAnswerFlag } from "../../../domain/viewmodels/answer-capture/answer-capture-viewmodel";
import QuestionCaptureFileDetailsContainer from "../../templates/answer-capture/QuestionCaptureFileDetailsContainer";
import AnswerCaptureDetailsAndActions from "./question-capture-details-and-actions";

const NestedQuestionCapture: React.FC<{
    isOverDue: boolean;
    nestedQuestionCaptureDto: NestedQuestionCaptureDto;
    parentAnswerFlagMode: AnswerFlagMode | null;
}> = ({ isOverDue, nestedQuestionCaptureDto, parentAnswerFlagMode }) => {
    const { canTriggerActionItem, questionTextDto, questionId } = nestedQuestionCaptureDto;
    const { t } = useTranslation("translation", { keyPrefix: ChecklistAnswers });

    const [answerFlagMode, setAnswerFlagMode] = useState<AnswerFlagMode | null>(null);
    const [indicatorQuestionAnswerDto, setIndicatorQuestionAnswerDto] =
        useState<IndicatorQuestionAnswerDto | null>(null);

    let questionAnswerInstanceDto =
        QuestionAnswerInstanceDto.constructFromNestedQuestionCaptureDto(nestedQuestionCaptureDto);

    const getIndicatorQuestionAnswerFlag = useGetIndicatorQuestionAnswerFlag(
        indicatorQuestionAnswerDto
    );

    useEffect(() => {
        if (
            getIndicatorQuestionAnswerFlag.fetchStatus === "idle" &&
            getIndicatorQuestionAnswerFlag.status === "success"
        ) {
            if (getIndicatorQuestionAnswerFlag.data === null) {
                setAnswerFlagMode(AnswerFlagMode.Neutral);
                return;
            }

            setAnswerFlagMode(getIndicatorQuestionAnswerFlag.data!.indicatorQuestionAnswerFlag);
        }
    }, [getIndicatorQuestionAnswerFlag.fetchStatus]);

    const onMutuallyExclusiveAnswerClicked = (predefinedAnswerDto: PredefinedAnswerDto): void =>
        setAnswerFlagMode(predefinedAnswerDto.answerFlagMode);

    const onIndicatorAnswerChanged = <TType,>(dto: IndicatorAnswerDto<TType>): void => {
        if (dto.value === null) {
            setAnswerFlagMode(AnswerFlagMode.Neutral);
            return;
        }

        setIndicatorQuestionAnswerDto(
            IndicatorQuestionAnswerDto.constructFromIndicatorAnswer(questionId, dto)
        );
    };

    const buildNestedQuestionHeader = (): ReactElement<HTMLParagraphElement> => (
        <StyledQuestionCaptureHeader $variant="secondary">
            {t("AdditionalAnswerRequired")}
        </StyledQuestionCaptureHeader>
    );

    const triggerNestedQuestion = (): boolean => {
        const triggerOnDesirable =
            nestedQuestionCaptureDto.triggerWhenParentIsDesirable &&
            parentAnswerFlagMode === AnswerFlagMode.Desirable;
        const triggerOnUnDesirable =
            !nestedQuestionCaptureDto.triggerWhenParentIsDesirable &&
            parentAnswerFlagMode === AnswerFlagMode.Undesirable;

        return triggerOnDesirable || triggerOnUnDesirable;
    };

    return triggerNestedQuestion() ? (
        <StyledQuestionCaptureContainer $isOverdue={isOverDue}>
            {buildNestedQuestionHeader()}
            <AnswerCaptureDetailsAndActions
                questionText={questionTextDto.text}
                isNestedQuestion
                canTriggerActionItem={canTriggerActionItem}
                questionAnswerInstanceDto={questionAnswerInstanceDto}
                answerTypeDto={nestedQuestionCaptureDto.answerTypeDto}
                isOverdue={isOverDue}
                answerFlagMode={answerFlagMode}
                onMutuallyExclusiveAnswerClicked={onMutuallyExclusiveAnswerClicked}
                onIndicatorAnswerChanged={onIndicatorAnswerChanged}
            >
                <QuestionCaptureFileDetailsContainer
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    isNestedQuestion
                />
            </AnswerCaptureDetailsAndActions>

            {nestedQuestionCaptureDto.nestedQuestionCaptureDtos?.map((x, index) => (
                <NestedQuestionCapture
                    key={index}
                    isOverDue={isOverDue}
                    nestedQuestionCaptureDto={x}
                    parentAnswerFlagMode={answerFlagMode}
                />
            ))}
        </StyledQuestionCaptureContainer>
    ) : (
        <></>
    );
};

export default NestedQuestionCapture;
