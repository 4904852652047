import { DisassociateActionItemDto } from "../../dtos/actions/disassociate-action-item-dto";

export class DisassociateActionItemRequest {
    actionPlanId: number;
    actionItemId: number | null;

    public constructor(dto: DisassociateActionItemDto) {
        const { actionPlanId, actionItemId } = dto;

        this.actionPlanId = actionPlanId;
        this.actionItemId = actionItemId;
    }
}

export const createDisassociateActionItemRequest = (
    actionPlanId: number,
    actionItemId: number | null
): DisassociateActionItemRequest => ({
    actionItemId: actionItemId,
    actionPlanId: actionPlanId,
});
