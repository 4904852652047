import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import styled from "styled-components";
import { Common, Hierarchy, HierarchyTypes, Nodes } from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    SectionContainer,
    StyledFilterTitle,
} from "../../../core/theme/global-styles";
import { HierarchyTypeDto } from "../../../domain/dtos/hierarchy/hierarchy-type-dto";
import { NodeTypeDto } from "../../../domain/dtos/hierarchy/node-type-dto";
import { SbSelect } from "../../atoms/input/SbSelect";
import { FilterInput } from "../../molecules/FilterInput";

const StyledRow = styled(Row)`
    padding-bottom: ${(props) => props.theme.padding.md};
`;

const StyledSpan = styled.span`
    color: ${(props) => props.theme.palette.primary};
    padding-left: 5px;
    font-weight: bold;
`;

export const NodeFilter: React.FC<{
    hierarchyType: HierarchyTypeDto | null;
    hierarchyTypes: HierarchyTypeDto[];
    changeHierarchyType: (option: SingleValue<HierarchyTypeDto>) => void;
    defaultSelectedHierarchyType: HierarchyTypeDto;
    hierarchyLevel: NodeTypeDto | null;
    hierarchyLevels: NodeTypeDto[];
    changeHierarchyLevel: (option: SingleValue<NodeTypeDto>) => void;
    defaultSelectedHierarchyLevel: NodeTypeDto;
    isFilterNodeTypesLoading: boolean;
    searchValue: string | null;
    changeSearchValue: (questionText: string) => void;
}> = ({
    hierarchyType,
    hierarchyTypes,
    changeHierarchyType,
    defaultSelectedHierarchyType,
    hierarchyLevel,
    hierarchyLevels,
    changeHierarchyLevel,
    defaultSelectedHierarchyLevel,
    isFilterNodeTypesLoading,
    searchValue,
    changeSearchValue,
}) => {
    const { t } = useTranslation("translation", { keyPrefix: HierarchyTypes });

    return (
        <ContentContainer>
            <SectionContainer>
                <StyledRow>
                    <Col md={3}>
                        <StyledFilterTitle>
                            {t("HierarchyType")}
                            <StyledSpan>*</StyledSpan>
                        </StyledFilterTitle>
                        <SbSelect
                            name={"HierarchyType"}
                            placeholderText={""}
                            defaultSelectedItem={defaultSelectedHierarchyType}
                            searchable
                            clearable={false}
                            items={hierarchyTypes}
                            itemLabel={(option: HierarchyTypeDto) => option.name}
                            itemValue={(option: HierarchyTypeDto) =>
                                option.hierarchyTypeId.toString()
                            }
                            onChange={(option: SingleValue<HierarchyTypeDto>) => {
                                changeHierarchyType(option);
                            }}
                            value={hierarchyType}
                        />
                    </Col>
                    <Col md={3}>
                        <StyledFilterTitle>
                            {t("HierarchyLevel", { keyPrefix: Hierarchy })}
                            <StyledSpan>*</StyledSpan>
                        </StyledFilterTitle>
                        <SbSelect
                            name={"HierarchyLevel"}
                            placeholderText={""}
                            defaultSelectedItem={defaultSelectedHierarchyLevel}
                            searchable
                            clearable={false}
                            items={hierarchyLevels}
                            itemLabel={(option: NodeTypeDto) => {
                                if (option.name === "All") {
                                    return t("All", { keyPrefix: Common });
                                }
                                return option.name;
                            }}
                            itemValue={(option: NodeTypeDto) => option.nodeTypeId.toString()}
                            onChange={(option: SingleValue<NodeTypeDto>) => {
                                changeHierarchyLevel(option);
                            }}
                            value={hierarchyLevel}
                            disabled={hierarchyType?.hierarchyTypeId == -1}
                            loading={isFilterNodeTypesLoading}
                        />
                    </Col>
                    <Col md={5}>
                        <FilterInput
                            title={t("SearchValue", { keyPrefix: Nodes })}
                            name={"searchValue"}
                            type={"text"}
                            onChangeEventHandler={changeSearchValue}
                            value={searchValue || ""}
                        />
                    </Col>
                </StyledRow>
            </SectionContainer>
        </ContentContainer>
    );
};
