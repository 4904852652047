import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { AccordionLinks, Common } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import { PageHeading, SectionVerticalSpace } from "../../../core/theme/global-styles";
import { reviewReportSetHistoryColumnNames } from "../../../core/utilities/dataTableColumns";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isQueryLoading, isQuerySuccessful } from "../../../core/utilities/responseStateHelper";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { defaultSearchDto, SearchDto } from "../../../domain/dtos/common/search-dto";
import { NodeTypeValueDto } from "../../../domain/dtos/hierarchy/node-type-value-dto";
import { FilterReviewReportSetHistoryDto } from "../../../domain/dtos/review-reports/filter-review-report-set-history-dto";
import ReviewReportSetHistoryDto from "../../../domain/dtos/review-reports/review-report-set-history-dto";
import ReviewRating from "../../../domain/enums/review-reports/review-ratings";
import { useLookupCountryNodeTypeValues } from "../../../domain/viewmodels/hierarchy/text-search-node-viewmodel";
import { useFilterReviewReportSetsHistory } from "../../../domain/viewmodels/review-reports/view-review-report-sets-history-viewmodel";
import { DataTable } from "../../organisms/DataTable";
import { ReviewReportHistoryFilter } from "../../organisms/filters/ReviewReportHistoryFilter";

const ReviewsHistoryContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<FilterReviewReportSetHistoryDto>(
        FilterReviewReportSetHistoryDto.defaultFilterReviewReportSetHistoryDto
    );
    const [filterDto, setFilterDto] = useState<FilterReviewReportSetHistoryDto>(
        FilterReviewReportSetHistoryDto.defaultFilterReviewReportSetHistoryDto
    );
    const [basePaginationDto, setBasePaginationDto] =
        useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();

    const { t } = useTranslation("translation", { keyPrefix: Common });

    const reviewReportSetHistory = useFilterReviewReportSetsHistory(basePaginationDto, filterDto);

    const [searchDto, setSearchDto] = useState<SearchDto>(defaultSearchDto);
    const filterCountryNodeTypeValues = useLookupCountryNodeTypeValues(searchDto);
    const filterCountryNodeTypeValuesData = filterCountryNodeTypeValues.data;

    useLoader(isQueryLoading(reviewReportSetHistory), ReviewsHistoryContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.ReviewsHistory);
    }, []);

    const search = (): void => {
        setBasePaginationDto({
            ...basePaginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const changeEndDate = (endDate: string): void => {
        setSearchParams({
            ...searchParams,
            endDate: endDate ? endDate : null,
        });
    };

    const changeStartDate = (startDate: string): void => {
        setSearchParams({
            ...searchParams,
            startDate: startDate ? startDate : null,
        });
    };

    const changeReviewRating = (option: SingleValue<ReactSelectDto<ReviewRating>>): void => {
        setSearchParams({
            ...searchParams,
            reviewRating: option!,
        });
    };

    const changeCountry = (option: SingleValue<NodeTypeValueDto>): void => {
        setSearchParams({
            ...searchParams,
            country: option!,
        });
    };

    const changeReportSetName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const resetFilter = (): void => {
        setBasePaginationDto({
            ...basePaginationDto,
            ...defaultBasePaginationDto,
        });
        setSearchParams(FilterReviewReportSetHistoryDto.defaultFilterReviewReportSetHistoryDto);
        setFilterDto(FilterReviewReportSetHistoryDto.defaultFilterReviewReportSetHistoryDto);
        setSearchDto(defaultSearchDto);
    };

    const navigateToViewPublishedFiles = (metadata: ReviewReportSetHistoryDto): void =>
        navigate(
            `${getPath(DrawerTitles.ReviewsHistory)}/${metadata.reviewReportSetId}/download-file`
        );

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("ReviewsHistory", { keyPrefix: AccordionLinks })}</PageHeading>
            <SectionVerticalSpace />
            
            <ReviewReportHistoryFilter
                search={search}
                resetFilter={resetFilter}
                reportSetName={searchParams.name}
                changeReportSetName={changeReportSetName}
                reviewRating={searchParams.reviewRating!}
                changeReviewRating={changeReviewRating}
                startDate={searchParams.startDate}
                changeStartDate={changeStartDate}
                endDate={searchParams.endDate}
                changeEndDate={changeEndDate}
                countries={filterCountryNodeTypeValuesData ?? []}
                changeCountry={changeCountry}
                country={searchParams.country}
                setCountrySearchTextChanged={setSearchDto}
            />
            <SectionVerticalSpace />
            {isQuerySuccessful(reviewReportSetHistory) && (
                <DataTable
                    rows={reviewReportSetHistory.data!.rows}
                    columns={reviewReportSetHistoryColumnNames}
                    viewItem={navigateToViewPublishedFiles}
                    totalItems={reviewReportSetHistory.data!.recordCount}
                    paginationDto={basePaginationDto}
                    setPaginationDto={setBasePaginationDto}
                    noResultsMessage={t("NoRecordsFound", { keyPrefix: Common })}
                />
            )}
        </>
    );
};

export default ReviewsHistoryContainer;
