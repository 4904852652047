import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { ActionPlans, Common, Nodes } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { isMutationLoading } from "../../../core/utilities/responseStateHelper";
import { CreateActionPlanDto } from "../../../domain/dtos/actions/create-action-plan-dto";
import { BaseUserDto, createBaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { Response } from "../../../domain/responses/common/response-response";
import { useCreateActionPlan } from "../../../domain/viewmodels/actions/create-action-plan-viewmodel";
import { useFilterUsers } from "../../../domain/viewmodels/hierarchy/node-details-viewmodel";
import { useGetUserDetails } from "../../../domain/viewmodels/users/view-user-details-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextAreaGroup from "../../molecules/input/SbFormikTextAreaGroup";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";

const CreateActionPlanContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const [userSearchText] = useState<string | null>(null);
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });

    const createActionPlan = useCreateActionPlan();
    const filterUsers = useFilterUsers(1, 1000, userSearchText);

    const user = useGetUserDetails(auth.userId!);

    const userDetails = createBaseUserDto(
        Number(user.data?.userId),
        "",
        "",
        user.data?.emailAddress!,
        user.data?.fullName!,
        ""
    );

    useLoader(isMutationLoading(createActionPlan), CreateActionPlanContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionPlans);
    }, []);

    const handleSubmit = (dto: CreateActionPlanDto): void => {
        createActionPlan.mutate(dto, {
            onSuccess: async (response: Response<number>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "CreateActionPlanSuccessMessage"),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionPlans)}/${response.data}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const createActionPlanValidator = (): object => {
        return object({
            name: string().required(t("NameIsRequired", { keyPrefix: Common })).max(100, t("MustBe100CharactersOrLess", { keyPrefix: Common })).required(),
            description: string().required(t("DescriptionRequired", { keyPrefix: Common })).max(1500, t("MustBe1500CharactersOrLess", { keyPrefix: Common })),
            userOwnerId: string().required(t("OwnerIsRequired", { keyPrefix: Common })),
        });
    };

    return (
        <>
            <PageHeading>{t("CreateActionPlan")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextActionPlanCreate")}</PageSubHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateActionPlan")}>
                <Formik
                    initialValues={{ name: "", description: "", userOwnerId: auth.userId! }}
                    onSubmit={handleSubmit}
                    validationSchema={createActionPlanValidator}
                >
                    {({ handleChange, errors, touched }) => (
                        <Form>
                            <SbFormikTextFieldGroup
                                name="name"
                                label={t("Name", { keyPrefix: Common })}
                                type="text"
                                required
                                onFormikChange={handleChange}
                                error={errors.name}
                                touched={touched.name}
                            />

                            <SbFormikTextAreaGroup
                                name="description"
                                label={t("Description", { keyPrefix: Common })}
                                maxLength={1500}
                                required
                                rows={4}
                                error={errors.description}
                                onFormikChange={handleChange}
                                touched={touched.description}
                            />
                            <Row className="mb-3">
                                <SbFormSelectFieldGroup
                                    name={"userOwnerId"}
                                    label={t("Owner", { keyPrefix: Common })!}
                                    placeholderText={t("SearchForUser", { keyPrefix: Nodes })!}
                                    searchable
                                    clearable={false}
                                    required
                                    disabled
                                    items={filterUsers?.data!}
                                    itemDisplayText={(option: BaseUserDto) =>
                                        `${option.fullName} - ${option.emailAddress}`
                                    }
                                    defaultSelectedItem={userDetails}
                                    error={errors.userOwnerId}
                                    touched={touched.userOwnerId}
                                />
                            </Row>
                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    )}
                </Formik>
            </TextTitledPanel>
        </>
    );
};

export default CreateActionPlanContainer;
