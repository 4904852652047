import { CreateActionPlanDto } from "./create-action-plan-dto";

export class EditActionPlanDto extends CreateActionPlanDto {
    actionPlanId: number;

    public constructor(actionPlanId: number, dto: CreateActionPlanDto) {
        const { name, description, userOwnerId } = dto;
        super(name, description, userOwnerId);

        this.actionPlanId = actionPlanId;
        this.userOwnerId = userOwnerId;
    }
}
