import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import {
    ChecklistQuestions,
    Common,
    Reports,
    Users,
} from "../../../core/constants/translation-namespace";
import {
    ContentContainer,
    EndAlignedDiv,
    SectionContainer,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import BaseChecklistDto from "../../../domain/dtos/checklists/base-checklist-dto";
import { BaseNodeDetailsDto } from "../../../domain/dtos/hierarchy/base-node-details-dto";
import FilterChecklistReportSummariesDto from "../../../domain/dtos/reports/filter-checklist-report-summaries-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { ResetButton } from "../../atoms/SbButton";
import DateTimeFilter from "../../molecules/DateTimeFilter";
import { FilterSelect } from "../../molecules/FilterSelect";

const buildDefaultFromDate = (): Date => {
    const dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 3);

    return dateNow;
};

const maxWidthForMenuAndControlSelectStyle = {
    menu: ({ ...css }) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
    }),
    control: ({ ...css }) => ({
        ...css,
        width: "max-content",
        minWidth: "100%",
    }),
};

const ChecklistReportSummaryFilter: React.FC<{
    functionNodes: BaseNodeDetailsDto[];
    users: BaseUserDto[] | undefined;
    checklists: BaseChecklistDto[] | undefined;
    filterChecklistReportSummariesDto: FilterChecklistReportSummariesDto | null;
    onFromDateChanged: (_: Date) => void;
    onToDateChanged: (_: Date) => void;
    onFunctionNodeChanged: (_: SingleValue<BaseNodeDetailsDto>) => void;
    onUserChanged: (_: SingleValue<BaseUserDto>) => void;
    onChecklistChanged: (_: SingleValue<BaseChecklistDto>) => void;
    onResetClicked: () => void;
}> = ({
    functionNodes,
    users,
    checklists,
    filterChecklistReportSummariesDto,
    onFromDateChanged,
    onToDateChanged,
    onFunctionNodeChanged,
    onUserChanged,
    onChecklistChanged,
    onResetClicked,
}) => {
    const [fromDate, setFromDate] = useState<Date | null>(
        filterChecklistReportSummariesDto?.startDateUtc ?? null
    );
    const [toDate, setToDate] = useState<Date | null>(
        filterChecklistReportSummariesDto?.endDateUtc ?? null
    );

    const { t } = useTranslation("translation");

    const getFromDate = (): Date =>
        filterChecklistReportSummariesDto?.startDateUtc ?? buildDefaultFromDate();

    const getToDate = (): Date => filterChecklistReportSummariesDto?.endDateUtc ?? new Date();

    useEffect(() => {
        if (!filterChecklistReportSummariesDto?.startDateUtc && fromDate === null) {
            onFromDateChanged(getFromDate());
        }

        if (!filterChecklistReportSummariesDto?.endDateUtc && toDate === null) {
            onToDateChanged(getToDate());
        }
    }, [
        filterChecklistReportSummariesDto?.startDateUtc,
        filterChecklistReportSummariesDto?.endDateUtc,
        fromDate,
        toDate,
    ]);

    const onReset = (): void => {
        setFromDate(null);
        setToDate(null);

        onResetClicked();
    };

    const getShortenedPath = (fullPath: string): string => {
        const numberOfNodeLevelsToDisplay = 3;
        const splitPath = fullPath.split("|");

        if (splitPath.length <= numberOfNodeLevelsToDisplay) {
            return fullPath;
        }

        const lastThreeNodes = splitPath.slice(
            Math.max(splitPath.length - numberOfNodeLevelsToDisplay, 1)
        );

        return lastThreeNodes.join(" | ");
    };

    return (
        <ContentContainer>
            <SectionContainer>
                <Row>
                    <Col>
                        <DateTimeFilter
                            label={t("ReportDateFrom", { keyPrefix: Reports })}
                            name={"fromDate"}
                            excludeTime
                            onInputChange={onFromDateChanged}
                            value={getFromDate()}
                        />
                    </Col>
                    <Col>
                        <DateTimeFilter
                            label={t("ReportDateTo", { keyPrefix: Reports })}
                            name={"toDate"}
                            excludeTime
                            onInputChange={onToDateChanged}
                            value={getToDate()}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"functionNode"}
                            label={`${t("FunctionPath", { keyPrefix: Common })}`}
                            searchable
                            placeholderText={`${t("Select", { keyPrefix: Common })}...`}
                            clearable={false}
                            items={functionNodes}
                            itemDisplayText={(option: BaseNodeDetailsDto) =>
                                getShortenedPath(option.path)
                            }
                            onChange={onFunctionNodeChanged}
                            value={filterChecklistReportSummariesDto?.nodeDto}
                            customStyles={maxWidthForMenuAndControlSelectStyle}
                        />
                    </Col>
                </Row>
                <SectionVerticalSpace />
                <Row sm={5}>
                    <Col>
                        <FilterSelect
                            name={"user"}
                            label={`${t("User", { keyPrefix: Users })}`}
                            searchable
                            placeholderText={`${t("Select", { keyPrefix: Common })}...`}
                            clearable={false}
                            items={users}
                            itemDisplayText={(option: BaseUserDto) => option.fullName}
                            onChange={onUserChanged}
                            value={filterChecklistReportSummariesDto?.userDto}
                        />
                    </Col>
                    <Col>
                        <FilterSelect
                            name={"checklist"}
                            label={`${t("Checklist", { keyPrefix: ChecklistQuestions })}`}
                            searchable
                            placeholderText={`${t("Select", { keyPrefix: Common })}...`}
                            clearable={false}
                            items={checklists}
                            itemDisplayText={(option: BaseChecklistDto) => option.name}
                            onChange={onChecklistChanged}
                            value={filterChecklistReportSummariesDto?.checklistDto}
                        />
                    </Col>
                </Row>
            </SectionContainer>
            <EndAlignedDiv>
                <ResetButton onClick={onReset} />
            </EndAlignedDiv>
        </ContentContainer>
    );
};

export default ChecklistReportSummaryFilter;
