import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { object, string } from "yup";
import {
    ChecklistQuestions,
    Common,
    Languages,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { alternativeQuestionsTextsColumnNamesAlt } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { LanguageDto } from "../../../domain/dtos/language/language-dto";
import { CreateQuestionTextDto } from "../../../domain/dtos/questions/create-question-text-dto";
import { emptySearchQuestionAssociationRequest } from "../../../domain/requests/questions/search-question-association-request";
import { useCreateAlternativeQuestionText } from "../../../domain/viewmodels/questions/create-alternative-question-text-viewmodel";
import { useGetAlternativeQuestionTexts } from "../../../domain/viewmodels/questions/view-alternative-question-texts-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextAreaGroup from "../../molecules/input/SbFormikTextAreaGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";

const CreateAlternativeQuestionTextContainer: React.FC = () => {
    const [alternativeQuestionTextPaginationDto, setAlternativeQuestionTextPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const questionId = Number(useParams().questionId);
    const searchRequest = {
        ...emptySearchQuestionAssociationRequest(),
        questionId: questionId,
    };

    const alternativeQuestionTexts = useGetAlternativeQuestionTexts(
        alternativeQuestionTextPaginationDto,
        searchRequest
    );
    const alternativeQuestionTextsResponseData = alternativeQuestionTexts[0].data;
    const languagesResponseData = alternativeQuestionTexts[1].data;

    const createAlternativeQuestionText = useCreateAlternativeQuestionText();

    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    useLoader(
        areQueriesLoading(alternativeQuestionTexts) ||
            isMutationLoading(createAlternativeQuestionText),
        CreateAlternativeQuestionTextContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Questions);
    }, []);

    const handleSubmit = (dto: CreateQuestionTextDto): void => {
        createAlternativeQuestionText.mutate(dto, {
            onSuccess: async () => {
                queryClient.invalidateQueries(["getAlternativeQuestionTexts"]);

                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "AddAlternativeQuestionTextSuccessMessage"
                    ),
                ];

                navigateSearch(`${getPath(AccordionTitles.Questions)}/${questionId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const renderAlternativeQuestionTexts = (): JSX.Element => (
        <SbAccordion title={t("AlternativeQuestionTexts", { keyPrefix: ChecklistQuestions })}>
            <DataTable
                noResultsMessage={
                    t("QuestionsNoAlternativeFound", { keyPrefix: ChecklistQuestions })!
                }
                columns={alternativeQuestionsTextsColumnNamesAlt}
                rows={alternativeQuestionTextsResponseData!.rows}
                totalItems={alternativeQuestionTextsResponseData!.recordCount}
                paginationDto={alternativeQuestionTextPaginationDto}
                setPaginationDto={setAlternativeQuestionTextPaginationDto}
            />
        </SbAccordion>
    );

    const renderAddAlternativeQuestionText = (): JSX.Element => (
        <Formik
            initialValues={new CreateQuestionTextDto(questionId, "", null)}
            onSubmit={handleSubmit}
            validationSchema={createQuestionTextSchema}
        >
            {({ handleChange, errors, touched, setFieldValue }) => (
                <Form>
                    <SbAccordion
                        title={t("AddAlternativeQuestionText", { keyPrefix: ChecklistQuestions })}
                    >
                        <SbFormSelectFieldGroup
                            name={"languageId"}
                            label={t("Language", { keyPrefix: Languages })}
                            placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                            searchable
                            clearable={false}
                            required
                            items={languagesResponseData!}
                            itemDisplayText={(option: LanguageDto) => option.name} //TODO: Add translations for dynamic data
                            onChange={(option: SingleValue<LanguageDto>) => {
                                if (option) {
                                    setFieldValue("languageId", option!.languageId);
                                } else {
                                    setFieldValue("languageId", null);
                                }
                            }}
                            error={errors.languageId}
                            touched={touched.languageId}
                        />

                        <SbFormikTextAreaGroup
                            name={"questionText"}
                            label={t("QuestionText", { keyPrefix: ChecklistQuestions })!}
                            required
                            rows={4}
                            maxLength={4000}
                            onFormikChange={handleChange}
                            error={errors.questionText}
                            touched={touched.questionText}
                        />

                        <EndAlignedDiv>
                            <SaveButton type="submit" label={t("Add", { keyPrefix: Common })!} />
                            <CancelButton onClick={() => navigate(-1)} />
                        </EndAlignedDiv>
                    </SbAccordion>
                </Form>
            )}
        </Formik>
    );

    const createQuestionTextSchema = (): object => {
        return object({
            questionText: string().required(t("QuestionTextIsRequired", { keyPrefix: Common })),
            languageId: string().required(t("LanguageIsRequired", { keyPrefix: Common })),
        });
    };

    return (
        <>
            {areQueriesSuccessful(alternativeQuestionTexts) && (
                <>
                    <PageHeading>
                        {t("AddAlternativeQuestionText", { keyPrefix: ChecklistQuestions })}
                    </PageHeading>
                    <PageSubHeading>
                        {t("HeaderHelpTextAlternativeQuestionText", {
                            keyPrefix: ChecklistQuestions,
                        })}
                    </PageSubHeading>
                    <SectionVerticalSpace />

                    {renderAlternativeQuestionTexts()}
                    <SectionVerticalSpace />

                    {renderAddAlternativeQuestionText()}
                    <SectionVerticalSpace />
                </>
            )}
        </>
    );
};

export default CreateAlternativeQuestionTextContainer;
