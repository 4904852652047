import styled from "styled-components";
import { EndAlignedDiv, LargeVerticalSpace, NoDataStateDiv } from "../../core/theme/global-styles";
import { MyActionItemsDto } from "../../domain/dtos/action-items/my-action-items-dto";
import { BasePaginationDto } from "../../domain/dtos/common/base-pagination-dto";
import Paginator from "../atoms/Paginator";
import { ActionItemCard } from "../molecules/ActionItemCard";
import { TextTitledPanel } from "../molecules/SbPanel";
import { useTranslation } from "react-i18next";
import { Common } from "../../core/constants/translation-namespace";

const StyledList = styled.div`
    width: 100%;
`;

export const ActionItemsList: React.FC<{
    title: string;
    actionItems: MyActionItemsDto[];
    recordCount: number;
    paginationDto: BasePaginationDto;
    setPaginationDto: React.Dispatch<React.SetStateAction<BasePaginationDto>>;
}> = ({ title, actionItems, recordCount, paginationDto, setPaginationDto }) => {
    const { t } = useTranslation("translation", { keyPrefix: Common });
    
    return (
        <TextTitledPanel title={title}>
            <StyledList>
                {actionItems &&
                    actionItems.map((actionItem, index) => (
                        <ActionItemCard key={index} actionItem={actionItem} />
                    ))}
            </StyledList>
            <LargeVerticalSpace />

            {recordCount ? (
                <EndAlignedDiv>
                    <Paginator
                        totalItems={recordCount}
                        paginationDto={paginationDto}
                        setPaginationProps={setPaginationDto}
                    />
                </EndAlignedDiv>
            ) : (
                <NoDataStateDiv>{t("NoResultsFound")}</NoDataStateDiv>
            )}
        </TextTitledPanel>
    );
};
