import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
    ActionItems,
    ActionItemTypes,
    ActionPlans,
    Common,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    createErrorToastProps,
    createSuccessToastProps,
    useToast,
} from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
    StyledBorderedSection,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import { convertUtcDateToLocalDate } from "../../../core/utilities/date-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getBase64FileString } from "../../../core/utilities/file-helper";
import { actionItemDynamicFieldValueTypeBooleanDropdownItems } from "../../../core/utilities/filterDropdownItems";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { ActionItemInstanceFileUploadDto } from "../../../domain/dtos/action-items/action-item-instance-file-upload-dto";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { ActionItemImportanceDto } from "../../../domain/dtos/actions/action-item-importance-dto";
import { EditActionItemDto } from "../../../domain/dtos/actions/edit-action-item-dto";
import {
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { ActionItemImportance } from "../../../domain/enums/action-items/ActionItemImportance";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import {
    ActionItemRoleGroup,
    hasRoleTypeInGroup,
    UserRoleGroup,
} from "../../../domain/enums/Roles";
import {
    useDownloadActionItemFile,
    useDownloadQuestionSetInstanceAnswerFile,
    useRemoveActionItemFile,
    useUploadActionItemFile,
} from "../../../domain/viewmodels/action-items/my-action-item-details-viewmodel";
import {
    useEditActionItem,
    useGetActionItemDetails,
} from "../../../domain/viewmodels/actions/edit-action-item-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import SbModal from "../../molecules/SbModal";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { FileGallery } from "../../organisms/FileGallery";

const EditActionItemContainer: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const toast = useToast();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [actionItemTypeNode, setActionItemTypeNode] = useState<ActionItemTypeDto | null>(null);
    const [actionItemSubTypeNode, setActionItemSubTypeNode] = useState<ActionItemTypeDto | null>();
    const [actionItemImportance, setActionItemImportance] = useState<ActionItemImportance | null>(
        null
    );
    const [downloadActionItemInstanceFileId, setDownloadActionItemInstanceFileId] = useState<
        number | null
    >(null);
    const [
        downloadQuestionSetInstanceAnswerFileDetailsId,
        setDownloadQuestionSetInstanceAnswerFileDetailsId,
    ] = useState<number | null>(null);
    const [removeActionItemFileId, setRemoveActionItemFileId] = useState<number | null>(null);
    const [
        showDeleteActionItemFileConfirmationModal,
        setShowDeleteActionItemFileConfirmationModal,
    ] = useState(false);

    const actionItemId = Number(useParams().actionItemId);

    const downloadActionItemFile = useDownloadActionItemFile(downloadActionItemInstanceFileId);
    const downloadQuestionSetInstanceAnswerFile = useDownloadQuestionSetInstanceAnswerFile(
        downloadQuestionSetInstanceAnswerFileDetailsId
    );
    const getActionItemDetails = useGetActionItemDetails(actionItemId, actionItemTypeNode?.nodeId!);

    const getActionItemTypes = getActionItemDetails[0];
    const getActionItemSubTypes = getActionItemDetails[1];
    const getActionItemImportances = getActionItemDetails[2];
    const actionItemDetails = getActionItemDetails[3];
    const getAdditionalFieldValues = getActionItemDetails[4];
    const getAdditionalFields = getActionItemDetails[5];
    const getActionItemInstanceFiles = getActionItemDetails[6];

    const getActionItemTypesResponseData = getActionItemTypes.data;
    const getActionItemSubTypesResponseData = getActionItemSubTypes.data;
    const getActionItemImportancesResponseData = getActionItemImportances.data;
    const actionItemDetailsResponseData = actionItemDetails.data;
    const getExistingAdditionalFieldValuesResponseData = getAdditionalFieldValues.data;
    const getAdditionalFieldsThatMatchTheTypeResponseData = getAdditionalFields.data;
    const getActionItemInstanceFilesResponseData = getActionItemInstanceFiles.data;

    const uploadActionItemFile = useUploadActionItemFile();
    const removeActionItemFile = useRemoveActionItemFile();
    const editActionItem = useEditActionItem();

    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    useLoader(
        areQueriesLoading([...getActionItemDetails, downloadActionItemFile]) ||
            areMutationsLoading([uploadActionItemFile, removeActionItemFile, editActionItem]),
        EditActionItemContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
    }, []);

    useEffect(() => {
        if (isQuerySuccessful(actionItemDetails)) {
            setActionItemImportance(actionItemDetailsResponseData!.actionItemImportance);
            setActionItemTypeNode(actionItemDetailsResponseData!.actionItemTypeDto);
            setActionItemSubTypeNode(actionItemDetailsResponseData!.actionItemSubTypeDto);
        }
    }, [actionItemDetails.fetchStatus]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        /*
         * As part of the HTML spec, disabled controls are ignored and not submitted
         * see more: https://stackoverflow.com/a/7357314
         * in particular, as per 17.12.1: https://www.w3.org/TR/REC-html40-971218/interact/forms.html#h-17.12.1
         * "Disabled controls cannot be successfully posted."
         */
        if (isEditActionNameDisabled) {
            formData.append("name", actionItemDetailsResponseData?.name ?? "");
        }

        const editActionItemDynamicFieldDetailsDto =
            hasActionItemTypeChanged() && getAdditionalFieldsThatMatchTheTypeResponseData
                ? getAdditionalFieldsThatMatchTheTypeResponseData
                : getExistingAdditionalFieldValuesResponseData!.map(
                      (x) => x.actionItemDynamicFieldDetailsDto
                  );

        editActionItem.mutate(
            new EditActionItemDto(
                formData,
                actionItemId,
                actionItemImportance!,
                actionItemSubTypeNode!.nodeId,
                editActionItemDynamicFieldDetailsDto,
                actionItemTypeNode!.nodeId !== actionItemDetailsResponseData!.actionItemTypeNodeId
            ),
            {
                onSuccess: async () => {
                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyUpdatedActionItem"
                        ),
                    ];

                    navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${actionItemId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const hasActionItemTypeChanged = (): boolean => {
        return actionItemTypeNode
            ? actionItemTypeNode!.nodeId !== actionItemDetailsResponseData!.actionItemTypeNodeId
            : false;
    };

    const onActionItemImportanceSelected = (option: SingleValue<ActionItemImportanceDto>): void =>
        setActionItemImportance(option!.actionItemImportanceId);

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemTypeNode(option!);
        setActionItemSubTypeNode(null);
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemSubTypeNode(option!);
    };

    const onUploadActionItemFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        const file = selectedFiles?.[0];

        const fileBase64String = await getBase64FileString(file);

        if (fileBase64String === null) {
            toast.addToast(
                createErrorToastProps([t("FailedToReadFile", { keyPrefix: ActionPlans })])
            );
        } else {
            uploadActionItemFile.mutate(
                new ActionItemInstanceFileUploadDto(actionItemId, file, fileBase64String!),
                {
                    onSuccess: async () => {
                        toast.addToast(
                            createSuccessToastProps([
                                t("EvidenceSuccessfullyUploaded", { keyPrefix: ActionPlans }),
                            ])
                        );
                        queryClient.refetchQueries(["getActionItemInstanceFiles", actionItemId]);
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
        }

        event.target.value = "";
    };

    const onDownloadActionItemFile = (fileId: number): void =>
        setDownloadActionItemInstanceFileId(fileId);

    const onDownloadQuestionSetInstanceAnswerFile = (fileDetailsId: number): void =>
        setDownloadQuestionSetInstanceAnswerFileDetailsId(fileDetailsId);

    const onDeleteActionItemFile = (fileId: number): void => {
        setShowDeleteActionItemFileConfirmationModal(true);
        setRemoveActionItemFileId(fileId);
    };

    const onRemoveFile = (): void => {
        removeActionItemFile.mutate(removeActionItemFileId!, {
            onSuccess: async () => {
                toast.addToast(createSuccessToastProps([t("EvidenceRemovedFromActionItem")]));
                queryClient.refetchQueries(["getActionItemInstanceFiles", actionItemId]);
            },
        });
    };

    const [isEditActionNameDisabled, setIsEditActionNameDisabled] = useState(false);

    useEffect(() => {
        if (auth && auth.userRoles) {
            setIsEditActionNameDisabled(
                !hasRoleTypeInGroup(auth.userRoles, ActionItemRoleGroup.EditActionItemNameRoles)
            );
        }
    }, [auth]);

    const renderActionItemDetail = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemDetails")}>
            <>
                <SbFormTextFieldGroup
                    name="name"
                    label={t("Name", { keyPrefix: Common })}
                    type="text"
                    defaultValue={actionItemDetailsResponseData?.name}
                    required
                    disabled={
                        !hasRoleTypeInGroup(
                            auth.userRoles,
                            ActionItemRoleGroup.EditActionItemNameRoles
                        )
                    }
                />

                <SbFormTextAreaGroup
                    name="description"
                    label={t("Description", { keyPrefix: Common })}
                    maxLength={1500}
                    required
                    rows={4}
                    defaultValue={actionItemDetailsResponseData?.description}
                />

                <SbFormSelectFieldGroup
                    name={"actionItemImportance"}
                    label={t("ActionItemUrgency")}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    required
                    searchable={false}
                    clearable={false}
                    items={getActionItemImportancesResponseData}
                    onChange={onActionItemImportanceSelected}
                    itemDisplayText={(option: ActionItemImportanceDto) =>
                        t(ActionItemImportance[option.actionItemImportanceId], {
                            keyPrefix: Common,
                        })
                    }
                    defaultSelectedItem={getActionItemImportancesResponseData!.find(
                        (x) =>
                            x.actionItemImportanceId ===
                            actionItemDetailsResponseData?.actionItemImportance
                    )}
                />

                <SbFormCheckFieldGroup
                    fieldLabel={t("ExcludeFromReporting")}
                    type="checkbox"
                    values={[
                        {
                            name: "isPersonalActionItem",
                            defaultSelected: actionItemDetailsResponseData?.isPersonalActionItem,
                        },
                    ]}
                />

                {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.SystemAdminRoles) && (
                    <>
                        <SbFormDateTimeFieldGroup
                            name="startDate"
                            label={t("StartDate")}
                            required
                            disabled
                            defaultValue={
                                actionItemDetailsResponseData!.actionInstanceDetailsDto
                                    .startDateLocal
                            }
                        />

                        <SbFormDateTimeFieldGroup
                            name="dueDate"
                            label={t("DueDate")}
                            required
                            disabled
                            defaultValue={
                                actionItemDetailsResponseData!.actionInstanceDetailsDto.dueDateLocal
                            }
                        />
                    </>
                )}
            </>
        </TextTitledPanel>
    );

    const renderActionItemTypes = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemType", { keyPrefix: ActionItemTypes })}>
            <SbFormSelectFieldGroup
                name={"actionItemType"}
                label={t("ActionItemType")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemTypesResponseData}
                itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                onChange={onChangeActionTypeValue}
                defaultSelectedItem={actionItemTypeNode}
            />

            <SbFormSelectFieldGroup
                name={"actionItemSubType"}
                label={t("ActionItemSubType", { keyPrefix: ActionItemTypes })}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemSubTypesResponseData}
                itemDisplayText={(option: ActionItemTypeDto) => option.value} //TODO: Add translations for dynamic data
                onChange={onChangeActionSubTypeValue}
                defaultSelectedItem={actionItemSubTypeNode}
                value={actionItemSubTypeNode}
            />
        </TextTitledPanel>
    );

    const renderExistingAdditionalInformation = (): JSX.Element =>
        getExistingAdditionalFieldValuesResponseData ? (
            <TextTitledPanel title={t("AdditionalInformation")}>
                {getExistingAdditionalFieldValuesResponseData?.map((x) => {
                    switch (x.actionItemDynamicFieldDetailsDto.valueType) {
                        case AnswerValueType.Text:
                            return (
                                <SbFormTextFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    type={"text"}
                                    maxLength={1000}
                                    defaultValue={x.value}
                                />
                            );

                        case AnswerValueType.TextArea:
                            return (
                                <SbFormTextAreaGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    maxLength={3000}
                                    rows={4}
                                    defaultValue={x.value}
                                />
                            );

                        case AnswerValueType.Boolean:
                            return (
                                <SbFormSelectFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    placeholderText={t("NotSet", { keyPrefix: Nodes })}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    searchable={false}
                                    clearable={false}
                                    items={valuesToReactSelectDtos(
                                        actionItemDynamicFieldValueTypeBooleanDropdownItems
                                    )}
                                    itemDisplayText={(option: ReactSelectDto<string>) =>
                                        translateWithFallback(t, option.value, Nodes)
                                    }
                                    defaultSelectedItem={valuesToReactSelectDtos(
                                        actionItemDynamicFieldValueTypeBooleanDropdownItems
                                    ).find((y) => y.value === x.value)}
                                />
                            );

                        case AnswerValueType.DateTime:
                            return (
                                <SbFormDateTimeFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    defaultValue={
                                        x.value
                                            ? convertUtcDateToLocalDate(new Date(x.value))
                                            : null
                                    }
                                />
                            );

                        case AnswerValueType.List:
                            return (
                                <SbFormSelectFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    searchable={false}
                                    clearable={false}
                                    items={valuesToReactSelectDtos(
                                        x.actionItemDynamicFieldDetailsDto.listData.split("|")
                                    )}
                                    itemDisplayText={
                                        (option: ReactSelectDto<string>) => option.value //TODO: Add translations for dynamic data
                                    }
                                    defaultSelectedItem={valuesToReactSelectDtos(
                                        x.actionItemDynamicFieldDetailsDto.listData.split("|")
                                    ).find((y) => y.value === x.value)}
                                />
                            );

                        case AnswerValueType.Integer:
                        case AnswerValueType.Decimal:
                            return (
                                <>
                                    <SbFormNumberBoxGroup
                                        name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                        required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                        label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                        defaultValue={x.value}
                                    />
                                </>
                            );
                    }
                })}
            </TextTitledPanel>
        ) : (
            <></>
        );

    const renderAdditionalInformation = (): JSX.Element =>
        getAdditionalFieldsThatMatchTheTypeResponseData ? (
            <TextTitledPanel title={t("AdditionalInformation")}>
                {getAdditionalFieldsThatMatchTheTypeResponseData?.map((x) => {
                    switch (x.valueType) {
                        case AnswerValueType.Text:
                            return (
                                <SbFormTextFieldGroup
                                    name={x.actionItemDynamicFieldId.toString()}
                                    label={x.fieldName}
                                    maxLength={1000}
                                    required={x.isRequired}
                                    type={"text"}
                                />
                            );

                        case AnswerValueType.TextArea:
                            return (
                                <SbFormTextAreaGroup
                                    name={x.actionItemDynamicFieldId.toString()}
                                    label={x.fieldName}
                                    required={x.isRequired}
                                    maxLength={3000}
                                    rows={4}
                                />
                            );

                        case AnswerValueType.Boolean:
                            return (
                                <SbFormSelectFieldGroup
                                    name={x.actionItemDynamicFieldId.toString()}
                                    label={x.fieldName}
                                    placeholderText={t("NotSet", { keyPrefix: Nodes })}
                                    required={x.isRequired}
                                    searchable={false}
                                    clearable={false}
                                    items={valuesToReactSelectDtos(
                                        actionItemDynamicFieldValueTypeBooleanDropdownItems
                                    )}
                                    itemDisplayText={(option: ReactSelectDto<string>) =>
                                        translateWithFallback(t, option.value, Nodes)
                                    }
                                />
                            );

                        case AnswerValueType.DateTime:
                            return (
                                <SbFormDateTimeFieldGroup
                                    name={x.actionItemDynamicFieldId.toString()}
                                    label={x.fieldName}
                                    required={x.isRequired}
                                />
                            );

                        case AnswerValueType.List:
                            return (
                                <SbFormSelectFieldGroup
                                    name={x.actionItemDynamicFieldId.toString()}
                                    label={x.fieldName}
                                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                    required={x.isRequired}
                                    searchable={false}
                                    clearable={false}
                                    items={valuesToReactSelectDtos(x.listData.split("|"))}
                                    itemDisplayText={
                                        (option: ReactSelectDto<string>) => option.value //TODO: Add translations for dynamic data
                                    }
                                />
                            );

                        case AnswerValueType.Integer:
                        case AnswerValueType.Decimal:
                            return (
                                <SbFormNumberBoxGroup
                                    name={x.actionItemDynamicFieldId.toString()}
                                    required={x.isRequired}
                                    label={x.fieldName}
                                />
                            );
                    }
                })}
            </TextTitledPanel>
        ) : (
            <></>
        );

    const renderOptionalInformation = (): JSX.Element => (
        <TextTitledPanel title={t("OptionalInformation")}>
            <SbFormTextAreaGroup
                name={"requiredOutcome"}
                label={t("RemedialAction")}
                maxLength={4000}
                rows={4}
                defaultValue={actionItemDetailsResponseData?.requiredOutcome}
            />
        </TextTitledPanel>
    );

    const renderAttachedEvidence = (): JSX.Element => (
        <TextTitledPanel title={t("EvidenceAttachments")}>
            <StyledBorderedSection>
                <FileGallery
                    files={getActionItemInstanceFilesResponseData!}
                    filesLabel={`${t("ActionItemFiles")}`}
                    uploadLabel={`${t("AttachEvidence")}`}
                    onUploadFile={onUploadActionItemFile}
                    onDownloadFile={onDownloadActionItemFile}
                    fileDownloadData={downloadActionItemFile.data!}
                    onRemoveFile={onDeleteActionItemFile}
                />
            </StyledBorderedSection>

            <StyledBorderedSection>
                <FileGallery
                    files={actionItemDetailsResponseData!.questionSetInstanceAnswerFileDtos}
                    filesLabel={t("QuestionSetInstanceAnswerFiles") as string}
                    onDownloadFile={onDownloadQuestionSetInstanceAnswerFile}
                    fileDownloadData={downloadQuestionSetInstanceAnswerFile.data!}
                />
            </StyledBorderedSection>
        </TextTitledPanel>
    );

    const renderActionButtons = (): JSX.Element => (
        <EndAlignedDiv>
            <SaveButton type="submit" />
            <CancelButton onClick={() => navigate(-1)} />
        </EndAlignedDiv>
    );

    return (
        <>
            <PageHeading>{t("EditActionItem")}</PageHeading>
            <SectionVerticalSpace />

            <SbModal
                title={t("RemoveActionItemFile")}
                body={
                    t("RemoveActionPlanFileConfirmationText", { keyPrefix: ActionPlans }) as string
                }
                primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                onPrimaryButtonClicked={onRemoveFile}
                isVisible={showDeleteActionItemFileConfirmationModal}
                updateIsVisible={setShowDeleteActionItemFileConfirmationModal}
            />

            <StyledHiddenOverflowForm onSubmit={handleSubmit}>
                {areQueriesSuccessful([actionItemDetails, getActionItemImportances]) && (
                    <>
                        {renderActionItemDetail()}
                        <SectionVerticalSpace />
                    </>
                )}

                {areQueriesSuccessful([
                    actionItemDetails,
                    getActionItemTypes,
                    getActionItemSubTypes,
                ]) && (
                    <>
                        {renderActionItemTypes()}
                        <SectionVerticalSpace />
                    </>
                )}

                {areQueriesSuccessful([actionItemDetails, getAdditionalFieldValues]) &&
                    getExistingAdditionalFieldValuesResponseData &&
                    getExistingAdditionalFieldValuesResponseData.length > 0 &&
                    (actionItemTypeNode === null || !hasActionItemTypeChanged()) && (
                        <>
                            {renderExistingAdditionalInformation()}
                            <SectionVerticalSpace />
                        </>
                    )}

                {getAdditionalFieldsThatMatchTheTypeResponseData &&
                    getAdditionalFieldsThatMatchTheTypeResponseData.length > 0 &&
                    hasActionItemTypeChanged() &&
                    areQueriesSuccessful([actionItemDetails, getAdditionalFields]) && (
                        <>
                            {renderAdditionalInformation()}
                            <SectionVerticalSpace />
                        </>
                    )}

                {isQuerySuccessful(actionItemDetails) && (
                    <>
                        {renderOptionalInformation()}
                        <SectionVerticalSpace />
                    </>
                )}

                {isQuerySuccessful(actionItemDetails) && (
                    <>
                        {renderAttachedEvidence()}
                        <SectionVerticalSpace />
                    </>
                )}

                {isQuerySuccessful(actionItemDetails) && (
                    <>
                        {renderActionButtons()}
                        <SectionVerticalSpace />
                    </>
                )}
            </StyledHiddenOverflowForm>
        </>
    );
};

export default EditActionItemContainer;
