import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLink, FaPlus } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { AssociateRefLinkToChecklistEvent } from "../../../core/constants/application-insights-events";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { associateReferentialLinkColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { AssociateDissociateReferentialLinkToActionItemDto } from "../../../domain/dtos/actions/associate-dissociate-referential-link-to-action-item-dto";
import { AssociateReferentialLinkDto } from "../../../domain/dtos/checklists/associate-referential-link-dto";
import { AssociateReferentialLinkTypeDto } from "../../../domain/dtos/checklists/associate-referential-link-type-dto";
import {
    defaultFilterReferentialLinkTypesDto,
    FilterReferentialLinkTypeDto,
} from "../../../domain/dtos/referential-links/filter-referential-link-type-dto";
import {
    useAssociateActionItemReferentialLink,
    useAssociateChecklistReferentialLink,
    useGetAllAssociateReferentialLinks,
} from "../../../domain/viewmodels/common/associate-referential-link-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { DataTable } from "../../organisms/DataTable";
import { AssociateReferentialLinksFilter } from "../../organisms/filters/AssociateReferentialLinksFilter";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";

interface SearchParams {
    referentialLinkTypeName: string | null;
    referentialLinkValue: string | null;
}

const createSearchParams = (
    referentialLinkTypeName: string | null,
    referentialLinkValue: string | null
): SearchParams => ({
    referentialLinkTypeName: referentialLinkTypeName,
    referentialLinkValue: referentialLinkValue,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null);

const AssociateReferentialLinkContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterReferentialLinkTypeDto>(
        defaultFilterReferentialLinkTypesDto
    );
    const [selectedReferentialLinks, setSelectedReferentialLinks] = useState<number[]>([]);

    const navigate = useNavigate();
    const menu = useMenu();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const checklistId = Number(useParams().checklistId);
    const actionItemId = Number(useParams().actionItemId);

    const getAllAssociateReferentialLinks = useGetAllAssociateReferentialLinks(filterDto);
    const associateChecklistReferentialLink = useAssociateChecklistReferentialLink();
    const associateActionItemReferentialLink = useAssociateActionItemReferentialLink();

    useLoader(
        areQueriesLoading(getAllAssociateReferentialLinks) ||
            areMutationsLoading([
                associateChecklistReferentialLink,
                associateActionItemReferentialLink,
            ]),
        AssociateReferentialLinkContainer
    );

    useEffect(() => {
        if (checklistId) {
            menu.changeActiveNavbarItem(NavbarTitles.Admin);
            menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
        }

        if (actionItemId) {
            menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
            menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
        }

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const changeReferentialLinkType = (
        option: SingleValue<AssociateReferentialLinkTypeDto>
    ): void => {
        setSearchParams({
            ...searchParams,
            referentialLinkTypeName: option?.name ?? null,
        });
    };

    const changeReferentialLinkValue = (referentialLinkValue: string): void => {
        setSearchParams({
            ...searchParams,
            referentialLinkValue: referentialLinkValue !== "" ? referentialLinkValue : null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
        setSelectedReferentialLinks([]);
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultBasePaginationDto, ...defaultSearchParams });
        setSelectedReferentialLinks([]);
    };

    const selectReferentialLink = (metadata: AssociateReferentialLinkDto): void => {
        const selectedReferentialLink = metadata.referentialLinkId;
        const selectedReferentialLinksArr = [...selectedReferentialLinks];

        const index = selectedReferentialLinksArr.indexOf(selectedReferentialLink);

        if (index > -1) {
            selectedReferentialLinksArr.splice(index, 1);
        } else {
            selectedReferentialLinksArr.push(selectedReferentialLink);
        }

        setSelectedReferentialLinks(selectedReferentialLinksArr);
    };

    const selectedReferentialLink = (metadata: AssociateReferentialLinkDto): boolean => {
        const selectedReferentialLink = metadata.referentialLinkId;
        const selectedReferentialLinksArray = [...selectedReferentialLinks];

        return selectedReferentialLinksArray.includes(selectedReferentialLink);
    };

    const handleAssociateReferentialLink = (): void => {
        if (checklistId)
            return associateChecklistReferentialLink.mutate(
                {
                    checklistId: checklistId,
                    referentialLinkIds: selectedReferentialLinks,
                },
                {
                    onSuccess: async () => {
                        trackAppInsightsEvent(
                            auth.email,
                            window.location.href,
                            AssociateRefLinkToChecklistEvent
                        );

                        queryClient.invalidateQueries(["getAssociatedReferentialLinks"]);

                        const params = [
                            createNavigateSearchParameter("success", "true"),
                            createNavigateSearchParameter(
                                "messageKey",
                                "AssociateReferentialLinkToChecklistSuccessMessage"
                            ),
                        ];

                        navigateSearch(
                            `${getPath(AccordionTitles.Checklists)}/${checklistId}`,
                            params
                        );
                    },
                    onError: (error: HTTPError) => {
                        trackAppInsightsException(
                            auth.email,
                            window.location.href,
                            AssociateRefLinkToChecklistEvent,
                            error
                        );
                        errorResponseToDisplayHandler(error);
                    },
                }
            );

        if (actionItemId)
            return associateActionItemReferentialLink.mutate(
                new AssociateDissociateReferentialLinkToActionItemDto(
                    actionItemId,
                    selectedReferentialLinks
                ),
                {
                    onSuccess: async () => {
                        queryClient.invalidateQueries(["getAssociatedReferentialLinks"]);

                        const params = [
                            createNavigateSearchParameter("success", "true"),
                            createNavigateSearchParameter(
                                "messageKey",
                                "AssociateReferentialLinkToActionItemSuccessMessage"
                            ),
                        ];

                        navigateSearch(
                            `${getPath(DrawerTitles.ActionItems)}/${actionItemId}`,
                            params
                        );
                    },
                    onError: errorResponseToDisplayHandler,
                }
            );
    };

    const associateNewClick = (): void => {
        if (checklistId) {
            return navigate(
                `${getPath(AccordionTitles.Checklists)}/${checklistId}/create-referential-link`
            );
        }

        if (actionItemId) {
            return navigate(
                `${getPath(DrawerTitles.ActionItems)}/${actionItemId}/create-referential-link`
            );
        }
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("AssociateReferentialLink")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getAllAssociateReferentialLinks[1]) && (
                <AssociateReferentialLinksFilter
                    referentialLinkTypes={getAllAssociateReferentialLinks[1].data!}
                    referentialLinkTypeName={searchParams.referentialLinkTypeName}
                    changeReferentialLinkType={changeReferentialLinkType}
                    referentialLinkValue={searchParams.referentialLinkValue}
                    changeReferentialLinkValue={changeReferentialLinkValue}
                    search={search}
                    resetFilter={resetFilter}
                />
            )}
            <SectionVerticalSpace />

            {isQuerySuccessful(getAllAssociateReferentialLinks[0]) && (
                <>
                    <DataTable
                        columns={associateReferentialLinkColumnNames}
                        rows={getAllAssociateReferentialLinks[0].data!.rows}
                        selectItem={selectReferentialLink}
                        selectedItem={selectedReferentialLink}
                        totalItems={getAllAssociateReferentialLinks[0].data!.recordCount}
                        paginationDto={filterDto}
                        setPaginationDto={setFilterDto}
                    />
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <Col sm={"auto"}>
                            <SbButton
                                icon={FaLink}
                                variant={"primary"}
                                type={"button"}
                                label={t("AssociateSelected", { keyPrefix: Common })}
                                onClick={handleAssociateReferentialLink}
                                disabled={selectedReferentialLinks.length === 0}
                            />
                        </Col>
                        <Col sm={"auto"}>{"or"}</Col>
                        <Col sm={"auto"}>
                            <SbButton
                                icon={FaPlus}
                                variant={"primary"}
                                type={"button"}
                                label={t("AssociateNew", { keyPrefix: Common })}
                                onClick={associateNewClick}
                            />
                        </Col>
                        <Col sm={"auto"}>
                            <CancelButton onClick={() => navigate(-1)} />
                        </Col>
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default AssociateReferentialLinkContainer;
