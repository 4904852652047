import { t } from "i18next";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IconType } from "react-icons";
import { FaExchangeAlt, FaRegClock, FaRegLightbulb } from "react-icons/fa";
import styled from "styled-components";
import { Common } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    StyledQuestionCaptureContainer,
    StyledQuestionCaptureHeader,
} from "../../../core/theme/global-styles";
import { isQueryLoading } from "../../../core/utilities/responseStateHelper";
import IndicatorAnswerDto from "../../../domain/dtos/answer-capture/indicator-answer-dto";
import IndicatorQuestionAnswerDto from "../../../domain/dtos/answer-capture/indicator-question-answer-dto";
import QuestionAnswerInstanceDto from "../../../domain/dtos/answer-capture/question-answer-instance-dto";
import QuestionCaptureDto from "../../../domain/dtos/answer-capture/question-capture-dto";
import PredefinedAnswerDto from "../../../domain/dtos/predefined-answers/predefined-answer-dto";
import AnswerFlagMode from "../../../domain/enums/answer-types/answer-flag-mode";
import InstanceStatus from "../../../domain/enums/questions/instance-status";
import {
    useGetIndicatorQuestionAnswerFlag,
    useGetQuestionAnswerDetails,
} from "../../../domain/viewmodels/answer-capture/answer-capture-viewmodel";
import { SbRibbon } from "../../atoms/SbRibbon";
import QuestionCaptureFileDetailsContainer from "../../templates/answer-capture/QuestionCaptureFileDetailsContainer";
import NestedQuestionCapture from "./nested-question-capture";
import AnswerCaptureDetailsAndActions from "./question-capture-details-and-actions";
import QuestionCaptureStatus from "./question-capture-status";

interface StyledQuestionTitleLabelProps {
    $isClickable?: boolean;
}

const StyledClickableQuestionLabel = styled.div<StyledQuestionTitleLabelProps>`
    cursor: ${(props) => (props.$isClickable === true ? "pointer" : "default")};
`;

const StyledCol = styled(Col)`
    display: "flex";
    justify-content: "center";
`;

const defaultTimeZone = "UTC";

class TimeZoneProps {
    isChecklistTimeZone: boolean;
    timeZone: string;

    constructor(isChecklistTimeZone: boolean, timeZone: string) {
        this.isChecklistTimeZone = isChecklistTimeZone;
        this.timeZone = timeZone;
    }
}

const QuestionCapture: React.FC<{
    // TODO: Container
    questionCaptureDto: QuestionCaptureDto;
}> = ({ questionCaptureDto }) => {
    const {
        formattedOpenDateTimeUtc,
        formattedDueDateTimeUtc,
        formattedOpenDateTimeChecklistTimeZone,
        formattedDueDateTimeChecklistTimeZone,
        checklistTimeZoneDto,
        questionSetInstanceAnswerId,
        questionSetName,
        status,
        isEnabled,
        isTraining,
        canTriggerActionItem,
        questionTextDto,
        answerTypeDto,
        questionId,
    } = questionCaptureDto;

    const [timeZone, setTimeZone] = useState<TimeZoneProps>(
        new TimeZoneProps(true, checklistTimeZoneDto.timeZoneId)
    );
    const [openDateTime, setOpenDateTime] = useState(formattedOpenDateTimeChecklistTimeZone);
    const [dueDateTime, setDueDateTime] = useState(formattedDueDateTimeChecklistTimeZone);
    const [answerFlagMode, setAnswerFlagMode] = useState<AnswerFlagMode | null>(null);
    const [indicatorQuestionAnswerDto, setIndicatorQuestionAnswerDto] =
        useState<IndicatorQuestionAnswerDto | null>(null);
    const [requestedQuestionAnswerDetails, setRequestedQuestionAnswerDetails] =
        useState<boolean>(false);

    const getQuestionAnswerDetails = useGetQuestionAnswerDetails(
        questionSetInstanceAnswerId,
        requestedQuestionAnswerDetails
    );

    const getIndicatorQuestionAnswerFlag = useGetIndicatorQuestionAnswerFlag(
        indicatorQuestionAnswerDto
    );

    useLoader(isQueryLoading(getQuestionAnswerDetails), QuestionCapture);

    useEffect(() => {
        if (
            getIndicatorQuestionAnswerFlag.fetchStatus === "idle" &&
            getIndicatorQuestionAnswerFlag.status === "success"
        ) {
            if (getIndicatorQuestionAnswerFlag.data === null) {
                setAnswerFlagMode(AnswerFlagMode.Neutral);
                return;
            }

            setAnswerFlagMode(getIndicatorQuestionAnswerFlag.data!.indicatorQuestionAnswerFlag);
        }
    }, [getIndicatorQuestionAnswerFlag.fetchStatus]);

    const isOverdue = status === InstanceStatus.Overdue;

    const questionAnswerInstanceDto =
        QuestionAnswerInstanceDto.constructFromQuestionCaptureDto(questionCaptureDto);

    const onMutuallyExclusiveAnswerClicked = (predefinedAnswerDto: PredefinedAnswerDto): void =>
        setAnswerFlagMode(predefinedAnswerDto.answerFlagMode);

    const onIndicatorAnswerChanged = <TType,>(dto: IndicatorAnswerDto<TType>): void => {
        if (dto.value === null) {
            setAnswerFlagMode(AnswerFlagMode.Neutral);
            return;
        }

        setIndicatorQuestionAnswerDto(
            IndicatorQuestionAnswerDto.constructFromIndicatorAnswer(questionId, dto)
        );
    };

    const onViewQuestionDetailsClicked = (): void => setRequestedQuestionAnswerDetails(true);

    const toggleTimeZone = (): void => {
        const isChecklistTimeZone = !timeZone.isChecklistTimeZone;

        setTimeZone({
            ...timeZone,
            isChecklistTimeZone: isChecklistTimeZone,
            timeZone: isChecklistTimeZone ? checklistTimeZoneDto.timeZoneId : defaultTimeZone,
        });

        setOpenDateTime(
            isChecklistTimeZone ? formattedOpenDateTimeChecklistTimeZone : formattedOpenDateTimeUtc
        );

        setDueDateTime(
            isChecklistTimeZone ? formattedDueDateTimeChecklistTimeZone : formattedDueDateTimeUtc
        );
    };

    const buildQuestionTitleLabel = (
        value: string,
        label?: string,
        icon?: IconType,
        onClick?: () => void
    ): ReactElement<HTMLDivElement> => (
        <StyledClickableQuestionLabel onClick={onClick} $isClickable={onClick !== undefined}>
            {icon && React.createElement(icon)} {label ? label + ": " + value : value}
        </StyledClickableQuestionLabel>
    );

    const buildQuestionHeader = (): ReactElement<HTMLParagraphElement> => (
        <StyledQuestionCaptureHeader $variant="secondary">
            <Row>
                <Col>{buildQuestionTitleLabel(questionSetName)}</Col>
                <Col>{buildQuestionTitleLabel(openDateTime, "Activated", FaRegLightbulb)}</Col>
                <Col>{buildQuestionTitleLabel(dueDateTime, "Due", FaRegClock)}</Col>
                <Col>
                    {buildQuestionTitleLabel(
                        timeZone.timeZone,
                        undefined,
                        FaExchangeAlt,
                        toggleTimeZone
                    )}
                </Col>
                <StyledCol>
                    <QuestionCaptureStatus status={status} />
                </StyledCol>
            </Row>
        </StyledQuestionCaptureHeader>
    );

    const buildTrainingBanner = (): ReactElement<typeof SbRibbon> | false =>
        isTraining && <SbRibbon size={"large"} label={t("Training", { keyPrefix: Common })} />;

    return (
        <StyledQuestionCaptureContainer $isOverdue={isOverdue} $disabled={!isEnabled}>
            {buildTrainingBanner()}
            {buildQuestionHeader()}

            <AnswerCaptureDetailsAndActions
                questionText={questionTextDto.text}
                isNestedQuestion={false}
                canTriggerActionItem={canTriggerActionItem}
                questionAnswerInstanceDto={questionAnswerInstanceDto}
                answerTypeDto={answerTypeDto}
                isOverdue={isOverdue}
                answerFlagMode={answerFlagMode}
                questionAnswerDetailsDto={getQuestionAnswerDetails.data}
                viewDetailsClicked={onViewQuestionDetailsClicked}
                onMutuallyExclusiveAnswerClicked={onMutuallyExclusiveAnswerClicked}
                onIndicatorAnswerChanged={onIndicatorAnswerChanged}
            >
                <QuestionCaptureFileDetailsContainer
                    questionAnswerInstanceDto={questionAnswerInstanceDto}
                    isNestedQuestion={false}
                />
            </AnswerCaptureDetailsAndActions>

            {questionCaptureDto.nestedQuestionCaptureDtos?.map((x, index) => (
                <NestedQuestionCapture
                    key={index}
                    isOverDue={isOverdue}
                    nestedQuestionCaptureDto={x}
                    parentAnswerFlagMode={answerFlagMode}
                />
            ))}
        </StyledQuestionCaptureContainer>
    );
};

export default QuestionCapture;
