import { ReactElement, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCheck, FaLock, FaLockOpen, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { Common, Nodes, Users } from "../../../core/constants/translation-namespace";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    associatedUserNodeColumnNames,
    filterUserAssignedActionItemsColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { getNullableString } from "../../../core/utilities/string-helper";
import {
    ActionInstanceStatusDto,
    createActionInstanceStatusDto,
} from "../../../domain/dtos/actions/action-instance-status-dto";
import { ActionItemEvidenceDto } from "../../../domain/dtos/actions/action-item-evidence-dto";
import { BasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    defaultFilterUserAssignedActionItemsDto,
    FilterUserAssignedActionItemsDto,
} from "../../../domain/dtos/users/filter-user-actions-dto";
import {
    ActionItemRoleGroup,
    hasRoleTypeInGroup,
    Role,
    UserRoleGroup,
} from "../../../domain/enums/Roles";
import { useUserNodeSuspensionDetails } from "../../../domain/viewmodels/users/suspend-user-from-all-nodes-viewmodel";
import {
    useFilterAssociatedUserNodes,
    useFilterUserAssignedActionItems,
    useGetUserDetails,
} from "../../../domain/viewmodels/users/view-user-details-viewmodel";
import { CreateLink, EditLink, SbLink, ViewLink } from "../../atoms/SbLink";
import { SbRibbon } from "../../atoms/SbRibbon";
import { SbAccordion } from "../../molecules/SbAccordion";
import { DataTable } from "../../organisms/DataTable";
import { UserAssignedActionItemsFilter } from "../../organisms/filters/UserAssignedActionItemsFilter";
import { UserNodeSuspensionConfirmationProps } from "./SuspendUserFromAllNodesContainer";
import { UserLockoutConfirmationProps } from "./ToggleUserLockoutContainer";

export interface ConfirmationDetailsProps {
    username: string;
}

const UserDetailsContainer: React.FC = () => {
    const menu = useMenu();
    const toast = useToast();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const allActionInstanceStatusOption = createActionInstanceStatusDto(-1, "All");
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const { t } = useTranslation("translation", { keyPrefix: Users });

    const [associatedNodesPaginationDto, setAssociatedNodesPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const userId = Number(useParams().userId);

    const getUserDetails = useGetUserDetails(userId);
    const filterAssociatedUserNodes = useFilterAssociatedUserNodes(
        userId,
        associatedNodesPaginationDto
    );

    const [searchParams, setSearchParams] = useState(defaultFilterUserAssignedActionItemsDto);
    const [actionItemsPaginationDto, setActionItemsPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);
    const [filter, setFilter] = useState<FilterUserAssignedActionItemsDto>(
        defaultFilterUserAssignedActionItemsDto
    );
    const [selectedActionInstanceStatus, setSelectedActionInstanceStatus] = useState<
        SingleValue<ActionInstanceStatusDto>
    >(createActionInstanceStatusDto(-1, "All"));
    const filterUserAssignedActionItems = useFilterUserAssignedActionItems(
        actionItemsPaginationDto,
        filter,
        userId
    );
    const getUserNodeSuspensionDetails = useUserNodeSuspensionDetails(userId);

    const getUserDetailsResponseData = getUserDetails.data;
    const filterUserNodesResponseData = filterAssociatedUserNodes.data;

    const filterUserActionItemsResponseData = filterUserAssignedActionItems[0].data;
    const actionInstanceStatusResponseData = filterUserAssignedActionItems[1].data;
    useLoader(
        areQueriesLoading([
            getUserDetails,
            getUserNodeSuspensionDetails,
            filterAssociatedUserNodes,
            ...filterUserAssignedActionItems,
        ]),
        UserDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.UserManagement, DrawerTitles.UserManagement);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    const buildLinkProps = (): ConfirmationDetailsProps => ({
        username: getUserDetailsResponseData!.emailAddress,
    });

    const buildLockoutLinkProps = (): UserLockoutConfirmationProps => ({
        ...buildLinkProps(),
        isLockedOut: getUserDetailsResponseData!.isLockedOut,
    });

    const buildUserNodeSuspensionLinkProps = (): UserNodeSuspensionConfirmationProps => ({
        ...buildLinkProps(),
        isSuspended: getUserDetailsResponseData!.isLockedOut,
    });

    const buildAssignedRoles = (): string => {
        const roles = getUserDetailsResponseData!.roles;

        if (roles.length) {
            return roles.map((x) => x.name).join(" | ");
        }

        return t("None", { keyPrefix: Common });
    };

    const buildLinks = (): ReactElement<typeof Link> => {
        let lockoutLink: ReactElement<typeof Link>;
        let deleteLink: ReactElement<typeof Link>;

        if (auth.isAdmin) {
            lockoutLink = hasRoleTypeInGroup(auth.userRoles, [Role.SystemAdmin]) ? (
                buildLockoutLinks()
            ) : (
                <></>
            );
            deleteLink = hasRoleTypeInGroup(auth.userRoles, [Role.SystemAdmin]) ? (
                buildDeleteLinks()
            ) : (
                <></>
            );
        } else {
            lockoutLink = deleteLink = <></>;
        }

        return (
            <>
                {lockoutLink} {deleteLink} {buildSuspensionEditAndViewUsersLinks()}
            </>
        );
    };

    const buildSuspensionEditAndViewUsersLinks = (): ReactElement<typeof Link> => (
        <>
            {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.SuspensionRoles) &&
                buildSuspendUserNodeLinks()}
            {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.WriteRoles) && (
                <EditLink navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/edit`} />
            )}
            <ViewLink label={t("Users")} navigateTo={getPath(DrawerTitles.UserManagement)} />
        </>
    );

    const buildLockoutLinks = (): ReactElement<typeof Link> =>
        getUserDetailsResponseData!.isLockedOut ? (
            <SbLink
                variant="primary"
                label={t("Unlock")}
                icon={FaLockOpen}
                navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/reset-lockout`}
                navigationProps={buildLockoutLinkProps()}
            />
        ) : (
            <SbLink
                variant="primary"
                label={t("Lockout", { keyPrefix: Common })}
                icon={FaLock}
                navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/lockout`}
                navigationProps={buildLockoutLinkProps()}
            />
        );

    const buildSuspendUserNodeLinks = (): ReactElement<typeof Link> =>
        getUserNodeSuspensionDetails!.data?.userNodeSuspensionId ? (
            <SbLink
                variant="primary"
                icon={FaThumbsUp}
                label={t("CancelSuspensionFromAllNodes")}
                navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/reset-suspend-user-node`}
                navigationProps={buildUserNodeSuspensionLinkProps()}
            />
        ) : (
            <SbLink
                variant="warning"
                icon={FaThumbsDown}
                label={t("SuspendUserFromAllNodes")}
                navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/suspend-user-node`}
                navigationProps={buildUserNodeSuspensionLinkProps()}
            />
        );

    const buildDeleteLinks = (): ReactElement<typeof Link> =>
        getUserDetailsResponseData!.isDeleted ? (
            <SbLink
                variant="primary"
                label={t("Undelete", { keyPrefix: Common })}
                icon={FaCheck}
                navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/undelete`}
                navigationProps={buildLinkProps()}
            />
        ) : (
            <SbLink
                variant="primary"
                label={t("Delete", { keyPrefix: Common })}
                icon={RiDeleteBinFill}
                navigateTo={`${getPath(DrawerTitles.UserManagement)}/${userId}/delete`}
                navigationProps={buildLinkProps()}
            />
        );

    const buildRibbon = (): JSX.Element => (
        <SbRibbon size={"small"} label={t("Training", { keyPrefix: Common })} />
    );

    const handleDissociateNode = (nodeId: number): void => {
        const params = [createNavigateSearchParameter("nodeId", nodeId.toString())];

        navigateSearch(`${getPath(DrawerTitles.UserManagement)}/${userId}/dissociate-node`, params);
    };

    const navigateToViewActionItem = (dto: ActionItemEvidenceDto): void => {
        const params = [
            createNavigateSearchParameter(
                "actionItemInstanceId",
                dto.actionItemInstanceId.toString()
            ),
        ];

        navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${dto.actionItemId}`, params);
    };

    const onChangeActionItemName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const onChangeActionInstanceStatus = (option: SingleValue<ActionInstanceStatusDto>): void => {
        setSelectedActionInstanceStatus(option);
        setSearchParams({
            ...searchParams,
            actionInstanceStatusId:
                option!.actionInstanceStatusId !== -1 ? option!.actionInstanceStatusId : null,
        });
    };

    const onChangeActionItemId = (actionItemId: string): void => {
        setSearchParams({
            ...searchParams,
            actionItemId: getNullableString(actionItemId),
        });
    };

    const resetFilter = (): void => {
        setSelectedActionInstanceStatus(createActionInstanceStatusDto(-1, "All"));
        setSearchParams(defaultFilterUserAssignedActionItemsDto);
        setFilter(defaultFilterUserAssignedActionItemsDto);
        setUrlSearchParams(urlSearchParams);
        setActionItemsPaginationDto(defaultPaginationDto);
    };

    const search = (): void => {
        setFilter(searchParams);
        setActionItemsPaginationDto({ ...actionItemsPaginationDto, pageNumber: 1 });
    };
    const renderAssociatedNodes = (): JSX.Element => (
        <>
            <SectionVerticalSpace />
            <SbAccordion title={t("AssignedNodes", { keyPrefix: Nodes })}>
                {hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles) && (
                    <EndAlignedDiv>
                        <CreateLink
                            label={t("AssignToNode", { keyPrefix: Nodes })}
                            navigateTo={`${getPath(
                                DrawerTitles.UserManagement
                            )}/${userId}/associate-node`}
                        />
                    </EndAlignedDiv>
                )}
                <DataTable
                    noResultsMessage="No nodes found"
                    columns={associatedUserNodeColumnNames}
                    rows={filterUserNodesResponseData!.rows}
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles)
                            ? handleDissociateNode
                            : undefined
                    }
                    ribbon={buildRibbon()}
                    totalItems={filterUserNodesResponseData!.recordCount}
                    paginationDto={associatedNodesPaginationDto}
                    setPaginationDto={setAssociatedNodesPaginationDto}
                />
            </SbAccordion>
        </>
    );

    const renderAssociatedActions = (): JSX.Element => (
        <>
            <SectionVerticalSpace />
            <SbAccordion title={"Assigned Action Items"}>
                <UserAssignedActionItemsFilter
                    actionItemName={searchParams.name}
                    onChangeActionItemName={onChangeActionItemName}
                    actionItemId={searchParams.actionItemId}
                    onChangeActionItemId={onChangeActionItemId}
                    actionInstanceStatus={selectedActionInstanceStatus}
                    actionInstanceStatuses={[
                        allActionInstanceStatusOption,
                        ...actionInstanceStatusResponseData!,
                    ]}
                    onChangeActionInstanceStatus={onChangeActionInstanceStatus}
                    search={search}
                    resetFilter={resetFilter}
                />
                <DataTable
                    noResultsMessage={t("NoAssignedActionItems")}
                    columns={filterUserAssignedActionItemsColumnNames}
                    rows={filterUserActionItemsResponseData!.rows}
                    ribbon={buildRibbon()}
                    totalItems={filterUserActionItemsResponseData!.recordCount}
                    paginationDto={actionItemsPaginationDto}
                    setPaginationDto={setActionItemsPaginationDto}
                    viewItem={
                        hasRoleTypeInGroup(auth.userRoles, ActionItemRoleGroup.ReadRoles)
                            ? navigateToViewActionItem
                            : undefined
                    }
                />
            </SbAccordion>
        </>
    );
    
    return (
        <>
            <PageHeading>{t("UserDetailsTitle")}</PageHeading>
            <SectionVerticalSpace />

            {isQuerySuccessful(getUserDetails) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{t("UserName")}</DetailsLabel>
                                <DetailsLabel>{t("FirstName")}</DetailsLabel>
                                <DetailsLabel>{t("LastName")}</DetailsLabel>
                                {/* <DetailsLabel>{t("TimeZoneCountry", {keyPrefix: Profile})}</DetailsLabel> */
                                /* TODO Time Zone Country*/}
                                <DetailsLabel>{t("IsLockedOut")}</DetailsLabel>
                                <DetailsLabel>{t("CreatedByUserEmail")}</DetailsLabel>
                                <DetailsLabel>
                                    {t("DateCreated", { keyPrefix: Common })}
                                </DetailsLabel>
                                <DetailsLabel>{t("ModifiedByUserEmail")}</DetailsLabel>
                                <DetailsLabel>
                                    {t("DateLastModified", { keyPrefix: Common })}
                                </DetailsLabel>
                                <DetailsLabel>{t("IsDeleted", { keyPrefix: Common })}</DetailsLabel>
                                {auth.isAdmin && auth.isImpersonating && (
                                    <DetailsLabel>{t("LastLoginDateUtc")}</DetailsLabel>
                                )}
                                <DetailsLabel>{t("RolesAssigned")}</DetailsLabel>
                            </Col>

                            <Col md="10">
                                <DetailsValue>
                                    {getUserDetailsResponseData!.emailAddress}
                                </DetailsValue>
                                <DetailsValue>{getUserDetailsResponseData!.firstName}</DetailsValue>
                                <DetailsValue>{getUserDetailsResponseData!.lastName}</DetailsValue>
                                {/* <DetailsValue>{}</DetailsValue> */
                                /* TODO Time Zone Country*/
                                /* Evaluate if this is needed, users can set their own time zone from 'Profile' */}
                                <Form.Check
                                    type={"checkbox"}
                                    checked={getUserDetailsResponseData!.isLockedOut}
                                    disabled
                                />
                                <DetailsValue>
                                    {getUserDetailsResponseData!.createdByEmailAddress}
                                </DetailsValue>
                                <DetailsValue>
                                    {toDateAndTimeFormat(
                                        getUserDetailsResponseData!.dateCreatedUtc.toString()
                                    )}
                                </DetailsValue>
                                <DetailsValue>
                                    {getUserDetailsResponseData!.modifiedByEmailAddress}
                                </DetailsValue>
                                <DetailsValue>
                                    {toDateAndTimeFormat(
                                        getUserDetailsResponseData!.dateModifiedUtc.toString()
                                    )}
                                </DetailsValue>
                                <Form.Check
                                    type={"checkbox"}
                                    checked={getUserDetailsResponseData!.isDeleted}
                                    disabled
                                />
                                {auth.isAdmin && auth.isImpersonating && (
                                    <DetailsValue>
                                        {getUserDetailsResponseData!.lastLoginDateUtc
                                            ? toDateAndTimeFormat(
                                                  getUserDetailsResponseData!.lastLoginDateUtc.toString()
                                              )
                                            : "-"}
                                    </DetailsValue>
                                )}
                                <DetailsValue>{buildAssignedRoles()}</DetailsValue>
                            </Col>
                        </Row>

                        <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                    </ContentContainer>
                </>
            )}
            {isQuerySuccessful(filterAssociatedUserNodes) && renderAssociatedNodes()}

            {areQueriesSuccessful(filterUserAssignedActionItems) && renderAssociatedActions()}
        </>
    );
};

export default UserDetailsContainer;
