import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ActionItems, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { ActionItemRaciParticipantDetailsDto } from "../../../domain/dtos/actions/action-item-raci-participant-details-dto";
import { AssociateRaciParticipantDto } from "../../../domain/dtos/actions/associate-raci-participant-dto";
import { DissociateRaciParticipantDto } from "../../../domain/dtos/actions/dissociate-raci-participant-dto";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import {
    useDissociateRaciParticipant,
    useGetAssociatedRaciParticipantDetails,
} from "../../../domain/viewmodels/action-items/dissociate-raci-participant-to-action-item-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateActionItemRaciParticipantContainer: React.FC = () => {
    const menu = useMenu();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const raciSetId = Number(useParams().raciSetId);
    const userId = Number(urlSearchParams.get("userId"));
    const actionItemId = Number(urlSearchParams.get("actionItemId"));
    const raciRelationship = Number(urlSearchParams.get("raciRelationship"));

    const getAssociatedRaciParticipantDetails = useGetAssociatedRaciParticipantDetails(
        new ActionItemRaciParticipantDetailsDto(raciSetId, userId, raciRelationship)
    );
    const dissociateRaciParticipant = useDissociateRaciParticipant();

    useLoader(
        isQueryLoading(getAssociatedRaciParticipantDetails) ||
            isMutationLoading(dissociateRaciParticipant),
        DissociateActionItemRaciParticipantContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
    }, []);

    const handleDissociateRaciParticipant = (): void =>
        dissociateRaciParticipant.mutate(
            new DissociateRaciParticipantDto(
                raciSetId,
                new AssociateRaciParticipantDto(userId, actionItemId, raciRelationship)
            ),
            {
                onSuccess: async () => {
                    queryClient.refetchQueries(["filterAssociatedRaciSetUsers"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "SuccessfullyDissociatedRACIParticipantFromActionItem"
                        ),
                    ];

                    navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${actionItemId}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={handleDissociateRaciParticipant} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(
            t("Name", { keyPrefix: Common }),
            `${getAssociatedRaciParticipantDetails.data!.firstName} ${
                getAssociatedRaciParticipantDetails.data!.lastName
            }`
        ),
        new TextConfirmationRow(
            t("RaciRelationship"),
            RaciRelationship[getAssociatedRaciParticipantDetails.data!.raciRelationship].toString()
        ),
    ];

    return isQuerySuccessful(getAssociatedRaciParticipantDetails) ? (
        <ActionConfirmationFragment
            pageHeading={t("DissociateRaciSetUserDisplay")}
            panelTitle={t("DissociateRaciSetUserConfirmationDisplay")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
        />
    ) : (
        <></>
    );
};

export default DissociateActionItemRaciParticipantContainer;
