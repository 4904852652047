import { CreateActionPlanDto } from "../../dtos/actions/create-action-plan-dto";

export class CreateActionPlanRequest {
    name: string;
    description: string;
    userOwnerId: Number;

    public constructor(dto: CreateActionPlanDto) {
        const { name, description, userOwnerId } = dto;

        this.name = name;
        this.description = description;
        this.userOwnerId = userOwnerId;
    }
}
