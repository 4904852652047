import { ControlFirstTenantIdentifier } from "../../domain/enums/common/TenantIdentifier";

export const getTenantIdentifier = (windowUrl: string): ControlFirstTenantIdentifier => {
    if (windowUrl.includes("admin")) {
        return ControlFirstTenantIdentifier.Admin;
    } else if (windowUrl.includes("standardbank")) {
        return ControlFirstTenantIdentifier.StandardBank;
    } else {
        return ControlFirstTenantIdentifier.Admin;
    }
};
