import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ActionItems } from "../../../core/constants/translation-namespace";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import { useMenu } from "../../../core/store/menu-context";
import {
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import {
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import { getNullableString } from "../../../core/utilities/string-helper";
import {
    ActionItemTypeDto,
    allActionItemTypeOption,
} from "../../../domain/dtos/action-items/action-item-type-dto";
import {
    defaultFilterFinalizedActionItemsDto,
    FilterFinalizedActionItemsDto,
} from "../../../domain/dtos/action-items/filter-finalized-action-items-dto";
import {
    emptySearchFinalizedActionItemsDto,
    SearchFinalizedActionItemsDto,
} from "../../../domain/dtos/action-items/search-finalized-action-items-dto";
import { emptySearchMyActionItemsDto } from "../../../domain/dtos/action-items/search-my-action-items-dto";
import { ActionInstanceStatusDto } from "../../../domain/dtos/actions/action-instance-status-dto";
import {
    BasePaginationDto,
    defaultBasePaginationDto,
} from "../../../domain/dtos/common/base-pagination-dto";
import { ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { ActionInstanceStatus } from "../../../domain/enums/action-items/ActionInstanceStatus";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import { useFilterFinalizedActionItems } from "../../../domain/viewmodels/action-items/view-finalized-action-items-viewmodel";
import { ActionItemsList } from "../../organisms/ActionItemsList";
import { FinalizedActionItemsFilter } from "../../organisms/filters/FinalizedActionItemsFilter";
import {
    allActionInstanceStatusOption,
    allActionItemSubTypeOption,
} from "../actions/ActionItemsContainer";

const FinalisedActionItemsContainer: React.FC = () => {
    const [searchParams, setSearchParams] = useState<SearchFinalizedActionItemsDto>(
        emptySearchFinalizedActionItemsDto()
    );
    const [filterDto, setFilterDto] = useState<FilterFinalizedActionItemsDto>(
        defaultFilterFinalizedActionItemsDto
    );
    const [paginationDto, setPaginationDto] = useState<BasePaginationDto>(defaultBasePaginationDto);

    const menu = useMenu();
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    const filterFinalizedActionItems = useFilterFinalizedActionItems(filterDto, paginationDto);
    const filterFinalizedActionItemsResponseData = filterFinalizedActionItems[0].data;
    const actionItemTypesResponseData = filterFinalizedActionItems[1].data;
    const actionItemSubTypesResponseData = filterFinalizedActionItems[2].data;
    const actionInstanceStatusResponseData = filterFinalizedActionItems[3].data;

    useLoader(areQueriesLoading(filterFinalizedActionItems), FinalisedActionItemsContainer);

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.FinalizedActionItems);
    }, []);

    const changeRaciRelationship = (
        option: SingleValue<ReactSelectDto<RaciRelationship>>
    ): void => {
        setSearchParams({
            ...searchParams,
            raciRelationship: option!,
        });
    };

    const changeActionItemName = (name: string): void => {
        setSearchParams({ ...searchParams, name: name ? name : null });
    };

    const changeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemType: option!,
        });
    };

    const changeActionInstanceStatus = (option: SingleValue<ActionInstanceStatusDto>): void => {
        setSearchParams({
            ...searchParams,
            actionInstanceStatusId:
                option!.actionInstanceStatusId !== 0 ? option!.actionInstanceStatusId : null,
            actionInstanceStatus: option!,
        });
    };

    const filterActionInstanceStatuses = (
        actionInstanceStatusResponseData: ActionInstanceStatusDto[] | undefined
    ): ActionInstanceStatusDto[] | [] => {
        if (actionInstanceStatusResponseData) {
            return actionInstanceStatusResponseData.filter(
                (actionInstanceStatus) =>
                    actionInstanceStatus.actionInstanceStatusId ===
                        ActionInstanceStatus.Completed ||
                    actionInstanceStatus.actionInstanceStatusId === ActionInstanceStatus.Cancelled
            );
        }

        return [];
    };

    const changeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setSearchParams({
            ...searchParams,
            actionItemSubTypeNodeId: option!.nodeId !== 0 ? option!.nodeId : null,
            actionItemSubType: option!,
        });
    };

    const changeActionItemId = (actionItemId: string): void => {
        setSearchParams({
            ...searchParams,
            actionItemId: getNullableString(actionItemId),
        });
    };

    const changeDueDateTo = (dueDateTo: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateTo: dueDateTo,
        });
    };

    const changeDueDateFrom = (dueDateFrom: Date): void => {
        setSearchParams({
            ...searchParams,
            dueDateFrom: dueDateFrom,
        });
    };

    const search = (): void => {
        setPaginationDto({
            ...paginationDto,
            pageNumber: 1,
        });
        setFilterDto({ ...filterDto, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(emptySearchMyActionItemsDto());
        setPaginationDto({
            ...paginationDto,
            ...defaultBasePaginationDto,
        });
        setFilterDto({ ...filterDto, ...emptySearchMyActionItemsDto() });
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("MyFinalizedActionItems")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpText")}</PageSubHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful(filterFinalizedActionItems) && (
                <>
                    <FinalizedActionItemsFilter
                        changeRaciRelationship={changeRaciRelationship}
                        raciRelationship={searchParams.raciRelationship}
                        actionItemName={searchParams.name}
                        changeActionItemName={changeActionItemName}
                        actionItemType={searchParams.actionItemType}
                        actionItemTypes={[allActionItemTypeOption, ...actionItemTypesResponseData!]}
                        changeActionItemType={changeActionTypeValue}
                        actionItemSubType={searchParams.actionItemSubType}
                        actionItemSubTypes={[
                            allActionItemSubTypeOption,
                            ...actionItemSubTypesResponseData!,
                        ]}
                        changeActionItemSubType={changeActionSubTypeValue}
                        actionItemId={searchParams.actionItemId}
                        changeActionItemId={changeActionItemId}
                        actionInstanceStatus={searchParams.actionInstanceStatus}
                        actionInstanceStatuses={[
                            allActionInstanceStatusOption,
                            ...filterActionInstanceStatuses(actionInstanceStatusResponseData!),
                        ]}
                        changeActionInstanceStatus={changeActionInstanceStatus}
                        dueDateFrom={searchParams.dueDateFrom}
                        changeDueDateFrom={changeDueDateFrom}
                        dueDateTo={searchParams.dueDateTo}
                        changeDueDateTo={changeDueDateTo}
                        search={search}
                        resetFilter={resetFilter}
                    />
                    <LargeVerticalSpace />

                    <ActionItemsList
                        title={t("FinalizedActionItems")}
                        actionItems={filterFinalizedActionItemsResponseData!.results}
                        recordCount={filterFinalizedActionItemsResponseData!.recordCount}
                        paginationDto={paginationDto}
                        setPaginationDto={setPaginationDto}
                    />
                </>
            )}
        </>
    );
};

export default FinalisedActionItemsContainer;
