import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ActionItems, ActionPlans } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemEvidenceDto } from "../../../domain/dtos/actions/action-item-evidence-dto";
import { BasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { defaultPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { Response } from "../../../domain/responses/common/response-response";
import { useGetActionItemDetailsByActionItemId } from "../../../domain/viewmodels/actions/action-item-details-viewmodel";
import { useGetActionPlanDetails } from "../../../domain/viewmodels/actions/action-plan-details-viewmodel";
import { useDisassociateActionItemFromActionPlan } from "../../../domain/viewmodels/actions/disassociate-action-item-viewmodel";
import { DissociateButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import { ActionConfirmationFragment } from "../../organisms/ActionConfirmationFragment";

const DissociateActionItemContainer: React.FC = () => {
    const menu = useMenu();
    const [urlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const { t } = useTranslation("translation", { keyPrefix: ActionPlans });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [associatedActionItemsPaginationDto, setAssociatedActionItemsPaginationDto] =
        useState<BasePaginationDto>(defaultPaginationDto);

    const actionPlanId = Number(urlSearchParams.get("actionPlanId"));
    const actionItemId = Number(urlSearchParams.get("actionItemId"));

    const getActionPlanDetails = useGetActionPlanDetails(
        actionPlanId,
        associatedActionItemsPaginationDto
    );

    const actionPlanResponseData = getActionPlanDetails[0].data;
    const getActionItemDetailsByActionItemId = useGetActionItemDetailsByActionItemId(actionItemId);

    const disassociateActionItemFromActionPlan = useDisassociateActionItemFromActionPlan(
        actionPlanId,
        actionItemId
    );

    useLoader(
        areQueriesLoading([...getActionPlanDetails, getActionItemDetailsByActionItemId]) ||
            isMutationLoading(disassociateActionItemFromActionPlan),
        DissociateActionItemContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
    }, []);

    const disassociateActionItem = (): void => {
        var dto = new ActionItemEvidenceDto(actionItemId, actionPlanId);
        disassociateActionItemFromActionPlan.mutate(dto, {
            onSuccess: async ( _ : Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "ActionItemDisassociatedSuccessfully"
                    ),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionPlans)}/${actionPlanId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const buildDissociateButton = (): ReactElement<typeof Button> => (
        <DissociateButton onClick={disassociateActionItem} />
    );

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow(t("ActionPlanName"), actionPlanResponseData?.name),
        new TextConfirmationRow(
            t("ActionItemName", { keyPrefix: ActionItems }),
            getActionItemDetailsByActionItemId.data?.name
        ),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={t("DisassociateActionItem")}
            panelTitle={t("DisassociateActionItemConfirmationDisplay")}
            rows={buildConfirmationRows()}
            primaryButton={buildDissociateButton()}
            areQueriesSuccessful={areQueriesSuccessful([
                ...getActionPlanDetails,
                getActionItemDetailsByActionItemId,
            ])}
        />
    );
};

export default DissociateActionItemContainer;
