import React from "react";
import { Col, Popover, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActionItems } from "../../../core/constants/translation-namespace";
import { StyledBoldVariantText } from "../../../core/theme/global-styles";
import { ActionInstanceStatus } from "../../../domain/enums/action-items/ActionInstanceStatus";
import { getEventColour } from "../../../domain/viewmodels/action-items/my-action-calendar-viewmodel";

interface PopoverHeaderProps {
    $actionInstanceStatus: ActionInstanceStatus;
}

const StyledPopoverHeader = styled(Popover.Header).attrs({
    className: "popover-header",
})<PopoverHeaderProps>`
    background-color: ${(props) => getEventColour(props.$actionInstanceStatus, props.theme)};
    color: ${(props) => props.theme.palette.purewhite};
`;

const ActionItemEventPopoverBody: React.FC<{
    status: ActionInstanceStatus;
    labelValueMap: Map<string, string | null | undefined>;
}> = ({ status, labelValueMap }) => {
    const { t } = useTranslation("translation", { keyPrefix: ActionItems });
    
    const hasValue = (value: string | null | undefined): boolean =>
        value !== undefined && value !== null;

    return (
        <>
            <StyledPopoverHeader $actionInstanceStatus={status} as="h3">
                {t("EventItem")}
            </StyledPopoverHeader>
            <Popover.Body>
                <>
                    {Array.from(labelValueMap.entries()).map(
                        (x, index) =>
                            hasValue(x[1]) && (
                                <Row key={index}>
                                    <Col>
                                        <StyledBoldVariantText $variant={"secondary"}>
                                            {x[0]}
                                        </StyledBoldVariantText>
                                    </Col>
                                    <Col>{x[1]}</Col>
                                </Row>
                            )
                    )}
                </>
            </Popover.Body>
        </>
    );
};

export default ActionItemEventPopoverBody;
