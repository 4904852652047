import {
    buildFilterAuditsChangeLogSearchParameters,
    FilterAuditsChangeLogRequest,
} from "../../../domain/requests/audits/filter-audits-change-log-request";
import { AuditChangelogResponse } from "../../../domain/responses/audits/audit-change-log-response";
import { PaginationResponse } from "../../../domain/responses/common/pagination-response";
import { Response } from "../../../domain/responses/common/response-response";
import client from "../../http-client";

const route = "audit";

export const getAuditChangeLogs = async (
    prefixUrl: string,
    request: FilterAuditsChangeLogRequest
): Promise<Response<PaginationResponse<AuditChangelogResponse>>> => {
    const searchParams = buildFilterAuditsChangeLogSearchParameters(request);

    return await client
        .get(`${route}/filter-changelogs`, {
            searchParams: searchParams,
            prefixUrl: prefixUrl,
        })
        .json();
};

export const getAuditChangeLog = async (
    prefixUrl: string,
    changelogId: number
): Promise<Response<AuditChangelogResponse>> =>
    await client
        .get(`${route}/changelogs/${changelogId}`, {
            prefixUrl: prefixUrl,
        })
        .json();
