import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ChecklistQuestions, Common, Reports } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    DetailsValue,
    maxContentWidthSelectStyle,
    PageHeading,
    SectionVerticalSpace,
    StartAlignedDiv,
} from "../../../core/theme/global-styles";
import { questionSetInstanceReviewsColumnNames } from "../../../core/utilities/dataTableColumns";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { DownloadFile } from "../../../core/utilities/FileDownload";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { createReactSelectDto, ReactSelectDto } from "../../../domain/dtos/common/react-select-dto";
import { FileDownloadDto } from "../../../domain/dtos/file-storage/file-download-dto";
import { EditReviewReportSetDto } from "../../../domain/dtos/review-reports/edit-review-report-set-dto";
import { EditReviewReportSetTypeFieldsValueDto } from "../../../domain/dtos/review-reports/edit-review-report-set-type-field-value-dto";
import { PublishReviewReportSetDto } from "../../../domain/dtos/review-reports/publish-review-report-set-dto";
import ReviewRating from "../../../domain/enums/review-reports/review-ratings";
import {
    hasRoleTypeInGroup,
    QuestionSetInstanceReviewRoleGroup,
    Role,
} from "../../../domain/enums/Roles";
import { Response } from "../../../domain/responses/common/response-response";
import BaseFileDetailsResponse from "../../../domain/responses/file-storage/base-file-details-response";
import {
    useEditReportSet,
    useGetReviewReportSetDetails,
    usePublishReviewReportSet,
} from "../../../domain/viewmodels/review-reports/view-review-report-set-details-viewmodel";
import { SbSelect } from "../../atoms/input/SbSelect";
import SbTextArea from "../../atoms/input/SbTextArea";
import { SbButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import { translateText } from "../../helpers/translate";
import { SbAccordion } from "../../molecules/SbAccordion";
import { ComponentPanel } from "../../molecules/SbPanel";
import { DataTable } from "../../organisms/DataTable";
import LinkReviewReportWithAssuranceReviewContainer from "./LinkReviewReportWithAssuranceReviewContainer";

export const StyledFormLabelLeft = styled(Form.Label)`
    text-align: left;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary};
`;

const ViewReviewReportSetContainer: React.FC = () => {
    const reviewReportSetId = Number(useParams().reviewReportSetId);
    const reviewRatings = getEnumsForType(ReviewRating);

    const [reviewRating, setReviewRating] = useState<ReviewRating>();
    const [paginationDto, setPaginationDto] = useState<PaginationDto>(defaultPaginationDto);

    const menu = useMenu();
    const toast = useToast();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const auth = useAuth();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const reviewReportSet = useGetReviewReportSetDetails(reviewReportSetId, paginationDto);
    const reviewReportSetDetails = reviewReportSet[0];
    const questionSetInstanceReviews = reviewReportSet[1];
    const editReportSet = useEditReportSet();
    const publishReviewReportSet = usePublishReviewReportSet();

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";

    useLoader(
        areQueriesLoading([...reviewReportSet]) ||
            areMutationsLoading([editReportSet, publishReviewReportSet]),
        ViewReviewReportSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    useEffect(() => {
        if (isQuerySuccessful(reviewReportSetDetails)) {
            setReviewRating(reviewReportSetDetails.data!.reviewRating);
        }
    }, [reviewReportSetDetails.fetchStatus]);

    const getVersionToBePublished = (): number => {
        return reviewReportSetDetails.data!.latestPublishedVersion === null
            ? 1
            : reviewReportSetDetails.data!.latestPublishedVersion! + 1;
    };

    const handleSave = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const reviewReportSetTypeFieldsValueDtos: EditReviewReportSetTypeFieldsValueDto[] = [];
        reviewReportSetDetails.data!.reviewReportSetTypeFieldsValueDtos.forEach((x) => {
            const updatedFiedValue = formData.get(x.reviewTypeFieldDto.fieldName) as string;

            updatedFiedValue &&
                reviewReportSetTypeFieldsValueDtos.push(
                    new EditReviewReportSetTypeFieldsValueDto(
                        updatedFiedValue,
                        x.reviewReportSetTypeFieldsValueId
                    )
                );
        });

        const editReportSetDto = new EditReviewReportSetDto(
            reviewReportSetTypeFieldsValueDtos,
            reviewReportSetId,
            reviewRating!
        );

        editReportSet.mutate(editReportSetDto, {
            onSuccess: async (_: Response<boolean>) => {
                toast.addToast(
                    createSuccessToastProps([
                        `${t("SuccessfullySavedReportDetails")} - ${
                            reviewReportSetDetails.data!.reviewReportName
                        }`,
                    ])
                );
                reviewReportSetDetails.refetch();
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handlePublishReviewReportSet = (): void => {
        publishReviewReportSet.mutate(
            new PublishReviewReportSetDto(reviewReportSetId, reviewRating!),
            {
                onSuccess: async (response: Response<BaseFileDetailsResponse>) => {
                    const fileDownloadDto =
                        FileDownloadDto.constructFromBaseFileDetailsResponse(response);

                    DownloadFile(fileDownloadDto);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "reviewReportName",
                            reviewReportSetDetails.data!.reviewReportName
                        ),
                        createNavigateSearchParameter("messageKey", "SuccessfullyPublishedReport"),
                    ];

                    navigateSearch(`${getPath(DrawerTitles.Reviews)}`, params);
                },
                onError: errorResponseToDisplayHandler,
            }
        );
    };

    const navigateToViewQuestionSetInstanceReviewDetails = (
        questionSetInstanceId: number
    ): void => {
        const params = [
            createNavigateSearchParameter(
                "questionSetInstanceId",
                questionSetInstanceId.toString()
            ),
            createNavigateSearchParameter("reviewReportSetId", reviewReportSetId.toString()),
        ];

        navigateSearch(`${getPath(DrawerTitles.Reviews)}/question-set-instance-review`, params);
    };

    const navigateToPreviewItems = (): void => {
        const params = [
            createNavigateSearchParameter("questionSetInstanceId", "0"),
            createNavigateSearchParameter("reviewReportSetId", reviewReportSetId.toString()),
        ];

        navigateSearch(`${getPath(DrawerTitles.Reviews)}/preview-items`, params);
    };

    return (
        <>
            <PageHeading>{t("Reviews")}</PageHeading>

            <SectionVerticalSpace />

            {areQueriesSuccessful(reviewReportSet) && (
                <>
                    <SbAccordion title={reviewReportSetDetails.data!.reviewReportName}>
                        <Form onSubmit={handleSave}>
                            <Form.Group as={Row} className="mb-3">
                                <StyledFormLabelLeft column sm={2}>
                                    <SbLabelText label={t("Name")} />
                                </StyledFormLabelLeft>
                                <Col sm={3}>
                                    <DetailsValue>
                                        {reviewReportSetDetails.data!.reviewReportName}
                                    </DetailsValue>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <StyledFormLabelLeft column sm={2}>
                                    <SbLabelText label={t("Rating", { keyPrefix: Common })} />
                                </StyledFormLabelLeft>
                                <Col sm={3}>
                                    <SbSelect
                                        name={"rating"}
                                        searchable={false}
                                        styles={maxContentWidthSelectStyle}
                                        clearable={false}
                                        itemLabel={(option: ReactSelectDto<ReviewRating>) =>
                                            t(option.label, { keyPrefix: Common })
                                        }
                                        itemValue={(option: ReactSelectDto<ReviewRating>) =>
                                            option.label
                                        }
                                        items={reviewRatings.map((x) =>
                                            createReactSelectDto(x, ReviewRating[x])
                                        )}
                                        defaultSelectedItem={createReactSelectDto(
                                            reviewReportSetDetails.data!.reviewRating,
                                            ReviewRating[reviewReportSetDetails.data!.reviewRating]
                                        )}
                                        onChange={(option): void => {
                                            setReviewRating(option!.value);
                                        }}
                                    />
                                </Col>
                            </Form.Group>

                            {reviewReportSetDetails.data!.reviewReportSetTypeFieldsValueDtos.map(
                                (x) => (
                                    <>
                                        <Form.Group as={Row} className="mb-3">
                                            <StyledFormLabelLeft column sm={2}>
                                                <SbLabelText
                                                    label={translateText(
                                                        t,
                                                        x.reviewTypeFieldDto.fieldName.trim(),
                                                        Reports
                                                    )}
                                                />
                                            </StyledFormLabelLeft>
                                            <Col sm={6}>
                                                <SbTextArea
                                                    defaultValue={x.value}
                                                    name={x.reviewTypeFieldDto.fieldName}
                                                    key={x.reviewTypeFieldDto.fieldName}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )
                            )}

                            <Row>
                                <Col sm="2"></Col>
                                <Col sm="auto">
                                    <StartAlignedDiv>
                                        <SbButton
                                            label={t("Save", { keyPrefix: Common })}
                                            type="submit"
                                            variant={"primary"}
                                        />
                                        {reviewReportSetDetails.data!.reviewReportSetTypeFieldsValueDtos.find(
                                            (x) => x.value === "" || x.value === null
                                        ) && (
                                            <SbButton
                                                label={t("ReviewFullReport")}
                                                disabled
                                                type="button"
                                                variant={"primary"}
                                            />
                                        )}
                                        {!reviewReportSetDetails.data!.reviewReportSetTypeFieldsValueDtos.find(
                                            (x) => x.value === "" || x.value === null
                                        ) && (
                                            <SbButton
                                                label={t("Preview", { keyPrefix: Common })}
                                                type="button"
                                                variant={"primary"}
                                                onClick={() => navigateToPreviewItems()}
                                            />
                                        )}
                                        <SbButton
                                            label={`${t("Publish", {
                                                keyPrefix: Common,
                                            })} v${getVersionToBePublished()}`}
                                            type="button"
                                            variant={"primary"}
                                            onClick={() => handlePublishReviewReportSet()}
                                            disabled={
                                                !hasRoleTypeInGroup(auth.userRoles, [
                                                    Role.Monitoring,
                                                ])
                                            }
                                        />
                                    </StartAlignedDiv>
                                </Col>
                            </Row>
                        </Form>
                    </SbAccordion>

                    <SectionVerticalSpace />

                    <LinkReviewReportWithAssuranceReviewContainer
                        assuranceReviewDto={reviewReportSetDetails.data?.assuranceReviewDto}
                    />

                    <SectionVerticalSpace />

                    <ComponentPanel>
                        <DataTable
                            keyPrefix={ChecklistQuestions}
                            rows={questionSetInstanceReviews.data!.rows}
                            columns={questionSetInstanceReviewsColumnNames}
                            viewItem={
                                hasRoleTypeInGroup(
                                    auth.userRoles,
                                    QuestionSetInstanceReviewRoleGroup.DeleteRoles
                                )
                                    ? (questionSetInstanceId: number): void => {
                                          navigateToViewQuestionSetInstanceReviewDetails(
                                              questionSetInstanceId
                                          );
                                      }
                                    : undefined
                            }
                            deleteItem={
                                hasRoleTypeInGroup(
                                    auth.userRoles,
                                    QuestionSetInstanceReviewRoleGroup.DeleteRoles
                                )
                                    ? (questionSetInstanceId: number) => {
                                          const params = [
                                              createNavigateSearchParameter(
                                                  "reviewReportSetId",
                                                  reviewReportSetId.toString()
                                              ),
                                          ];

                                          navigateSearch(
                                              `${getPath(
                                                  DrawerTitles.Reviews
                                              )}/${questionSetInstanceId}/delete-checklist-from-review`,
                                              params
                                          );
                                      }
                                    : undefined
                            }
                            totalItems={questionSetInstanceReviews.data!.recordCount}
                            paginationDto={paginationDto}
                            setPaginationDto={setPaginationDto}
                        />
                    </ComponentPanel>
                </>
            )}
        </>
    );
};

export default ViewReviewReportSetContainer;
