import { useMutation, UseMutationResult, useQueries, UseQueryResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useTranslation } from "react-i18next";
import { ChecklistQuestions, Common } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { DataTableColumnTypes } from "../../../core/utilities/enums";
import {
    editReviewReportSet,
    filterQuestionSetInstanceReviews,
    getReviewReportSetDetails,
    publishReviewReportSet,
} from "../../../data/services/review-reports/review-reports-service";
import { translateText } from "../../../presentation/helpers/translate";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { EditReviewReportSetDto } from "../../dtos/review-reports/edit-review-report-set-dto";
import { PublishReviewReportSetDto } from "../../dtos/review-reports/publish-review-report-set-dto";
import { ReviewReportSetDto } from "../../dtos/review-reports/review-report-set-dto";
import { toRatingLabel } from "../../enums/review-reports/review-ratings";
import ReviewStatus from "../../enums/review-reports/review-status";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { EditReviewReportSetRequest } from "../../requests/review-reports/edit-review-report-set-request";
import { FilterQuestionSetInstanceReviewsRequest } from "../../requests/review-reports/filter-question-set-instance-reviews-request";
import { PublishReviewReportSetRequest } from "../../requests/review-reports/publish-review-report-set-request";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import BaseFileDetailsResponse from "../../responses/file-storage/base-file-details-response";
import { QuestionSetInstanceReviewResponse } from "../../responses/review-reports/question-set-instance-review-response";

export const useGetReviewReportSetDetails = (
    reviewReportSetId: number,
    paginationDto: PaginationDto
): [
    UseQueryResult<ReviewReportSetDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();

    const { t } = useTranslation("translation", { keyPrefix: Common });
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const queries = useQueries({
        queries: [
            {
                queryKey: ["getReviewReportSetDetails", reviewReportSetId],
                queryFn: () => getReviewReportSetDetails(url.baseUrl, reviewReportSetId),
                select: ReviewReportSetDto.constructFromResponse,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterQuestionSetInstanceReviews", reviewReportSetId, paginationDto],
                queryFn: () =>
                    filterQuestionSetInstanceReviews(
                        url.baseUrl,
                        new FilterQuestionSetInstanceReviewsRequest(
                            reviewReportSetId,
                            createPaginationRequestFromDto(paginationDto)
                        )
                    ),
                select: (
                    response: Response<PaginationResponse<QuestionSetInstanceReviewResponse>>
                ) => transformToDataTableRows(response, t),
                onError: errorResponseToDisplayHandler,
            },
        ],
    });

    return queries as [
        UseQueryResult<ReviewReportSetDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

const transformToDataTableRows = (
    response: Response<PaginationResponse<QuestionSetInstanceReviewResponse>>,
    t: any
): PaginatedTableDto<number> => {
    const rows = response.data.results!.map((questionSetInstanceReview) => {
        return {
            metadata: questionSetInstanceReview.questionSetInstanceResponse.questionSetInstanceId,
            secondaryMetadata: questionSetInstanceReview.questionSetInstanceReviewId,
            columns: [
                {
                    //Checklist
                    value: questionSetInstanceReview.checklistResponse.name.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Question Set
                    value: questionSetInstanceReview.questionSetResponse.name.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Path
                    value: questionSetInstanceReview.nodeResponse.path.toString(),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Date Created
                    value: toDateAndTimeFormat(
                        questionSetInstanceReview.questionSetInstanceResponse.dateCreatedUtc.toString()
                    ),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Created By
                    value: `${questionSetInstanceReview.questionSetInstanceResponse.adhocTriggerUser.firstName} ${questionSetInstanceReview.questionSetInstanceResponse.adhocTriggerUser.lastName}`,
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Rating
                    value: translateText(
                        t,
                        toRatingLabel(questionSetInstanceReview.reviewRating),
                        Common
                    ),
                    type: DataTableColumnTypes.Text,
                },
                {
                    //Status
                    value: translateText(
                        t,
                        ReviewStatus[questionSetInstanceReview.reviewStatus].toString(),
                        ChecklistQuestions
                    ),
                    type: DataTableColumnTypes.Text,
                },
            ],
        };
    });

    return {
        numberOfPages: response.data.pageCount,
        pageSize: response.data.pageSize,
        currentPage: response.data.currentPage,
        recordCount: response.data.recordCount,
        rows: rows,
    };
};

export const useEditReportSet = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    EditReviewReportSetDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: EditReviewReportSetDto) => {
        return editReviewReportSet(url.baseUrl, new EditReviewReportSetRequest(mutationKey));
    });
};

export const usePublishReviewReportSet = (): UseMutationResult<
    Response<BaseFileDetailsResponse>,
    HTTPError,
    PublishReviewReportSetDto
> => {
    const url = useUrl();

    return useMutation((mutationKey: PublishReviewReportSetDto) => {
        return publishReviewReportSet(url.baseUrl, new PublishReviewReportSetRequest(mutationKey));
    });
};
