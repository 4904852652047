import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useUrl } from "../../../core/store/url-context";
import { disassociateActionItem } from "../../../data/services/actions/action-plans-service";
import { createDisassociateActionItemRequest } from "../../requests/actions/disasociate-action-item-request";
import { Response } from "../../responses/common/response-response";

export const useDisassociateActionItemFromActionPlan = (
    actionPlanId: number,
    actionItemId: number | null
): UseMutationResult<Response<boolean>, HTTPError> => {
    const url = useUrl();

    return useMutation(() =>
        disassociateActionItem(
            url.baseUrl,
            createDisassociateActionItemRequest(actionPlanId, actionItemId)
        )
    );
};
